import React, { Component } from "react";
import "./DashboardStyle.css";

import "react-toastify/dist/ReactToastify.css";

import TaskCardComponent from "./TaskCardComponent";

class TaskList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      apiCallHasError1: false,
      apiCallProcessing1: true,
      apiCallHasError2: false,
      apiCallProcessing2: true,
      apiCallHasError: false,
      apiCallProcessing: false,
      isModalShown: false,
      projectList: [],
      userList: [],
      taskList: [],
      focusedTask: null,
    };

    this.formModal = null; //Variable contenant le modal du formulaire.
    this.toastSuccess = null; //Variable contenant le modal du formulaire.
  }

  updateFunction(task) {
    this.props.updateFunction(task);
  }

  deleteFunction(task) {
    this.props.deleteFunction(task);
  }

  render() {
    return (
      <div className="content-displayer py-3 mb-2">
        {this.props.tasks.map((task, index) => {
          // var propsTask = this.props.tasks;
          // var childTasks = propsTask.filter(element => element.parent === task.id);

          return (
            <TaskCardComponent
              key={index}
              task={task}
              updateFunction={this.props.updateFunction}
              deleteFunction={this.props.deleteFunction}
              fetchTaskChildren={(params) => this.props.fetchTaskChildren(params)}
            />
          );
        })}
      </div>
    );
  }
}

export default TaskList;
