import HelperFunction from "helperfunctions";
import { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { getTaskFile } from "services/TaskServices";
import AddTaskWithFile from "components/forms/AddTaskWithFile";
import AddWithFile from "components/forms/AddWithFile";
import { toast, ToastContainer } from "react-toastify";
import autoBind from "react-autobind";

import GantPage from "./Gantpage";

class DownloadUpload extends Component {
  constructor(props) {
    console.log(props.className);
    super(props);
    this.state = {
      isUploadModalShown: false,
      userGroup: localStorage.getItem("group"),
    };
    autoBind(this);
  }

  showUploadModal() {
    this.setState({
      isUploadModalShown: true,
    });
  }

  hideUploadModal() {
    this.setState({
      isUploadModalShown: false,
    });
  }

  downloadTaskFile() {
    HelperFunction.downloadFile(
      this.props.downloadUrl,
      this.props.downloadFileName,
      this.props.accept,
    );
  }

  successfullUploadCallback() {
    if (this.props.onSuccessfullUpload) {
      this.props.onSuccessfullUpload();
    }
    this.hideUploadModal();
    toast.success("Fichier chargé avec succès", {
      position: "bottom-left",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    });
  }

  render() {
    return (
      <>
        <ToastContainer />
        <div
          className={this.props.className ? this.props.className : "d-flex px-2"}
          style={this.props.style}
        >
          <button
            type="button"
            className="btn btn-danger download col me-2 z-100"
            onClick={this.downloadTaskFile}
          >
            <i className="bi bi-file-earmark-arrow-down"></i>
          </button>
          {this.state.userGroup === "RSE" && (
            <button
              type="button"
              className="btn btn-primary download col"
              onClick={this.showUploadModal}
            >
              <i className="bi bi-file-arrow-up"></i>
            </button>
          )}
        </div>

        <div id="modalformaddtask">
          <AddWithFile
            show={this.state.isUploadModalShown}
            hide={this.hideUploadModal}
            warningMessage={this.props.warningMessage}
            modalName={this.props.modalName}
            uploadUrl={this.props.uploadUrl}
            callBackSuccess={this.successfullUploadCallback}
            payloadKey={this.props.payloadKey}
          />
        </div>
      </>
    );
  }
}

export default DownloadUpload;
