import { createBrowserHistory } from "history";

export const history = createBrowserHistory();

export const API_URL = process.env.REACT_APP_API_URL;
// export const API_URL = "https://lireapi.2mkouas.com/";

export function APIERRORCODELIST() {
  return ["token_not_valid", "user_not_found", "Bad Request"];
}

export const DATE_FORMAT_OPTIONS = {
  weekday: "long",
  year: "numeric",
  month: "long",
  day: "numeric",
};

export const TASKTYPES = [
  { id: "Composante", name: "Composante" },
  { id: "Sous Composante", name: "Sous Composante" },
  { id: "Volet", name: "Volet" },
  { id: "Activité", name: "Activité" },
];

export const INDICATORS = {
  "IODP.1": {
    composante: 1,
    link: "/enseignants/formes",
    code: "IODP.1",
    shortName: "IODP.1",
    Label:
      "Pourcentage d’enseignants ciblés dans l’enseignement de base faisant preuve de meilleures pratiques d’enseignement en classe",
  },
  "IRIP.1.1": {
    composante: 1,
    link: "/audits",
    code: "IRIP.1.1",
    shortName: "IRIP.1.1",
    Label: " Nombre audit pédagogique et organisationnel des ENI et ENS",
  },
  "IR1.3": {
    composante: 1,
    link: "/formateurs",
    code: "IR1.3",
    shortName: "IR1.3",
    Label: "",
  },
  "IR1.4": {
    composante: 1,
    link: "/outils",
    code: "IR1.4",
    shortName: "IR1.4",
    Label:
      "Nombre d'outils d’observation en classe (OOC) élaborés pour évaluer les pratiques d’enseignement",
  },
  "IR1.5": {
    composante: 1,
    link: "/enseignants/visites",
    code: "IR1.5",
    shortName: "IR1.5",
    Label: "",
  },
  "IR1.6": {
    composante: 1,
    link: "/coachs",
    code: "IR1.6",
    shortName: "IR1.6",
    Label: "",
  },
  "IR1.7": {
    composante: 1,
    link: "/cours",
    code: "IR1.7",
    shortName: "IR1.7",
    Label: "",
  },
};

export const EXCEL_EXTENSIONS =
  ".xlsx,.xlsm,.xls,.xltx,xltx,.xls,.xlt,.xml,.xlam,.xla,.xlw,.xlr,.csv";

const COLUMNS_IODP = [
  {
    id: "région",
    order: 0,
    label: "region",
    name: "region",
    isVisible: true,
    contentToDisplay: (element) => {
      return element.region;
    },
  },
  {
    id: "département",
    order: 0,
    label: "département",
    name: "departement",
    isVisible: true,
    contentToDisplay: (element) => {
      return element.departement;
    },
  },
  {
    id: "commune",
    order: 0,
    label: "commune",
    name: "commune",
    isVisible: true,
    contentToDisplay: (element) => {
      return element.commune;
    },
  },
  {
    id: "localité",
    order: 0,
    label: "localité",
    name: "localite",
    isVisible: true,
    contentToDisplay: (element) => {
      return element.localite;
    },
    options: {
      filter: false,
    },
  },
  {
    id: "ecole",
    order: 0,
    label: "école",
    name: "ecole",
    isVisible: true,
    contentToDisplay: (element) => {
      return element.ecole;
    },
    options: {
      filter: false,
    },
  },
  // { id: "niveau d'enseignement", order: 0, name: "niveau d'enseignement", isVisible: true, "contentToDisplay": (element) => { return element.region } },
  {
    id: "cycle",
    order: 0,
    label: "cycle",
    name: "cycle",
    isVisible: true,
    contentToDisplay: (element) => {
      return element.cycle;
    },
    options: {
      filter: false,
    },
  },
  {
    id: "classe",
    order: 0,
    label: "classe",
    name: "classes",
    isVisible: true,
    contentToDisplay: (element) => {
      return element.classes[0];
    },
    options: {
      customBodyRender: (value, tableMeta, updateValue) => {
        let val = "";
        if (value) val = value[0];
        return val;
      },
      filter: false,
    },
  },
  {
    id: "titre de la formation",
    order: 0,
    label: "Titre de la formation",
    name: "formation_title",
    isVisible: true,
    contentToDisplay: (element) => {
      return element.formation_title;
    },
    options: {
      filter: false,
    },
  },
  // { id: "lieu de formation", order: 0, name: "lieu de formation", isVisible: true, "contentToDisplay": (element) => { return element.region } },
  {
    id: "nom",
    order: 0,
    label: "nom",
    name: "nom",
    isVisible: true,
    contentToDisplay: (element) => {
      return element.nom;
    },
    options: {
      filter: false,
    },
  },
  {
    id: "prenoms",
    order: 0,
    label: "prenoms",
    name: "prenoms",
    isVisible: true,
    contentToDisplay: (element) => {
      return element.prenoms;
    },
    options: {
      filter: false,
    },
  },
  {
    id: "sexe",
    order: 0,
    label: "sexe",
    name: "sexe",
    isVisible: true,
    contentToDisplay: (element) => {
      return element.sexe;
    },
    options: {
      filter: false,
    },
  },
  {
    id: "tel",
    order: 0,
    label: "téléphone",
    name: "telephone",
    isVisible: true,
    contentToDisplay: (element) => {
      return element.telephone;
    },
    options: {
      filter: false,
    },
  },
  {
    id: "année de formation",
    order: 0,
    label: "année de formation",
    name: "formation_date",
    isVisible: true,
    contentToDisplay: (element) => {
      return element.formation_date;
    },
    options: {
      filter: false,
    },
  },
  {
    id: "niveau d'appropriation",
    order: 0,
    label: "niveau d'appropriation",
    name: "niveau_appropriation",
    isVisible: true,
    contentToDisplay: (element) => {
      return element.niveau_appropriation;
    },
    options: {
      filter: false,
    },
  },
  {
    id: "année d'évaluation",
    order: 0,
    label: "année d'évaluation",
    name: "date_evaluation",
    isVisible: true,
    contentToDisplay: (element) => {
      return element.date_evaluation;
    },
    options: {
      filter: false,
    },
  },
];

const COLUMNS_IRIP = [
  {
    id: "région",
    order: 0,
    label: "region",
    name: "region",
    isVisible: true,
    contentToDisplay: (element) => {
      return element.region;
    },
    options: {
      filter: true,
    },
  },
  {
    id: "département",
    order: 0,
    label: "département",
    name: "departement",
    isVisible: true,
    contentToDisplay: (element) => {
      return element.departement;
    },
    options: {
      filter: true,
    },
  },
  {
    id: "commune",
    order: 0,
    label: "commune",
    name: "commune",
    isVisible: true,
    contentToDisplay: (element) => {
      return element.commune;
    },
    options: {
      filter: true,
    },
  },
  {
    id: "localité",
    order: 0,
    label: "localité",
    name: "localite",
    isVisible: true,
    contentToDisplay: (element) => {
      return element.localite;
    },
    options: {
      filter: false,
    },
  },
  {
    id: "structure",
    order: 0,
    label: "structure",
    name: "structure",
    isVisible: true,
    contentToDisplay: (element) => {
      return element.structure;
    },
    options: {
      filter: false,
    },
  },
  {
    id: "Nº contrat",
    order: 0,
    label: "Nº contrat",
    name: "contrat_number",
    isVisible: true,
    contentToDisplay: (element) => {
      return element.contrat_number;
    },
    options: {
      filter: false,
    },
  },
  {
    id: "nombre de rapport déposé",
    order: 0,
    label: "nombre de rapport déposé",
    name: "reports_count",
    isVisible: true,
    contentToDisplay: (element) => {
      return element.reports_count;
    },
    options: {
      filter: false,
    },
  },
  // { id: "nombre d'audit", order: 0, name: "nombre d'audit", isVisible: true, "contentToDisplay": (element) => { return element.region } },
  {
    id: "année de réalisation",
    order: 0,
    label: "année de réalisation",
    name: "date",
    isVisible: true,
    contentToDisplay: (element) => {
      return element.date;
    },
    options: {
      filter: false,
    },
  },
];

const COLUMNS_IR1_3 = [
  {
    id: "région",
    order: 0,
    label: "region",
    name: "region",
    isVisible: true,
    contentToDisplay: (element) => {
      return element.region;
    },
  },
  {
    id: "département",
    order: 0,
    label: "département",
    name: "departement",
    isVisible: true,
    contentToDisplay: (element) => {
      return element.departement;
    },
    options: {
      filter: true,
    },
  },
  {
    id: "commune",
    order: 0,
    label: "commune",
    name: "commune",
    isVisible: true,
    contentToDisplay: (element) => {
      return element.commune;
    },
    options: {
      filter: true,
    },
  },
  {
    id: "localité",
    order: 0,
    label: "localité",
    name: "localite",
    isVisible: true,
    contentToDisplay: (element) => {
      return element.localite;
    },
    options: {
      filter: false,
    },
  },
  {
    id: "structure",
    order: 0,
    label: "structure",
    name: "structure",
    isVisible: true,
    contentToDisplay: (element) => {
      return element.structure;
    },
    options: {
      filter: false,
    },
  },
  // { id: "cycle", order: 0, label:"cycle", name: "cycle", isVisible: true, "contentToDisplay": (element) => { return element.cycle } },
  {
    id: "titre de la formation",
    order: 0,
    label: "Titre de la formation",
    name: "formation_title",
    isVisible: true,
    contentToDisplay: (element) => {
      return element.formation_title;
    },
    options: {
      filter: false,
    },
  },
  {
    id: "lieu de formation",
    order: 0,
    label: "lieu de formation",
    name: "lieu_formation",
    isVisible: true,
    contentToDisplay: (element) => {
      return element.lieu_formation;
    },
    options: {
      filter: false,
    },
  },
  {
    id: "nom",
    order: 0,
    label: "nom",
    name: "nom",
    isVisible: true,
    contentToDisplay: (element) => {
      return element.nom;
    },
    options: {
      filter: false,
    },
  },
  {
    id: "prenoms",
    order: 0,
    label: "prenoms",
    name: "prenoms",
    isVisible: true,
    contentToDisplay: (element) => {
      return element.prenoms;
    },
    options: {
      filter: false,
    },
  },
  {
    id: "sexe",
    order: 0,
    label: "sexe",
    name: "sexe",
    isVisible: true,
    contentToDisplay: (element) => {
      return element.sexe;
    },
    options: {
      filter: false,
    },
  },
  {
    id: "tel",
    order: 0,
    label: "téléphone",
    name: "telephone",
    isVisible: true,
    contentToDisplay: (element) => {
      return element.telephone;
    },
    options: {
      filter: false,
    },
  },
  {
    id: "année de formation",
    order: 0,
    label: "année de formation",
    name: "formation_date",
    isVisible: true,
    contentToDisplay: (element) => {
      return element.formation_date;
    },
    options: {
      filter: false,
    },
  },
];

const COLUMNS_IR1_4 = [
  {
    id: "région",
    order: 0,
    label: "region",
    name: "region",
    isVisible: true,
    contentToDisplay: (element) => {
      return element.region;
    },
    options: {
      filter: true,
    },
  },
  {
    id: "département",
    order: 0,
    label: "département",
    name: "departement",
    isVisible: true,
    contentToDisplay: (element) => {
      return element.departement;
    },
    options: {
      filter: true,
    },
  },
  {
    id: "commune",
    order: 0,
    label: "commune",
    name: "commune",
    isVisible: true,
    contentToDisplay: (element) => {
      return element.commune;
    },
    options: {
      filter: true,
    },
  },
  {
    id: "localité",
    order: 0,
    label: "localité",
    name: "localite",
    isVisible: true,
    contentToDisplay: (element) => {
      return element.localite;
    },
    options: {
      filter: false,
    },
  },
  {
    id: "structure",
    order: 0,
    label: "structure",
    name: "structure",
    isVisible: true,
    contentToDisplay: (element) => {
      return element.structure;
    },
    options: {
      filter: false,
    },
  },
  {
    id: "nom",
    order: 0,
    label: "nom",
    name: "nom",
    isVisible: true,
    contentToDisplay: (element) => {
      return element.nom;
    },
    options: {
      filter: false,
    },
  },
  {
    id: "description",
    order: 0,
    label: "description",
    name: "description",
    isVisible: true,
    contentToDisplay: (element) => {
      return element.description;
    },
    options: {
      filter: false,
    },
  },
  {
    id: "année d'élaboration",
    order: 0,
    label: "année d'élaboration",
    name: "date_elaboration",
    isVisible: true,
    contentToDisplay: (element) => {
      return element.date_elaboration;
    },
    options: {
      filter: false,
    },
  },
];

const COLUMNS_IR1_5 = [
  {
    id: "région",
    order: 0,
    label: "region",
    name: "region",
    isVisible: true,
    contentToDisplay: (element) => {
      return element.region;
    },
    options: {
      filter: true,
    },
  },
  {
    id: "département",
    order: 0,
    label: "département",
    name: "departement",
    isVisible: true,
    contentToDisplay: (element) => {
      return element.departement;
    },
    options: {
      filter: true,
    },
  },
  {
    id: "commune",
    order: 0,
    label: "commune",
    name: "commune",
    isVisible: true,
    contentToDisplay: (element) => {
      return element.commune;
    },
    options: {
      filter: true,
    },
  },
  {
    id: "localité",
    order: 0,
    label: "localité",
    name: "localite",
    isVisible: true,
    contentToDisplay: (element) => {
      return element.localite;
    },
    options: {
      filter: false,
    },
  },
  {
    id: "ecole",
    order: 0,
    label: "école",
    name: "ecole",
    isVisible: true,
    contentToDisplay: (element) => {
      return element.ecole;
    },
    options: {
      filter: false,
    },
  },
  // { id: "niveau d'enseignement", order: 0, name: "niveau d'enseignement", isVisible: true, "contentToDisplay": (element) => { return element.region } },
  {
    id: "date de visite",
    order: 0,
    label: "date de visite",
    name: "date_visite",
    isVisible: true,
    contentToDisplay: (element) => {
      return element.date_visite;
    },
    options: {
      filter: false,
    },
  },
  {
    id: "nom",
    order: 0,
    label: "nom",
    name: "nom",
    isVisible: true,
    contentToDisplay: (element) => {
      return element.nom;
    },
    options: {
      filter: false,
    },
  },
  {
    id: "prenoms",
    order: 0,
    label: "prenoms",
    name: "prenoms",
    isVisible: true,
    contentToDisplay: (element) => {
      return element.prenoms;
    },
    options: {
      filter: false,
    },
  },
  {
    id: "sexe",
    order: 0,
    label: "sexe",
    name: "sexe",
    isVisible: true,
    contentToDisplay: (element) => {
      return element.sexe;
    },
    options: {
      filter: false,
    },
  },
  {
    id: "tel",
    order: 0,
    label: "téléphone",
    name: "telephone",
    isVisible: true,
    contentToDisplay: (element) => {
      return element.telephone;
    },
    options: {
      filter: false,
    },
  },
];

const COLUMNS_IR1_6 = [
  {
    id: "région",
    order: 0,
    label: "region",
    name: "region",
    isVisible: true,
    contentToDisplay: (element) => {
      return element.region;
    },
    options: {
      filter: true,
    },
  },
  {
    id: "département",
    order: 0,
    label: "département",
    name: "departement",
    isVisible: true,
    contentToDisplay: (element) => {
      return element.departement;
    },
    options: {
      filter: true,
    },
  },
  {
    id: "commune",
    order: 0,
    label: "commune",
    name: "commune",
    isVisible: true,
    contentToDisplay: (element) => {
      return element.commune;
    },
    options: {
      filter: true,
    },
  },
  {
    id: "localité",
    order: 0,
    label: "localité",
    name: "localite",
    isVisible: true,
    contentToDisplay: (element) => {
      return element.localite;
    },
    options: {
      filter: false,
    },
  },
  {
    id: "type d'acteur",
    order: 0,
    label: "type d'acteur",
    name: "grade",
    isVisible: true,
    contentToDisplay: (element) => {
      return element.grade;
    },
    options: {
      filter: false,
    },
  },
  {
    id: "nom",
    order: 0,
    label: "nom",
    name: "nom",
    isVisible: true,
    contentToDisplay: (element) => {
      return element.nom;
    },
    options: {
      filter: false,
    },
  },
  {
    id: "prenoms",
    order: 0,
    label: "prenoms",
    name: "prenoms",
    isVisible: true,
    contentToDisplay: (element) => {
      return element.prenoms;
    },
    options: {
      filter: false,
    },
  },
  {
    id: "sexe",
    order: 0,
    label: "sexe",
    name: "sexe",
    isVisible: true,
    contentToDisplay: (element) => {
      return element.sexe;
    },
    options: {
      filter: false,
    },
  },
  {
    id: "date de formation",
    order: 0,
    label: "date de formation",
    name: "formation_date",
    isVisible: true,
    contentToDisplay: (element) => {
      return element.formation_date;
    },
    options: {
      filter: false,
    },
  },
  {
    id: "lieu de formation",
    order: 0,
    label: "lieu de formation",
    name: "formation_lieu",
    isVisible: true,
    contentToDisplay: (element) => {
      return element.formation_lieu;
    },
    options: {
      filter: false,
    },
  },
];

const COLUMNS_IR1_7 = [
  {
    id: "région",
    order: 0,
    label: "region",
    name: "region",
    isVisible: true,
    contentToDisplay: (element) => {
      return element.region;
    },
    options: {
      filter: true,
    },
  },
  {
    id: "département",
    order: 0,
    label: "département",
    name: "departement",
    isVisible: true,
    contentToDisplay: (element) => {
      return element.departement;
    },
    options: {
      filter: true,
    },
  },
  {
    id: "commune",
    order: 0,
    label: "commune",
    name: "commune",
    isVisible: true,
    contentToDisplay: (element) => {
      return element.commune;
    },
    options: {
      filter: true,
    },
  },
  {
    id: "localité",
    order: 0,
    label: "localité",
    name: "localite",
    isVisible: true,
    contentToDisplay: (element) => {
      return element.localite;
    },
    options: {
      filter: false,
    },
  },
  {
    id: "structure",
    order: 0,
    label: "structure",
    name: "structure",
    isVisible: true,
    contentToDisplay: (element) => {
      return element.structure;
    },
    options: {
      filter: false,
    },
  },
  {
    id: "langue utilisées",
    order: 0,
    label: "langue utilisées",
    name: "langues",
    isVisible: true,
    contentToDisplay: (element) => {
      return element.langues;
    },
    options: {
      filter: false,
    },
  },
  {
    id: "type de support numérique",
    order: 0,
    label: "type de support numérique ",
    name: "types_support",
    isVisible: true,
    contentToDisplay: (element) => {
      return element.types_support;
    },
    options: {
      filter: false,
    },
  },
  {
    id: "année d'élaboration'",
    order: 0,
    label: "année d'élaboration",
    name: "annee_elaboration",
    isVisible: true,
    contentToDisplay: (element) => {
      return element.annee_elaboration;
    },
    options: {
      filter: false,
    },
  },
];

export const COLUMNS_INDICATOR = {
  "IODP.1": COLUMNS_IODP,
  "IRIP.1.1": COLUMNS_IRIP,
  "IR1.3": COLUMNS_IR1_3,
  "IR1.4": COLUMNS_IR1_4,
  "IR1.5": COLUMNS_IR1_5,
  "IR1.6": COLUMNS_IR1_6,
  "IR1.7": COLUMNS_IR1_7,
};

export const COMPONENTS_LIST = [1, 2, 3, 4];
export const TDB_TYPES = ["Graphes", "Cartes"];
export const YEARS = [2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030];
export const INDICATOR_TYPES = [
  {
    label: "Indicateur d'Objectif de Développement",
    code: "IODP",
  },
  {
    label: "Indicateur de Résultats Intermédiaire",
    code: "IRIP",
  },
];

export const VISIBLE_COLUMN_CLASSNAME = "bg-info mb-1 text-light";
export const HIDDEN_COLUMN_CLASSNAME = "mb-1";
