/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-len */
import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  BarController,
  DoughnutController,
  LineController,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { Spinner } from "components/spinner";
import HelperFunction from "helperfunctions";
import { getIndicatorValues } from "services/DashboardServices";
import Alert from "components/alert";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  BarController,
  DoughnutController,
  LineController,
  Title,
  Tooltip,
  Legend,
);

export const tempOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: "bottom",
    },
    title: {
      display: true,
      text: "Chart.js Bar Chart",
    },
    backgroundColor: "#ebab34",
  },
};

export default function BarChart(props) {
  const { graphName, indicator, color, displayTarget, selectedRegion } = props;
  const [chartData, setChartData] = useState();
  const [apiCalling, setApiCalling] = useState(true);
  const [hasError, setHasError] = useState(false);

  const getChartData = async (indicator) => {
    setApiCalling(true);
    setHasError(false);
    let params = new URLSearchParams();
    if (selectedRegion) {
      params.append("region", selectedRegion);
    }
    await getIndicatorValues(indicator.pk, params)
      .then((response) => {
        setChartData(
          HelperFunction.transformToDataSet(
            indicator,
            response.data,
            [color, "green"],
            displayTarget,
          ),
        );
        setApiCalling(false);
      })
      .catch((error) => {
        setHasError(true);
        setApiCalling(false);
      });
  };

  useEffect(() => {
    if (indicator) {
      getChartData(indicator);
    }
  }, [indicator, selectedRegion]);

  const handleBarClick = (event, chartElements) => {};

  return (
    <>
      {/* {(apiCalling) && <Spinner override={{ marginTop: "0" }} />}
      {(hasError) && <Alert description={"Une erreur est survenue"} />} */}
      {!apiCalling && !hasError && (
        <Bar
          options={HelperFunction.barChartOption(graphName, handleBarClick)}
          height={"300px"}
          data={chartData}
          className="w-fit h-fit"
        />
      )}
    </>
  );
}
