import React, { Component } from "react";
import "./DashboardStyle.css";

class NothingToShow extends Component {
  showSideBar() {
    // Hide the sidebar
    var sidebar = document.getElementById("sidebar");
    var sidebarShower = document.getElementById("sidebarShower");

    if (sidebar.classList.contains("d-flex")) {
      sidebar.classList.remove("d-flex");
      sidebar.style.display = "none";
      sidebarShower.classList.remove("hidden-content");
    } else {
      sidebar.classList.add("d-flex");
      sidebar.style.display = "block";
      sidebarShower.classList.add("hidden-content");
    }
  }

  render() {
    return (
      <div className="content-displayer p-3">
        <h1>RESSOURCE INTROUVABLE</h1>
        <button
          class="btn btn-danger mx-1 container-floatting-down-left hidden-content"
          onClick={() => this.showSideBar()}
          id="sidebarShower"
        >
          <i class="bi bi-list container-icon-customization-1"></i>
        </button>
      </div>
    );
  }
}

export default NothingToShow;
