import React, { useEffect, useState } from "react";
import "../../assets/scss/profilePage.scss";
import avatar from "../../assets/img/avatar.jpg";
import { AiOutlineUser, AiOutlineMail } from "react-icons/ai";
import FormEditProfile from "components/forms/formEditProfile";
import { FaUserGraduate } from "react-icons/fa";
import AuthService from "services/AuthServices";
import ProfilePageSkeleton from "./ProfilePageSkeleton";

const ProfilePage = () => {
  const [user, setUser] = useState();
  const [profilePictureUrl, setProfilePictureUrl] = useState("");

  useEffect(() => {
    setProfilePictureUrl(avatar);

    AuthService.getMe()
      .then((response) => {
        setUser(response.data);
      })
      .catch((error) => {});
  }, []);

  const changeProfilePicture = (e) => {
    setProfilePictureUrl(window.URL.createObjectURL(e.target.files[0]));
  };

  return (
    <div className="profile-page">
      {user ? (
        <div className="profile-container sm:max-w-xs w-full">
          <div className="cover-picture">
            <div className="picture-fullname">
              <img
                src={profilePictureUrl}
                alt="USER PIC"
                width={125}
                height={125}
                className="mb-2"
              />
              <p className="fw-bold">{`${user.surname} ${user.forename}`}</p>
            </div>
          </div>
          <div className="infos !px-4 sm:px-4">
            <div>
              <div>
                <AiOutlineUser className="display-6 rounded-circle shadow p-2 text-warning" />
              </div>
              <div>
                <small className="text-secondary fw-bold">Nom d'utilisateur</small>
                <div className="fw-bold">{user.username}</div>
              </div>
            </div>
            <div>
              <div>
                <AiOutlineMail className="display-6 rounded-circle shadow p-2 text-primary" />
              </div>
              <div>
                <small className="text-secondary fw-bold">E-mail</small>
                <div className="fw-bold">{user.email}</div>
              </div>
            </div>

            <div>
              <div>
                <FaUserGraduate
                  className="display-6 rounded-circle shadow p-2"
                  style={{ color: "#ae3aac" }}
                />
              </div>
              <div>
                <small className="text-secondary fw-bold">Rôles</small>
                <div className="fw-bold">
                  {user.groups.length > 1
                    ? user.groups.map((item, index) => {
                        if (index !== user.groups.length - 1) {
                          return `${item} &bull;`;
                        } else {
                          return item;
                        }
                      })
                    : user.groups[0]}
                </div>
              </div>
            </div>
          </div>
          <FormEditProfile
            userData={user}
            currentprofilePicUrl={profilePictureUrl}
            changeProfilePicture={changeProfilePicture}
          />
        </div>
      ) : (
        <ProfilePageSkeleton />
      )}
    </div>
  );
};

export default ProfilePage;
