import axios from "axios";
import { API_URL, history } from "../constants";
import { StatusCodes } from "http-status-codes";
import { Navigate } from "react-router-dom";
import { emitCustomEvent } from "react-custom-events";

let instanceConfig = {
  baseURL: API_URL,
  timeout: 5 * 60000,
  headers: {},
};

const token = JSON.parse(localStorage.getItem("access"));
if (token) {
  instanceConfig.headers.common = { Authorization: `Bearer ${token.trim()}` };
}

const axiosInstance = axios.create(instanceConfig);

axiosInstance.interceptors.request.use((config) => {
  const token = JSON.parse(localStorage.getItem("access"));
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  const trackDownloadProgress = (event) => {
    const [none, base, pk, suffix] = config.url.split("/");
    const key = `${pk}-downloaded`;
    if (event.loaded) {
      emitCustomEvent(key, Math.round((event.loaded * 100) / event.total));
    }
  };

  // Axios interceptor to monitor download progress
  config.onDownloadProgress = trackDownloadProgress;
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.config.url.includes("token/refresh")) {
      localStorage.removeItem("access");
      localStorage.removeItem("refresh");
      localStorage.removeItem("me-username");
      window.dispatchEvent(new Event("logout"));
      return;
    }
    let originalConfig = error.config;
    if (
      error.response.status === StatusCodes.UNAUTHORIZED &&
      error.response.data.code === "token_not_valid"
    ) {
      let refresh = localStorage.getItem("refresh");
      let refreshToken = JSON.parse(refresh);
      var payload = { refresh: refreshToken };
      try {
        const response = await axiosInstance.post("/token/refresh/", payload);
        let newToken = response.data.access;
        localStorage.setItem("access", JSON.stringify(newToken));
        originalConfig.headers.Authorization = `Bearer ${newToken.trim()}`;
        return axios(originalConfig);
      } catch (error) {
        localStorage.removeItem("access");
        localStorage.removeItem("refresh");
        localStorage.removeItem("me-username");
        window.dispatchEvent(new Event("logout"));
        return;
      }
    }
    return Promise.reject(error);
  },
);

function manageSuccessfullResponse(response) {
  var returnMe = new Map();
  var code = response.data.code;
  var message = "";
  var data = response.data;
  returnMe.set("responseType", "success");
  returnMe.set("code", code);
  returnMe.set("message", message);
  returnMe.set("data", data);

  return returnMe;
}

async function manageErrorBasedOnServerResponse(response) {
  var returnMe = new Map();
  var code = "";
  var message = "";
  var data = "";

  switch (response.data.code) {
    case "token_not_valid":
      code = response.data.code;
      message = response.data.detail;

      returnMe.set("code", code);
      returnMe.set("message", message);
      returnMe.set("data", message);
      return returnMe;

    case "user_not_found":
      code = response.data.code;
      message = response.data.detail;

      returnMe.set("code", code);
      returnMe.set("message", message);
      returnMe.set("data", message);

      return returnMe;

    default:
      code = response.statusText;
      message = response.statusText;
      data = response.data;

      returnMe.set("code", code);
      returnMe.set("message", message);
      returnMe.set("data", data);

      return returnMe;
  }
}

async function manageNoResponseError(response) {
  var returnMe = new Map();
  var code = response.data.code;
  var message = response.data.detail;
  var data = response.data;

  returnMe.set("code", code);
  returnMe.set("message", message);
  returnMe.set("data", data);

  return returnMe;
}

async function manageOtherError(response) {
  var returnMe = new Map();
  var code = response;
  var message = response;
  var data = response;

  returnMe.set("code", code);
  returnMe.set("message", message);
  returnMe.set("data", data);

  return returnMe;
}

export default axiosInstance;
