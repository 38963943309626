import React, { Component } from "react";
import { Button } from "react-bootstrap";
import {
  COLUMNS_INDICATOR,
  HIDDEN_COLUMN_CLASSNAME,
  VISIBLE_COLUMN_CLASSNAME,
  INDICATORS,
} from "../../constants";
import Dropdown from "react-bootstrap/Dropdown";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import "./DashboardStyle.css";
import { getElements } from "services/IndicatorServices";
import HelperFunction from "helperfunctions";
import { getIndicators } from "services/IndicatorServices";
import Indicator from "models/IndicatorModel";
import Select from "react-select";
import { Spinner } from "components/spinner";
import { downloadExcel } from "react-export-table-to-excel";
import autoBind from "react-autobind";
import { saveAs } from "file-saver";
import { write, utils } from "xlsx";

export default class DataTableTabContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      indicators: [],
      columnsIndicator: [],
      dataIndicator: [],
      operationInProgress: true,
      apiCallHasError: false,
      errorMessage: "",
      loading: true,
      selectedIndicatorLabel: "Indicateur",
    };
    autoBind(this);
  }

  componentDidMount() {
    getIndicators(this.props.component)
      .then((response) => {
        let tempList = [];
        response.data.results.forEach((element) => {
          tempList.push(Indicator.fromJson(element));
        });
        if (tempList) {
          this.getElementIndic(tempList[0].code);
        }
        this.setState({
          indicators: tempList,
          operationInProgress: false,
          apiCallHasError: false,
          errorMessage: "",
          selectedCode: tempList[0].code,
          columnsIndicator: COLUMNS_INDICATOR[tempList[0].code],
          selectedIndicatorLabel: tempList[0].code,
        });
      })
      .catch((error) => {
        let errorData = HelperFunction.handleErrors(error);
        this.setState({
          indicators: [],
          operationInProgress: false,
          apiCallHasError: true,
          errorMessage: errorData.get("code"),
        });
      });
  }

  getElementIndic(indicCode) {
    this.setState({ loading: true });
    getElements(INDICATORS[indicCode].link)
      .then((response) => {
        let tempList = response.data.results;
        this.setState({
          dataIndicator: tempList,
          columnsIndicator: COLUMNS_INDICATOR[indicCode],
          operationInProgress: false,
          apiCallHasError: false,
          errorMessage: "",
          loading: false,
        });
      })
      .catch((error) => {
        let errorData = HelperFunction.handleErrors(error);
        this.setState({
          dataIndicator: [],
          operationInProgress: false,
          apiCallHasError: true,
          errorMessage: errorData.get("code"),
          loading: false,
        });
      });
  }

  findFunction(column, columnNameToFind) {
    return column.name === columnNameToFind;
  }

  changeColumnVisibility(columnListToUpdate, columnName, indic) {
    let columnListClone = columnListToUpdate.slice();
    let columnIndex = columnListClone.findIndex((column) =>
      this.findFunction(column, columnName),
    );

    var c = columnListClone.at(columnIndex);
    if (c !== undefined) {
      c.isVisible = !c.isVisible;
      columnListClone[columnIndex] = c;
    }

    this.setState({
      columns_Indicator: columnListClone,
    });
  }

  transformIntoHeaderExcel(data) {
    let tempArray = [];
    data.forEach((element) => {
      const name = element.name;
      if (element.isVisible) {
        tempArray.push(name.charAt(0).toUpperCase() + name.slice(1));
      }
    });
    return tempArray;
  }

  transformIntoDataExcel(data) {
    let tempArray = [];
    data.forEach((elementData) => {
      let tempData = [];
      this.state.columnsIndicator.forEach((column) => {
        if (column.isVisible) {
          tempData.push(column.contentToDisplay(elementData));
        }
      });
      tempArray.push(tempData);
    });
    return tempArray;
  }

  handleDownloadExcel() {
    downloadExcel({
      fileName: `Lire-excel-${this.state.selectedIndicatorLabel}.xlsx`,
      sheet: this.state.selectedIndicatorLabel,
      tablePayload: {
        header: this.transformIntoHeaderExcel(this.state.columnsIndicator),
        body: this.transformIntoDataExcel(this.state.dataIndicator),
      },
    });
  }

  getMuiTheme = () =>
    createTheme({
      overrides: {
        MUIDataTable: {
          root: {
            backgroundColor: "#AAF",
          },
          paper: {
            boxShadow: "none",
          },
        },
        MUIDataTableBodyCell: {
          root: {
            backgroundColor: "#FFF",
          },
        },
        MuiToolbar: {
          root: {
            backgroundColor: "#f00",
          },
        },
        MuiTableCell: {
          head: {
            backgroundColor: "purple",
          },
        },
        MUIDataTableSelectCell: {
          headerCell: {
            backgroundColor: "blue",
          },
        },
      },
    });

  render() {
    const options = {
      filterType: "multiselect",
      selectableRowsHideCheckboxes: true,
      print: false,
      download: false,
      pagination: false,
      textLabels: {
        toolbar: {
          search: "Rechercher",
          downloadCsv: "Télécharger le fichier excel",
          print: "Imprimer",
          viewColumns: "Voir les colonnes",
          filterTable: "Filtrer le tableau",
        },
        filter: {
          all: "Tout",
          title: "Filtrer",
          reset: "Réinitialiser",
        },
        viewColumns: {
          title: "Visibilité des colonnes",
          titleAria: "Montrer/Cacher les colonnes",
        },
      },
      onDownload: (buildHead, buildBody, columns, values) => {
        const fileType =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const fileExtension = ".xlsx";

        console.log(values.forEach((val) => console.log(val)));

        const json = values.reduce((result, val) => {
          const temp = {};
          val.data.forEach((v, idx) => {
            const value = v instanceof Array ? v.join(", ") : v;
            temp[columns[idx].name.toUpperCase()] = value;
          });
          result.push(temp);
          return result;
        }, []);

        const fileName = `Lire-excel-${this.state.selectedIndicatorLabel}`;
        const ws = utils.json_to_sheet(json);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        saveAs(data, fileName + fileExtension);
        // cancel default  CSV download from table
        return false;
      },
    };

    return (
      <div className="m-2">
        {this.state.indicators[0] && (
          <div className="px-4 sm:w-2/5 m-auto mb-4">
            <Select
              options={this.state.indicators.map((indic) => ({
                value: indic.code,
                label: indic.code,
              }))}
              defaultValue={{
                value: this.state.indicators[0].code,
                label: this.state.indicators[0].code,
              }}
              onChange={(event) => {
                this.setState({
                  selectedIndicatorLabel: event.label,
                });
                this.getElementIndic(event.value);
              }}
            />
          </div>
        )}
        {this.state.loading ? (
          <Spinner />
        ) : (
          <ThemeProvider theme={this.getMuiTheme()}>
            <MUIDataTable
              data={this.state.dataIndicator}
              columns={this.state.columnsIndicator}
              options={options}
            />
          </ThemeProvider>
        )}
      </div>
    );
  }
}
