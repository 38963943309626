import HelperFunction from "helperfunctions";
import { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { getTaskFile } from "services/TaskServices";
import AddTaskWithFile from "components/forms/AddTaskWithFile";
import AddWithFile from "components/forms/AddTaskWithFile";
import { toast } from "react-toastify";
import autoBind from "react-autobind";

import GantPage from "./Gantpage";
import DownloadUpload from "./DownloadUpload";

class TaskPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isUploadModalShown: false,
    };
    autoBind(this);
  }

  showUploadModal() {
    this.setState({
      isUploadModalShown: true,
    });
  }

  hideUploadModal() {
    this.setState({
      isUploadModalShown: false,
    });
  }

  downloadTaskFile() {
    HelperFunction.downloadFile("/tasks/template/", "Tâches.xlsx");
  }

  onSuccessfullUpload() {
    this.forceUpdate();
  }

  render() {
    return (
      <>
        <Tabs className="mt-2 p-3">
          <TabList>
            <div className="d-flex flex-row">
              <div className="flex-grow-1">
                <Tab>
                  Tableau de gantt <i className="bi-bar-chart-steps"></i>
                </Tab>
                <Tab>
                  Liste de tâches <i className="bi bi-list-task"></i>
                </Tab>
              </div>
              <DownloadUpload
                downloadUrl={`projects/${this.props.projectId}/tasks/template/`}
                downloadFileName={"Tâches.xlsx"}
                uploadUrl={`projects/${this.props.projectId}/tasks/template/`}
                warningMessage={
                  "Cette action supprimera toute information relative aux anciennes tâches. Elle est irréversible"
                }
                modalName={"Charger des tâches"}
                onSuccessfullUpload={this.onSuccessfullUpload}
              />
            </div>
          </TabList>

          <TabPanel>
            <GantPage setToken={this.props.setToken} projectId={1} />
          </TabPanel>
          <TabPanel>
            <h2 style={{ margin: "auto", textAlign: "center" }}>Liste des tâches</h2>
            <GantPage
              setToken={this.props.setToken}
              projectId={1}
              displayTaskList={true}
            />
          </TabPanel>
        </Tabs>
      </>
    );
  }
}

export default TaskPage;
