import React, { Component } from "react";
import { UserTable } from "./UserTable";

export function ContentToDisplay(props) {
  if (props.apiCallProcessing) {
    return <Spinner />;
  } else {
    if (props.apiCallHasError) {
      return <NothingToShow />;
    } else {
      if (props.userList.length !== 0) {
        return (
          <UserTable
            userList={props.userList}
            updateFunction={(user) => props.updateFunction(user)}
            deleteFunction={(user) => props.deleteFunction(user)}
          />
        );
      } else {
        return <NoUser />;
      }
    }
  }
}

export function Spinner() {
  return (
    <div className="d-flex justify-content-center">
      <div className="spinner-border text-primary" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
}

export function NothingToShow() {
  return (
    <div className="d-flex justify-content-center">Une erreur s'est produite.</div>
  );
}

export function NoUser() {
  return <div className="d-flex justify-content-center">Aucun utilisateur trouvé.</div>;
}
