import React, { Component } from "react";
import "./DashboardStyle.css";
import AddTaskForm from "components/forms/formAddTask.js";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { getTasks, createTask, updateTask, getTaskFile } from "services/TaskServices";
import User from "models/userModel";
import { getAllUsers } from "services/UserServices";
import GantTable from "./gantcomponents/ganttable";
import TaskList from "./TaskList";
import HelperFunctions from "helperfunctions";
import autoBind from "react-autobind";
import AddTaskWithFile from "components/forms/AddTaskWithFile";
import HelperFunction from "helperfunctions";

class GantPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      apiCallHasError1: false,
      apiCallProcessing1: true,
      apiCallHasError2: false,
      apiCallProcessing2: true,
      apiCallHasError: false,
      apiCallProcessing: false,
      isModalShown: false,
      isUploadModalShown: false,
      projectList: [],
      userList: [],
      taskList: [],
      /**
       * This component is not aware about tasks list changes in GantTable (children displaying)
       * So when there is any state update, it set back the initial list. forceUpdate is meant to
       * be used when we do want to update the taskList.
       */
      forceUpdate: true,
      globalTaskList: [],
      focusedTask: null,
      yearStartFilter: null,
      yearEndFilter: null,
    };

    this.formModal = null; // Form modal containing variable.
    this.toastSuccess = null;
    autoBind(this);
  }

  componentDidUpdate() {
    if (
      (this.state.apiCallHasError1 || this.state.apiCallHasError2) &&
      !this.state.apiCallHasError
    ) {
      this.setState({
        apiCallHasError: true,
      });
    }

    let apiCallProcessing =
      this.state.apiCallProcessing1 || this.state.apiCallProcessing2;
    if (this.state.apiCallProcessing !== apiCallProcessing) {
      this.setState({ apiCallProcessing });
    }
  }

  async getUserList() {
    getAllUsers()
      .then((response) => {
        var tempList = [];
        var data = response.data.results;

        if (data.length > 0) {
          data.forEach((element) => {
            tempList.push(User.fromJson(element));
          });
        } // Convert received data from api into User

        this.setState({
          userList: tempList,
          apiCallHasError1: false,
          apiCallProcessing1: false,
        });
      })
      .catch((error) => {
        this.setState({
          apiCallHasError1: true,
          apiCallProcessing1: false,
        });
        let errorMessage = HelperFunctions.handleErrors(error).get("errormessage");

        toast.error(errorMessage, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  }

  async refreshTaskList(
    projectId,
    params = new URLSearchParams(),
    filterOnDate = false,
    onlyComponents = false,
  ) {
    this.setState({
      apiCallProcessing2: true,
    });

    if (this.state.yearStartFilter) {
      params.append("starts_after", this.state.yearStartFilter);
      params.append("ends_before", this.state.yearEndFilter);
    }

    if (onlyComponents) {
      params.append("no_parent", true);
    }

    // If params is empty, we will fetch composante only
    if (!params.has("parent")) {
      params.append("category", "Composante");
    }
    getTasks({
      projectId: projectId,
      params: params,
    })
      .then((response) => {
        var tempList = [];
        var data = response.data.results;

        if (params.has("starts_after")) {
          filterOnDate = true;
        }

        if (data.length > 0) {
          data.forEach((element) => {
            let task = null;
            task = HelperFunctions.taskfromJson(
              element,
              params.get("starts_after"),
              params.get("ends_before"),
            );
            tempList.push(task);
          });
        } // Convert received data from api into Task

        this.setState(
          {
            globalTaskList: tempList,
            taskList: tempList,
            // 1- We tell GantTable that we do want to update the task list
            //    by setting forceUpdate to true
            forceUpdate: true,
            apiCallHasError2: false,
            apiCallProcessing2: false,
            // 2- Then we set forceUpdate back to false so next state change wont affect
            //    task list
          },
          () => this.setState({ forceUpdate: false }),
        );
      })
      .catch((error) => {
        this.setState({
          apiCallHasError2: true,
          apiCallProcessing2: false,
        });

        let errorMessage = HelperFunctions.handleErrors(error).get("errormessage");

        toast.error(errorMessage, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  }

  componentDidMount() {
    this.getUserList();
    var params = new URLSearchParams();
    this.refreshTaskList(this.props.projectId, params, true, false);
  }

  showModal() {
    this.setState({
      isModalShown: true,
    });
  }

  showUploadModal() {
    this.setState({
      isUploadModalShown: true,
    });
  }

  hideUploadModal() {
    this.setState({
      isUploadModalShown: false,
    });
  }

  hideModal() {
    this.setState({
      isModalShown: false,
      focusedTask: null,
    });
  }

  showToast() {
    // Ouvre le toast du formulaire
    this.toastSuccess.show();
  }

  hideToast() {
    // Ferme le toast du formulaire
    this.toastSuccess.hide();
  }

  handleFormSubmit({ isUpdate, formData }) {
    if (isUpdate === true) {
      return this.updateTaskApi(formData);
    } else {
      return this.createTask(formData);
    }
  }

  downloadTaskFile() {
    getTaskFile()
      .then((result) => {
        if (!result.ok) {
          HelperFunction.handleDownloadFileError(result.statusText);
        }
      })
      .then((blob) => {
        HelperFunction.handleBlobDownloadFile(blob, "Tâches.xlsx");
      });
  }

  async createTask(formData) {
    let projectId = this.props.projectId;
    var payload = {
      name: formData.get("taskname"),
      description: formData.get("description"),
      start_date: formData.get("beginingdate"),
      deadline: formData.get("enddate"),
      assignee: formData.get("userId"),
      predecessors: JSON.parse(formData.get("predecessors")),

      parent: formData.get("parent"),
      approver: formData.get("approver"),
      informed: formData.get("informed"),
      estimated_amount: formData.get("estimatedAmount"),
      spent_amount: formData.get("spentAmount"),
      code: formData.get("code"),
      project: projectId,
      percentage: formData.get("progress"),
    };
    var createTaskResponse = await createTask(payload, projectId);

    return this.manageCreateTask(createTaskResponse);
  }

  manageCreateTask(response) {
    if (response.status === 201 || response.status === 200) {
      toast.success("Tâche enregistrée ", {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      this.refreshTaskList(this.props.projectId);

      this.hideModal();
    } else {
      toast.error(response.statusText, {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    return response;
  }

  updateTask(taskToUpdate) {
    this.setState({
      focusedTask: taskToUpdate,
    });

    setTimeout(this.showModal(), 500);
  }

  successfullFormCallback() {
    this.refreshTaskList(this.props.projectId);
    this.hideModal();
    toast.success("Tâche enregistrée ", {
      position: "bottom-left",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  successfullUploadCallback() {
    this.refreshTaskList(this.props.projectId);
    this.hideUploadModal();
    toast.success("Fichier chargé avec succès ", {
      position: "bottom-left",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    });
  }

  async updateTaskApi(formData) {
    let projectId = this.props.projectId;

    var payload = {
      name: formData.get("taskname"),
      description: formData.get("description"),
      start_date: formData.get("beginingdate"),
      deadline: formData.get("enddate"),
      assignee: formData.get("userId"),
      predecessors: JSON.parse(formData.get("predecessors")),

      parent: formData.get("parent"),
      approver: formData.get("approver"),
      informed: formData.get("informed"),
      estimated_amount:
        formData.get("estimatedAmount") === "" ? 0 : formData.get("estimatedAmount"),
      spent_amount:
        formData.get("spentAmount") === "" ? 0 : formData.get("spentAmount"),
      // spent_amount: formData.get("spentAmount"),
      code: formData.get("code"),
      project: projectId,
      percentage: formData.get("progress"),
    };

    var createUserResponse = await updateTask({
      payload: payload,
      code: this.state.focusedTask ? this.state.focusedTask.id : formData.get("code"),
      project: projectId,
    });

    return this.manageCreateTask(createUserResponse);
  }

  transformTaskList() {
    // let newTaskList =  [];
    // let taskListClone = this.state.taskList.slice
    // for(var task of taskListClone){
    //     if task.
    // }
  }

  render() {
    return (
      <div className="content-displayer p-3 not-scrollable mb-32 pb-32">
        <div>
          {!this.state.apiCallHasError && this.props.displayTaskList && (
            <TaskList
              tasks={this.state.taskList}
              projectList={this.state.projectList}
              updateFunction={this.updateTask}
              deleteFunction={this.deleteTask}
            />
          )}
          {!this.state.apiCallHasError && !this.props.displayTaskList && (
            <GantTable
              tasks={this.state.taskList}
              projectList={this.state.projectList}
              projectId={this.props.projectId}
              updateFunction={this.updateTask}
              deleteFunction={this.deleteTask}
              forceUpdate={this.state.forceUpdate}
              submitSearch={(params) =>
                this.refreshTaskList(this.props.projectId, params)
              }
              apiCallProcessing={this.state.apiCallProcessing}
              callBackSuccess={this.successfullFormCallback}
            />
          )}
          <ToastContainer />
        </div>

        <div id="modalformaddtask">
          <AddTaskForm
            show={this.state.isModalShown}
            hide={this.hideModal}
            task={this.state.focusedTask}
            projectId={this.props.projectId}
            userList={this.state.userList}
            taskList={this.state.globalTaskList}
            onFormSubmit={this.handleFormSubmit}
            callBackSuccess={this.successfullFormCallback}
          />
          <AddTaskWithFile
            show={this.state.isUploadModalShown}
            hide={this.hideUploadModal}
            projectId={this.props.projectId}
            callBackSuccess={this.successfullUploadCallback}
          />
        </div>
      </div>
    );
  }
}

export default GantPage;
