import React, { Component } from "react";
import { exportExcelById } from "simple_html_to_excel";
import "./DashboardStyle.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import DataTableTabContent from "./DataTableTabContent";
import DownloadUpload from "./DownloadUpload";
import { ToastContainer } from "react-toastify";

export default class DataTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      indicators: [],
      operationInProgress: true,
      apiCallHasError: false,
      errorMessage: "",
    };
  }

  downloadExcel() {
    exportExcelById("excel-download-content");
  }

  onSuccessfullUpload() {
    this.forceUpdate();
  }

  render() {
    return (
      <>
        <ToastContainer />

        <Tabs className="mt-2 h-screen overflow-auto p-3">
          <TabList>
            <div className="d-flex flex-row">
              <div className="flex-grow-1">
                {[1, 2, 3, 4].map((val, index) => (
                  <Tab key={index}>Composante {val}</Tab>
                ))}
              </div>
              <DownloadUpload
                downloadUrl={"formations/upload/"}
                downloadFileName={"Formations.xlsx"}
                uploadUrl={"formations/upload/"}
                modalName={"Charger un fichier"}
                onSuccessfullUpload={this.onSuccessfullUpload}
              />
            </div>
          </TabList>
          {[1, 2, 3, 4].map((val, index) => {
            return (
              <TabPanel key={index}>
                <DataTableTabContent component={val} />
              </TabPanel>
            );
          })}
        </Tabs>
      </>
    );
  }
}
