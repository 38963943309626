import axios from "axios";
import { Spinner } from "components/spinner";
import HelperFunction from "helperfunctions";
import React, { Component } from "react";
import { createTaskWithFile } from "services/TaskServices";
import Swal from "sweetalert2";
import { Modal } from "react-bootstrap";
import autoBind from "react-autobind";
import { EXCEL_EXTENSIONS } from "../../constants";

class AddTaskWithFile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFile: null,
      errorMessage: "",
      operationInProgress: false,
    };

    autoBind(this);
  }

  onFileChange = (event) => {
    this.setState({ selectedFile: event.target.files[0] });
  };

  uploadAction = (formData) => {
    this.setState({
      operationInProgress: true,
      errorMessage: "",
    });

    HelperFunction.creationWithFile({
      endPoint: this.props.uploadUrl,
      payload: formData,
    })
      .then((response) => {
        this.setState({
          operationInProgress: false,
          errorMessage: "",
        });
        if (this.props.callBackSuccess) {
          this.props.callBackSuccess();
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          operationInProgress: false,
          errorMessage: error.response.data.detail[0],
        });
      });
  };

  onFileUpload = () => {
    if (this.state.selectedFile) {
      const formData = new FormData();

      formData.append(
        this.props.payloadKey || "formatted_data",
        this.state.selectedFile,
        this.state.selectedFile.name,
      );

      setTimeout(() => {}, 1000);

      if (this.props.warningMessage) {
        Swal.fire({
          title: "Etes-vous sûr(e)?",
          text: this.props.warningMessage,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Oui, charger!",
          cancelButtonText: "Annuler",
        }).then((result) => {
          if (result.isConfirmed) {
            this.uploadAction(formData);
          }
        });
      } else {
        this.uploadAction(formData);
      }
    }
  };

  fileData = () => {
    if (this.state.operationInProgress) {
      return (
        <>
          <span
            class="spinner-grow spinner-grow-sm"
            role="status"
            aria-hidden="true"
          ></span>
          Chargement en cours...
        </>
      );
    } else {
      return <span>Soumettre</span>;
    }
  };

  onHide() {
    this.setState({
      selectedFile: null,
      errorMessage: "",
      operationInProgress: false,
    });
    this.props.hide();
  }

  render() {
    return (
      <>
        <Modal show={this.props.show} onHide={this.onHide} onShow={this.onShow}>
          <Modal.Header closeButton>
            <Modal.Title>{this.props.modalName}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row g-2 m-2">
              <div className="col-auto">
                <input
                  className="form-control"
                  type="file"
                  onChange={this.onFileChange}
                  accept={EXCEL_EXTENSIONS + ",.zip"}
                />
              </div>
              <div className="col-auto">
                <button className="btn btn-primary mb-3" onClick={this.onFileUpload}>
                  {this.fileData()}
                  {/* <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                  Chargement en cours... */}
                </button>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div>
              <span className="text-danger"> {this.state.errorMessage}</span>
            </div>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default AddTaskWithFile;
