import React, { Component } from "react";
import SyncLoader from "react-spinners/SyncLoader";

const override = {
  display: "block",
  margin: "auto",
  textAlign: "center",
  width: "100%",
  marginTop: "100px",
};

export class Spinner extends Component {
  render() {
    return (
      <div className="sweet-loading">
        <SyncLoader
          color={this.props.color || "blue"}
          loading
          cssOverride={this.props.override || override}
          size={this.props.size || 20}
        />
      </div>
    );
  }
}
