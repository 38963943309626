import { useState } from "react";
import AuthService from "./services/AuthServices";

export default function useToken() {
  const [token, setToken] = useState(AuthService.getCurrentUser());

  const saveToken = (token) => {
    setToken(token);
  };

  return {
    setToken: saveToken,
    token,
  };
}
