export default function IndicateurSupperpositionButton(props: any) {
  const { onClick } = props;
  return (
    <button
      type="button"
      id="overlay"
      onClick={onClick}
      className="absolute right-[40px] top-[150px] z-[999] flex items-center justify-center text-sm font-medium text-white bg-blue-900 rounded-md shadow-md lc-modal-show w-11 h-11 transition-pacofide"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        style={{ fill: "currentColor" }}
      >
        <path d="M4 6h2v2H4zm0 5h2v2H4zm0 5h2v2H4zm16-8V6H8.023v2H18.8zM8 11h12v2H8zm0 5h12v2H8z"></path>
      </svg>
    </button>
  );
}
