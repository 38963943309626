import * as React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import DownloadIcon from "@mui/icons-material/Download";
import "./card.scss";
import ChooseFileIcon from "./ChooseFileIcon";
import { DATE_FORMAT_OPTIONS } from "../../constants";
import { downloadDocument } from "../../services/LibraryServices";
import { StyledEngineProvider } from "@mui/material/styles";
import DescriptionModal from "./DescriptionModal";
import { Button } from "@mui/material";
import FormAddDocument from "components/forms/formAddDocument";
import FileLoader from "./Loader";

const LibraryCard = ({ cardData, reloadCardsList }) => {
  const [showEditButton, setShowEditButton] = React.useState(false);
  const userGroup = localStorage.getItem("group");
  const handleMouseEnterCardActionsBox = () =>
    userGroup === "RSE" && setShowEditButton(true);
  const handleMouseLeaveCardActionsBox = () => setShowEditButton(false);
  const [downloading, setDownloading] = React.useState(false);

  const download = (docId, docName) => {
    downloadDocument(docId)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", docName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {});
  };

  const truncateLetters = (str, number_of_letters) => {
    if (str.length > number_of_letters) {
      return str.split("").slice(0, number_of_letters).join("") + "...";
    } else {
      return str;
    }
  };

  let size = cardData.size;
  let unit = "o";

  if (size && size >= 1024 * 1024) {
    size = size / (1024 * 1024);
    unit = "Mo";
  } else if (size && size >= 1024) {
    size = size / 1024;
    unit = "Ko";
  }

  return (
    <StyledEngineProvider injectFirst>
      <Card className="shadow libraryCard" sx={{ width: 225 }}>
        <CardHeader
          className="cardHeader"
          action={
            downloading ? (
              <FileLoader
                setDownloading={setDownloading}
                className="MuiButtonBase-root MuiIconButton-root MuiIconButton-colorPrimary MuiIconButton-sizeMedium css-1dhog8w-MuiButtonBase-root-MuiIconButton-root"
                eventToListen={`${cardData.pk}-downloaded`}
              />
            ) : (
              <IconButton
                color="primary"
                onClick={() => {
                  setDownloading(true);
                  download(cardData.pk, cardData.filename);
                }}
              >
                <DownloadIcon className="downloadIcon" color="primary" />
              </IconButton>
            )
          }
          subheader={cardData.author}
        />
        <CardMedia
          className="cardMedia"
          component="img"
          image={ChooseFileIcon(cardData.filename)}
          alt="Paella dish"
        />
        <CardContent className="cardContent border-top">
          <Typography variant="body2" className="fw-bold">
            {cardData.name}
          </Typography>
          <div className="pt-2 description">
            {truncateLetters(cardData.description, 17)}{" "}
            {cardData.description.length > 17 ? (
              <DescriptionModal text={cardData.description} />
            ) : null}
          </div>
          <div className="custom-badge-container">
            <small className="custom-badge">{cardData.category}</small>
          </div>
        </CardContent>
        <CardActions
          className="cardActions"
          disableSpacing
          onMouseEnter={handleMouseEnterCardActionsBox}
          onMouseLeave={handleMouseLeaveCardActionsBox}
        >
          {!showEditButton ? (
            <div className="date-and-doc-size">
              <small>
                {new Date(cardData.publication_date).toLocaleDateString(
                  "fr-FR",
                  DATE_FORMAT_OPTIONS,
                )}
              </small>
              <small>
                {size.toFixed(2)}
                {unit}
              </small>
            </div>
          ) : (
            <FormAddDocument
              documentToUpdate={cardData}
              reloadCardsList={reloadCardsList}
            />
          )}
        </CardActions>
      </Card>
    </StyledEngineProvider>
  );
};

export default LibraryCard;
