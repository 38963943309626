import { MapContainer, GeoJSON } from "react-leaflet";
import L from "leaflet";
import country from "../../components/dashboard/shapes/nigerAdm01.json";

function MapSearch(props) {
  const { onEachFeature, resetLayers } = props;
  const bounds = L.geoJSON(country).getBounds();

  return (
    <div className="w-full">
      <button
        className="absolute top-4 right-4 bg-white color-white z-[9999] font-bold py-2 px-4 shadow-2xl drop-shadow-2xl rounded-2xl"
        onClick={resetLayers}
      >
        <i className="bi bi-arrow-clockwise text-black"></i>
      </button>

      <MapContainer
        bounds={bounds}
        zoomControl={false}
        dragging={false}
        zoom={20}
        doubleClickZoom={false}
        scrollWheelZoom={false}
        className="region-selector-leaflet leaflet-spacing"
      >
        <GeoJSON data={country} onEachFeature={onEachFeature} />
      </MapContainer>
    </div>
  );
}

export default MapSearch;
