import React, { Component } from "react";
import Swal from "sweetalert2";
import autoBind from "react-autobind";
// import whatsappSvg from "../../../assets/img/worlBank_logo.svg";
import whatsappSvg from "../../assets/img/WhatsappSvg.svg";

export class UserTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userGroup: localStorage.getItem("group"),
      userList: props.userList.map((user) => {
        user.selected = false;
        return user;
      }),
    };
    autoBind(this);
  }

  updateUser(user) {
    this.props.updateFunction(user);
  }

  deleteUser(user) {
    Swal.fire({
      title: "Etes-vous sûr(e)?",
      text: "Cette action est irréversible",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui, supprimer!",
      cancelButtonText: "Annuler",
    }).then((result) => {
      if (result.isConfirmed) {
        this.props.deleteFunction(user);
      }
    });
    // this.props.deleteFunction(user);
  }

  removeFirstChar(str) {
    if (str && str.startsWith("+")) {
      return str.substring(1);
    } else if (str && str.startsWith("00")) {
      return str.substring(2);
    } else {
      return str;
    }
  }

  openWhatsapp(num) {
    num = this.removeFirstChar(num);
    return `https://wa.me/${num}`;
  }

  selectAll(e) {
    this.setState({
      userList: this.state.userList.map((user) => {
        user.selected = e.target.checked;
        return user;
      }),
    });
  }

  selectUser(idx, value) {
    const userList = this.state.userList.map((user, index) => {
      if (index === idx) {
        user.selected = value;
      }
      return user;
    });
    this.setState({
      userList,
    });
  }

  anySelection() {
    for (let index = 0; index < this.state.userList.length; index++) {
      const element = this.state.userList[index];
      if (element.selected) return true;
    }
    return false;
  }

  getUserMailLists() {
    const mails = this.state.userList
      .filter((user) => user.selected)
      .map((user) => user.email)
      .join(",");
    return `https://mail.google.com/mail/?view=cm&fs=1&to=${mails}`;
  }

  render() {
    return (
      <div className="block w-full overflow-x-auto">
        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col" className="whitespace-nowrap">
                Nom et prénom
              </th>
              <th scope="col">Email</th>
              <th scope="col" className="whitespace-nowrap">
                Numéro de téléphone
              </th>
              <th scope="col">Rôle</th>
              {this.state.userGroup === "RSE" && (
                <th scope="col" className="text-center">
                  Action
                </th>
              )}
              <th scope="col" className="slim">
                <div className="flex items-center gap-x-2">
                  <input type="checkbox" onClick={this.selectAll}></input> Sélectionner
                  {this.anySelection() && (
                    <a
                      href={this.getUserMailLists()}
                      target="_blank"
                      className="underline pl-2 text-sm"
                    >
                      Envoyer mail
                    </a>
                  )}{" "}
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {this.state.userList.map((user, index) => {
              return (
                <tr key={user.id}>
                  <td className="whitespace-nowrap">{user.name}</td>
                  <td className="whitespace-nowrap">
                    {" "}
                    <a
                      href={`https://mail.google.com/mail/?view=cm&fs=1&to=${user.email}`}
                      target="_blank"
                    >
                      {user.email}
                    </a>{" "}
                  </td>
                  <td className="whitespace-nowrap">
                    <ul className="space-y-1 m-0 pl-0">
                      {user.phones?.map((elmt) => {
                        return (
                          <li key={elmt} className="flex gap-2">
                            {elmt.toString()}
                            <a
                              href={this.openWhatsapp(elmt)}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img
                                src={whatsappSvg}
                                alt="Send Whatsapp message"
                                style={{ width: "20px", height: "auto" }}
                              />
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </td>
                  <td className="whitespace-nowrap">
                    <ul className="m-0 pl-0">
                      {user.roles.map((role, index) => (
                        <li key={index}>
                          <span>{role.name}</span>
                        </li>
                      ))}
                    </ul>
                  </td>
                  {this.state.userGroup === "RSE" && (
                    <td className="whitespace-nowrap">
                      <div className="flex flex-nowrap mx-4">
                        <button
                          className="col btn btn-primary m-1"
                          onClick={() => this.updateUser(user)}
                          id="sidebarShower"
                        >
                          <i className="bi bi-pencil-square"></i>
                        </button>
                        <button
                          className="col btn btn-danger m-1"
                          onClick={() => this.deleteUser(user)}
                          id="sidebarShower"
                        >
                          <i className="bi bi-trash"></i>
                        </button>
                      </div>
                    </td>
                  )}
                  <td>
                    <input
                      type="checkbox"
                      checked={user.selected}
                      onChange={(e) => this.selectUser(index, e.target.checked)}
                    ></input>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}
