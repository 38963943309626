import React, { useState } from "react";
import "./LoginStyle.scss";
import axios from "services/axios.js";
import AuthService from "services/AuthServices";
import { Link, useLocation, useNavigate } from "react-router-dom";
import planingIcon from "../../assets/img/planingIcon.svg";
import followIcon from "../../assets/img/followIcon.svg";
import libraryIcon from "../../assets/img/libraryIcon.svg";
import dashboard_layout_icon from "../../assets/img/dashboard_layout_icon.svg";
import userIcon from "../../assets/img/user_icon.svg";
import lockIcon from "../../assets/img/lock_icon.svg";
import repNiger_logo from "../../assets/img/repNiger_logo.svg";
import lireProject_logo from "../../assets/img/lireProject_logo.svg";
import worlBank_logo from "../../assets/img/worlBank_logo.svg";
import gotoLink from "../../assets/img/gotoLink.svg";
import mmkouas from "../../assets/img/2mkouas.svg";

export default function LoginPage({ setToken }) {
  const navigate = useNavigate();
  const [data, setData] = useState({
    username: "",
    password: "",
  });

  const [processing, setProcessing] = useState(false);
  const [hasError, sethasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const { username, password } = data;

  const changeHandler = (e) => {
    setData({ ...data, [e.target.name]: [e.target.value] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setProcessing(true);

    var payload = {
      username: username[0],
      password: password[0],
    };
    axios
      .post("/token/", payload)
      .then((response) => {
        var tokenAccess = JSON.stringify(response.data.access);
        var tokenRefresh = JSON.stringify(response.data.refresh);
        var group = JSON.stringify(response.data.group);
        var tokenMap = new Map();
        tokenMap.set("access", tokenAccess);
        tokenMap.set("refresh", tokenRefresh);
        AuthService.saveToken(tokenMap);
        AuthService.saveGroup(group);
        setProcessing(false);
        setToken(tokenAccess);
        // navigate(
        //   location.pathname === "/" ? "/dashboard" : location.pathname
        // );
      })
      .catch((error) => {
        if (error.response) {
          var errorMessage = error.response.data.detail;
          setErrorMessage(errorMessage);
        } else if (error.request) {
          setErrorMessage("Connexion issue.");
        } else {
          // Something happened in setting up the request that triggered an Error
          setErrorMessage("Something went wrong. Contact us");
        }
        sethasError(true);
        setProcessing(false);
      });
  };

  const renderButtonContent = () => {
    return processing ? <Spinner /> : "connexion";
  };

  return (
    <div className="LoginPage">
      <div className="!hidden md:!grid app-features">
        <div className="main-feature-title">
          <div>Système de </div>
          <div>Suivi-Evaluation</div>
        </div>

        <div className="features">
          <div>
            <span>
              <img src={planingIcon} alt="PLAN_ICON" />
            </span>
            <span>Planification numérique</span>
          </div>
          <div>
            <span>
              <img src={dashboard_layout_icon} alt="FOLLOW_ICON" />
            </span>
            <span>Tableau de bord de suivi</span>
          </div>
          <div>
            <span>
              <img src={followIcon} alt="FOLLOW_ICON" />
            </span>
            <span>Suivi spatial numérique</span>
          </div>
          <div>
            <span>
              <img src={libraryIcon} alt="LIBRARY_ICON" />
            </span>
            <span>Bibliothèque numérique</span>
          </div>
        </div>
      </div>

      <div className="login-space">
        <div className="md:hidden main-feature-title text-center text-white text-2xl mt-3">
          <div>Système de </div>
          <div>Suivi-Evaluation</div>
        </div>
        <div className="login-container">
          <div className="partners">
            <img src={repNiger_logo} alt="Rep. Niger" width={100} height={50} />
            <img src={lireProject_logo} alt="LIRE" width={100} height={70} />
            <img src={worlBank_logo} alt="BM" width={200} height={75} />
          </div>

          <form onSubmit={handleSubmit}>
            <div>
              <label htmlFor="">
                <img src={userIcon} alt="USER_ICON" width={15} height={15} />
              </label>
              <input
                type="text"
                placeholder="Identifiant"
                id="username"
                name="username"
                value={username}
                onChange={changeHandler}
                required
              />
            </div>
            <div>
              <label htmlFor="">
                <img src={lockIcon} alt="LOCK_ICON" width={15} height={15} />
              </label>
              <input
                type="password"
                placeholder="Mot de passe"
                id="password"
                name="password"
                value={password}
                onChange={changeHandler}
                required
              />
            </div>
            <Link to="#">Mot de passe oublié?</Link>
            {hasError ? (
              <p className="text-center text-danger">{errorMessage}</p>
            ) : null}
            <div className="submit-button-container">
              <button type="submit">{renderButtonContent()}</button>
            </div>
          </form>
        </div>

        <div className="developers-firm">
          <div>
            <span>GéoDigital</span>
            <Link to="#">
              <img src={gotoLink} alt="GO_TO" width={15} height={15} />
            </Link>
          </div>
          <div>
            <img src={mmkouas} width={60} />
            <Link to="#">
              <img src={gotoLink} alt="GO_TO" width={15} height={15} />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export function Spinner() {
  return (
    <div className="d-flex justify-content-center">
      <div className="spinner-border text-light" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
}
