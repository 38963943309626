import React, { Component, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "./logo.png";
import logo2 from "../../../src/logo.png";
import useLocalStorage from "useLocalStorage";
import AuthService from "services/AuthServices";

function Sidebar({ reduceSideBar, user, setPageTitle }) {
  const location = useLocation();
  let path = location.pathname;
  const [tabs, setTabs] = useState([
    {
      link: "",
      icon: "bi-house-fill",
      dispay: "Tableau de bord",
      class: path === "/dashboard" ? "active" : "link-dark",
      isRestricted: false,
    },
    {
      link: "gantt",
      icon: "bi-bar-chart-steps",
      dispay: "Diagramme de Gantt",
      class: path.includes("gantt") ? "active" : "link-dark",
      isRestricted: true,
    },
    {
      link: "courbe",
      icon: "bi-wallet2",
      dispay: "Finances",
      class: path.includes("courbe") ? "active" : "link-dark",
      isRestricted: true,
    },
    {
      link: "mapping",
      icon: "bi-map-fill",
      dispay: "Mapping",
      class: path.includes("mapping") ? "active" : "link-dark",
      isRestricted: false,
    },
    {
      link: "datatable",
      icon: "bi-table",
      dispay: "Tableaux de données",
      class: path.includes("datatable") ? "active" : "link-dark",
      isRestricted: false,
    },
    {
      link: "numeric-library",
      icon: "bi-book",
      dispay: "Bibilothèque numérique",
      class: path.includes("numeric-library") ? "active" : "link-dark",
      isRestricted: false,
    },
    {
      link: "userlist",
      icon: "bi-people-fill",
      dispay: "Utilisateurs",
      class: path.includes("userlist") ? "active" : "link-dark",
      isRestricted: false,
    },
  ]);

  const [activeTab, setActiveTab] = useState(null);
  const [userRegion, setUserRegion] = useState();
  const [hasAllAccess, setHasAllAccess] = useState(true);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (userRegion) {
      setHasAllAccess(false);
    }
  }, [userRegion]);

  useEffect(() => {
    if (!user) return;
    setUserRegion(localStorage.getItem("region-id", null));
    setLoading(false);
  }, [user]);

  const setActive = (key) => {
    var items = tabs;
    items.forEach((element) => {
      element.class = "link-dark";
    });
    items[key].class = "active";
    setTabs(items);
    setActiveTab(items[key].link);
  };

  return (
    <div
      className={`sidebar d-flex flex-column flex-shrink-0 p-3 bg-light ${
        reduceSideBar ? "sidebar-reduction" : null
      }`}
      id="sidebar"
    >
      {!reduceSideBar && (
        <div className="relative">
          <span className="absolute md:hidden right-3 top-3 flex items-center justify-center h-[30px] w-[30px] hover:bg-gray-200 text-slate-500 text-2xl cursor-pointer rounded-full transition-all ease-in-out duration-300">
            <i className="bi-x"></i>
          </span>
        </div>
      )}
      {!reduceSideBar ? (
        <button
          style={{ padding: 0 }}
          className=" btn btn-light d-flex align-items-center mr-auto mb-3 mb-md-0 me-md-auto link-dark text-decoration-none"
        >
          <img className="logoStyle" alt="" src={logo} />
        </button>
      ) : (
        <img alt="" src={logo2} width={50} height={50} />
      )}
      <hr></hr>

      <ul className="nav nav-pills flex-column mb-auto">
        {!loading &&
          tabs.map((item, key) => {
            if (!hasAllAccess && item.isRestricted) {
              return null;
            } else {
              return (
                <li className="nav-item" key={key}>
                  <Link
                    onClick={() => {
                      setActive(key);
                      setPageTitle("");
                    }}
                    className={"nav-link " + item.class}
                    to={`/dashboard/${item.link}`}
                  >
                    <i className={item.icon}></i> {!reduceSideBar ? item.dispay : null}
                  </Link>
                </li>
              );
            }
          })}
        <hr></hr>
      </ul>
    </div>
  );
}

export default Sidebar;
