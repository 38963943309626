import { Task } from "gantt-task-react";
import { useEffect, useState } from "react";

export interface NTask extends Task {
  description?: string | undefined;
  assignee?: number;
  assignedUser?: string;
}

export function useLocalStorageState(key: string, defaultValue: any) {
  const [state, setState] = useState(() => {
    let value;
    try {
      const keyValue = localStorage.getItem(key);
      if (keyValue) {
        value = JSON.parse(keyValue) || defaultValue;
      } else {
        value = defaultValue;
      }
    } catch (error) {
      value = defaultValue;
    }
    return value;
  });

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(state));
  }, [key, state]);

  return [state, setState];
}
