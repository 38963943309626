import { requestHeader } from "./helperServices";
import axios from "./axios.js";

export async function getIndicators(component) {
  return axios.get("/indicators/", {
    headers: requestHeader(),
    params: { component },
  });
}

export async function getIndicatorValues(indicatorId, params) {
  return axios.get(`/indicators/${indicatorId}/values/by_year/`, {
    headers: requestHeader(),
    params,
  });
}

export async function getRegions(params = new URLSearchParams()) {
  return axios.get("/regions/", {
    headers: requestHeader(),
    params,
  });
}

export async function getDepartements(params = new URLSearchParams()) {
  return axios.get("/departements/", {
    headers: requestHeader(),
    params,
  });
}

export async function getDataByYear(indicatorId, region = null) {
  let params = {};
  if (region) {
    params.region = region;
  }
  return axios.get(`/indicators/${indicatorId}/values/by_year/`, {
    headers: requestHeader(),
    params,
  });
}

export async function getDataByTrimester(indicatorId, year, composante, region = null) {
  let params = { year, composante };
  if (region) {
    params.region = region;
  }

  return axios.get(`/indicators/${indicatorId}/values/by_trimester/`, {
    headers: requestHeader(),
    params,
  });
}

export function getDataByRegion(indicatorId, year = null) {
  const params = new URLSearchParams();
  if (year) {
    params.append("year", year);
  }
  return axios.get(`/indicators/${indicatorId}/values/by_region/`, {
    headers: requestHeader(),
    params,
  });
}
