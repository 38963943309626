import React, { Component, useEffect, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import "react-tabs/style/react-tabs.css";
import { useOutletContext } from "react-router-dom";
import { getIndicators, getRegions } from "services/DashboardServices";
import { getDataByYear } from "../../services/DashboardServices";
import MapSearch from "./MapSearch";
import AreaModalController from "./tdb/AreaModalController";
import { COMPONENTS_LIST } from "constants";
import ComponentParameterModal from "./tdb/ComponentParameterModal";
import { INDICATOR_TYPES, YEARS } from "constants";
import GraphSide from "./GraphSide";
import MapSide from "./MapSide";

const ABSISCES_TYPES = {
  MULTI_ANNUEL: {
    value: "MULTI_ANNUEL",
    labels: YEARS,
  },
  ANNUEL: {
    value: "ANNUEL",
    labels: ["T1", "T2", "T3", "T4"],
  },
};

const Tableau = (tdbPropos) => {
  const { tdbType, currentComponent } = tdbPropos;

  var abscisseTypes = Object.keys(ABSISCES_TYPES).map((absType) => ({
    label: ABSISCES_TYPES[absType].value.replace(/_/g, " "),
    value: ABSISCES_TYPES[absType].value,
  }));

  const userRegionId = localStorage.getItem("region-id");
  const [abscisseType, setAbscisseType] = useState(abscisseTypes[0].value);
  const [intervals, setIntervals] = useState([]);
  const [loading, setLoading] = useState(false);
  const [indicatorsList, setIndicatorsList] = useState([]);
  const [regionsList, setRegionsList] = useState([]);
  const [region, setRegion] = useState();
  const [indicator, setIndicator] = useState();
  const [loadingGraphValues, setLoadingGraphValues] = useState(false);

  const [data, setData] = useState({
    labels: ABSISCES_TYPES.MULTI_ANNUEL.labels,
    datasets: [
      {
        label: "Situation indicateur",
        data: [],
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "Référence indicateur",
        data: [],
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
    abscisseType: ABSISCES_TYPES.MULTI_ANNUEL.value,
  });
  const [donData, setDonData] = useState();
  const [dataFromRegion, setDataFromRegion] = useState([]);
  const [selectedYears, setSelectedYears] = useState("selectedYears");
  const [firstSelectedYear, setFirstSelectedYear] = useState("firstSelectedYear");
  const [displayedComponentSelector, setDisplayedComponentSelector] = useState(false);
  const [displayedParametersSelector, setDisplayedParametersSelector] = useState(true);
  const [onEachFeature, setOneachFeature] = useState(null);
  const [allLayers, setAllLayers] = useState([]);
  const [openMap, setOpenMap] = useState(false);
  // const [currentComponent, setCurrentComponent] = useState(COMPONENTS_LIST[0]);

  const INTERVENTION_STYLE = { fillColor: "#0c63e4", fillOpacity: 0.5 };
  const DEFAULT_STYLE = { fillColor: "gray", fillOpacity: 0.2 };
  let layers = [];

  const resetLayer = function (layer, withSelection = true) {
    layer.setStyle(withSelection ? INTERVENTION_STYLE : { fillColor: "white" });
  };
  const resetLayers = function () {
    allLayers.forEach((layer) => resetLayer(layer));
    setRegion(undefined);
  };

  const tempOnEachFeature = (feature, layer) => {
    resetLayer(layer);
    layers.push(layer);
    setAllLayers(layers);
    layer.on("click", function (e) {
      layers.forEach((layer) => resetLayer(layer, false));
      setRegion(
        regionsList.find(
          (region) => region.nom.toUpperCase() === feature.properties.NOMREG,
        ),
      );
      layer.setStyle(INTERVENTION_STYLE);
    });
    if (feature.properties.adm_01 !== "Niamey") {
      layer
        .bindTooltip(feature.properties.adm_01, {
          permanent: true,
          direction: "center",
          className: "map-labels",
        })
        .openTooltip();
    }
  };

  useEffect(() => {
    console.log("UPPPPP");
    // Get indicators and regions. this useEffect occure on the first component's render
    getRegions()
      .then((response) => {
        setRegionsList([{ nom: "NATIONAL", pk: 0 }, ...response.data.results]);
      })
      .catch((error) => {});

    getIndicators(currentComponent)
      .then((response) => {
        setIndicatorsList(response.data.results);
      })
      .catch((error) => {});
  }, [currentComponent]);

  // Update data per year and set firstSelectedYear
  useEffect(() => {
    if (indicator && indicator.pk && region && selectedYears) {
      console.log(selectedYears);
      renderMultiAnnuelChart();
      if (selectedYears[0]) {
        setFirstSelectedYear(selectedYears[0]);
      }
    }
  }, [indicator, region, selectedYears, abscisseType]);

  // Update dounught and map

  useEffect(() => {
    if (regionsList.length > 0) {
      setOneachFeature(() => tempOnEachFeature);
    }
  }, [regionsList]);

  const handleChangeIndicator = (indicObject) => {
    let chosenIndicator = indicatorsList.find(
      (indic) => indic.code === indicObject.code,
    );
    setIndicator(chosenIndicator);
    // getSelectedIndicatorValues(chosenIndicator.pk);
  };

  const handleChangeRegion = (regionObject) => {
    let chosenRegion = regionsList.find((region) => region.pk === regionObject.value);
    setRegion(chosenRegion);
  };

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    ArcElement,
    Title,
    Tooltip,
    Legend,
  );

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Référence et évolution de l'indicateur",
      },
    },
  };

  const donoughtOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Valeur de l'indicateur par région",
      },
    },
  };

  const initDataset = (labels) => {
    return [
      {
        label: "Situation indicateur",
        borderColor: "rgb(255, 99, 132)",
        data: Array(labels.length).fill(0),
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "Référence indicateur",
        borderColor: "rgb(53, 162, 235)",
        data: Array(labels.length).fill(0),
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ];
  };

  const computeIntervals = (datasets) => {
    let realized = datasets[0]["data"];
    let planned = datasets[1]["data"];
    let atTop = realized[0] > planned[0] ? "realised" : "planned";
    atTop = realized[0] === planned[0] ? "none" : atTop;
    let total = Math.abs(realized[0] - planned[0]);
    let current_interval = { atTop, duration: 1, from: 0, to: 0, total };
    let intervals = [];
    for (let index = 1; index < realized.length; index++) {
      const realValue = realized[index];
      const planValue = planned[index];
      let diff = Math.abs(realValue - planValue);
      if (realValue > planValue) {
        if (atTop === "realised") {
          current_interval.to = index;
          current_interval.duration += 1;
          current_interval.total += diff;
        } else {
          intervals.push(current_interval);
          atTop = "realised";
          current_interval = { atTop, duration: 1, from: index, total: diff };
        }
      } else if (realValue < planValue) {
        if (atTop === "planned") {
          current_interval.to = index;
          current_interval.duration += 1;
          current_interval.total += diff;
        } else {
          intervals.push(current_interval);
          atTop = "planned";
          current_interval = { atTop, duration: 1, from: index, total: diff };
        }
      } else {
        if (atTop === "none") {
          current_interval.to = index;
          current_interval.duration += 1;
          current_interval.total += diff;
        } else {
          intervals.push(current_interval);
          atTop = "none";
          current_interval = { atTop, duration: 1, from: index, total: diff };
        }
      }
    }
    intervals.push(current_interval);
    return intervals;
  };

  const renderMultiAnnuelChart = () => {
    setLoading(true);
    getDataByYear(indicator.pk, region ? region.pk : 0).then((response) => {
      const labels = selectedYears;
      let datasets = initDataset(labels);
      let dataMap = new Map();
      response.data.forEach((dataValue) => {
        dataMap.set(dataValue.year, dataValue);
      });

      labels.forEach((year, index) => {
        let yearData = dataMap.get(year);
        if (yearData) {
          datasets[0].data[index] = yearData.total_real;
          datasets[1].data[index] = yearData.total_planned;
        }
      });
      setData({
        labels,
        datasets,
        abscisseType: ABSISCES_TYPES.MULTI_ANNUEL.value,
      });
      setIntervals(computeIntervals(datasets));
      setLoading(false);
      let barDatasets = datasets.map((set) => {
        let newSet = { ...set };
        newSet.data = [0, ...set.data, 0];
        return newSet;
      });
    });
  };

  const handleYearSelection = (event) => {
    if (Array.isArray(event)) {
      const years = event.sort((a, b) => a - b);
      setSelectedYears(years);
    }
  };

  // const handleChangeComponent = (component) => {
  //   setCurrentComponent(component);
  // };

  return (
    <div className="flex flex-row h-screen pb-40 mb-40">
      <button
        id="map"
        type="button"
        className="h-14 w-14 fixed right-6 bottom-10 z-40 flex lg:hidden items-center justify-center rounded-full bg-[#0d6efd] text-white cursor-pointer opacity-1 pointer-events-auto transition-all ease-in-out duration-300"
        onClick={() => setOpenMap(true)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          style={{ fill: "currentColor" }}
          className="h-8 w-8"
        >
          <path d="m21.447 6.105-6-3a1 1 0 0 0-.895 0L9 5.882 3.447 3.105A1 1 0 0 0 2 4v13c0 .379.214.725.553.895l6 3a1 1 0 0 0 .895 0L15 18.118l5.553 2.776a.992.992 0 0 0 .972-.043c.295-.183.475-.504.475-.851V7c0-.379-.214-.725-.553-.895zM10 7.618l4-2v10.764l-4 2V7.618zm-6-2 4 2v10.764l-4-2V5.618zm16 12.764-4-2V5.618l4 2v10.764z"></path>
        </svg>
      </button>
      {/* <button
        type="button"
        className="absolute right-[20px]  z-[100] flex items-center justify-center text-sm font-medium text-white bg-blue-900 rounded-md shadow-md lc-modal-show w-11 h-11 transition-pacofide"
        onClick={() => setDisplayedComponentSelector(!displayedComponentSelector)}
      >
        <AreaModalController />
      </button>
      {displayedComponentSelector && (
        <div className="position-absolute  right-[20px] z-[99] w-1/4 z-2 bg-white border-2 border-blue-900">
          <ComponentParameterModal
            selectedComponent={currentComponent}
            handleChangeComponent={handleChangeComponent}
          />
        </div>
      )} */}
      {!Boolean(userRegionId) && (tdbType === "Graphes" || !tdbType) && (
        <div className="w-1/4 lg:flex lg:flex-col md:justify-center shadow-2xl drop-shadow-2xl rounded-2xl m-4 h-full hidden flex flex-col">
          {onEachFeature && (
            <MapSearch
              onEachFeature={onEachFeature}
              resetLayers={resetLayers}
              setMapFunction={setOneachFeature}
            />
          )}
        </div>
      )}
      {tdbType === "Graphes" || !tdbType ? (
        <div className="flex-grow justify-center p-2 md:p-8 rounded-md bg-white mb-2 h-full overflow-y-auto mb-8 py-4">
          {indicatorsList &&
            INDICATOR_TYPES.map((indicator_type) => {
              return (
                <GraphSide
                  currentComponent={currentComponent}
                  // handleChangeComponent={handleChangeComponent}
                  indicatorsList={indicatorsList}
                  selectedRegion={region}
                  indicator_type={indicator_type}
                />
              );
            })}
        </div>
      ) : (
        <div className="flex flex-grow justify-center p-2 md:p-8 rounded-md bg-white mb-2 h-full overflow-y-auto mb-8 py-4">
          {indicatorsList && (
            <MapSide
              currentComponent={currentComponent}
              // handleChangeComponent={handleChangeComponent}
              indicatorsList={indicatorsList}
              selectedRegion={region}
            />
          )}
        </div>
      )}

      {/* Here comes Map modal */}
      <div
        className={`${
          openMap ? "" : "hidden"
        } lc-modal fixed left-0 top-0 w-[300px] h-full bg-white pt-5 z-[99999] transition-pacofide`}
        data-lc-target="map"
      >
        <span
          className="lc-modal-icon-cross absolute right-3 top-3 flex items-center justify-center h-[30px] w-[30px] hover:bg-gray-200 text-slate-500 text-2xl z-[2] cursor-pointer rounded-full transition-pacofide"
          data-lc-target="#map"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            style={{ fill: "currentColor" }}
            onClick={() => setOpenMap(false)}
          >
            <path d="m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242z"></path>
          </svg>
        </span>

        <div className="mt-20 h-[95%]">
          <div className="relative h-full">
            {onEachFeature && (
              <MapSearch
                onEachFeature={onEachFeature}
                resetLayers={resetLayers}
                setMapFunction={setOneachFeature}
              />
            )}
          </div>
        </div>
      </div>
      <div
        className={`${
          openMap ? "" : "hidden"
        } lc-modal-overlay fixed left-0 top-0 h-full w-full bg-black/50 z-[9999] transition-pacofide`}
        data-lc-target="map"
        onClick={() => setOpenMap(false)}
      ></div>
    </div>
  );
};

const TabsDash = (props) => {
  const onSuccessfullUpload = () => {
    this.forceUpdate();
  };
  const [currentComponent, setCurrentComponent] = useState(COMPONENTS_LIST[0]);
  const [displayedComponentSelector, setDisplayedComponentSelector] = useState(false);

  const handleChangeComponent = (component) => {
    setCurrentComponent(component);
  };

  const [user, setUser, setPageTitle] = useOutletContext();
  const [ownPageTitle, setOwnPageTitle] = useState("");
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    const names = ["Tableau de bord", "Cartographie"];
    const pageTitle = `${names[tabIndex]} des indicateurs de la composante ${currentComponent}`;
    setPageTitle(pageTitle);
    setOwnPageTitle(pageTitle);
  }, [tabIndex, currentComponent]);

  return (
    <div>
      <button
        type="button"
        className="absolute right-[20px]  z-[100] flex items-center justify-center text-sm font-medium text-white bg-blue-900 rounded-md shadow-md lc-modal-show w-11 h-11 transition-pacofide"
        onClick={() => setDisplayedComponentSelector(!displayedComponentSelector)}
      >
        <AreaModalController />
      </button>
      {displayedComponentSelector && (
        <div className="position-absolute  right-[20px] z-[99] w-[280px] z-2 bg-white border-2 border-blue-900">
          <ComponentParameterModal
            selectedComponent={currentComponent}
            handleChangeComponent={handleChangeComponent}
          />
        </div>
      )}
      {/*<h4 className="text-center">Cartographie des indicateurs de la composante 1</h4>*/}
      <h4 className="text-center mt-[54px] mb-[20px] block md:hidden">
        {ownPageTitle}
      </h4>
      <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
        <TabList>
          <Tab>Graphes</Tab>
          <Tab>Cartes</Tab>
        </TabList>
        <TabPanel>
          <Tableau currentComponent={currentComponent} />
        </TabPanel>
        <TabPanel>
          <Tableau tdbType={"Cartes"} currentComponent={currentComponent} />
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default TabsDash;
