import { requestHeader } from "./helperServices";
import axios from "./axios.js";

export async function getIndicators(component = 1) {
  // Récupère toutes les utilisateurs
  return axios.get("/indicators/", {
    headers: requestHeader(),
    params: { component },
  });
}

export async function getElements(link) {
  // Récupère toutes les utilisateurs
  return axios.get(link, { headers: requestHeader() });
}
