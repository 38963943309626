import Select from "@mui/material/Select";
import { createTheme, styled } from "@mui/material/";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#0d6efd",
    },
  },
});

export const CustomizedSelect = styled(Select)(() => ({
  "&.MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: theme.palette.primary.main,
      borderWidth: 2,
    },
    "&:hover fieldset": {
      borderColor: theme.palette.primary.main,
      borderWidth: 2,
    },
    "&.Mui-focused fieldset": {
      borderColor: theme.palette.primary.main,
      borderWidth: 2,
    },
  },
}));
