import React, { Component } from "react";
import { Accordion } from "react-bootstrap";
import { ViewSwitcher } from "./view-switcher";
import TaskFilter from "../TaskFilter";
import autoBind from "react-autobind";
import { ViewMode } from "gantt-task-react";

export default class GanttPageHeader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      detailView: false,
    };

    autoBind(this);
  }

  setView(viewMode) {
    this.props.onViewModeChange(viewMode);
    this.setState({ detailView: viewMode !== ViewMode.Year });
    localStorage.setItem("viewMode", viewMode);
  }

  render() {
    return (
      <Accordion defaultActiveKey="1" className="mb-4 shadow-sm rounded">
        <Accordion.Item eventKey="1">
          <Accordion.Header>
            <h4 className="text-xs sm:text-2xl" style={{ margin: "auto" }}>
              Diagramme de Gantt Niger LIRE
            </h4>
          </Accordion.Header>
          <Accordion.Body>
            <div className="mb-2">
              <ViewSwitcher
                onmy={this.props.onmy}
                onViewModeChange={this.setView}
                onViewListChange={this.props.onViewListChange}
                isChecked={this.props.isChecked}
                changeColumnVisibility={this.props.changeColumnVisibility}
                columnList={this.props.columnList}
                selectedViewMode={this.props.selectedViewMode}
              />
            </div>
            <TaskFilter
              submitSearch={(params) => this.props.submitSearch(params)}
              detailView={this.state.detailView}
              calendarOnly
              filterWithCurrentYear={this.props.filterWithCurrentYear}
            />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    );
  }
}
