import React, { Component, useEffect, useState } from "react";
import Sidebar from "components/sidebar/Sidebar";
import HelperFunction from "helperfunctions";
import { Outlet } from "react-router-dom";
import "../sidebar/SidebarStyle.css";
import AuthService from "services/AuthServices";
import { useNavigate } from "react-router-dom";

function Dashboard({ setToken, setSignedUser, setLoading }) {
  const navigate = useNavigate();
  const [me, setMe] = useState();
  const [user, setUser] = useState();
  const [pageTitle, setPageTitle] = useState("");

  const unlogUser = () => {
    setToken("");
    AuthService.logout();
    navigate("/");
  };

  const [reduceSideBar, setReduceSideBar] = useState(false);
  const toggleReduceSideBar = (state) => {
    setReduceSideBar(state ?? !reduceSideBar);
  };

  const reduceSideBarInMobile = () => {
    if (window.innerWidth <= 768) {
      toggleReduceSideBar(true);
    }
  };

  useEffect(() => {
    reduceSideBarInMobile();
    window.addEventListener("resize", reduceSideBarInMobile);
    AuthService.getMe()
      .then((response) => {
        setUser(response.data);
        setLoading(false);
        AuthService.saveUserName(response.data.username);
        AuthService.saveGroup(response.data.groups[0]);
        AuthService.saveRegionId(response.data);
        let useridentity = AuthService.getCurrentUsername();
        if (useridentity) {
          setMe(useridentity);
        }
        setLoading(false);
        setSignedUser(response.data);
      })
      .catch((error) => {
        setUser({ username: "Utilisateur" });
      });
  }, []);

  const showCurrentUserPage = () => {
    navigate("profile-page");
  };

  return (
    <div className="d-flex flex-row">
      <div>
        {" "}
        <Sidebar
          reduceSideBar={reduceSideBar}
          user={user}
          setPageTitle={setPageTitle}
        />{" "}
      </div>
      <div className="flex-grow-1 page-container flex flex-column">
        <div className="topBar">
          <button
            className="btn btn-customcolor mx-1 inline-block"
            onClick={() => toggleReduceSideBar()}
          >
            {reduceSideBar ? (
              <i className="bi bi-chevron-double-right sidebar-icon-customization-1"></i>
            ) : (
              <i className="bi bi-chevron-double-left sidebar-icon-customization-1"></i>
            )}
          </button>
          {me && (
            <>
              <h4 className="text-center hidden md:block">{pageTitle}</h4>
              <Username
                showCurrentUserPage={showCurrentUserPage}
                unlogUser={unlogUser}
                username={me}
              />
            </>
          )}
        </div>
        <Outlet context={[user, setUser, setPageTitle]} />
      </div>
    </div>
  );
}

export default Dashboard;

function Spinner() {
  return (
    <div className="btn btn-light">
      <span
        className="spinner-grow spinner-grow-sm"
        role="status"
        aria-hidden="true"
      ></span>
    </div>
  );
}

class Username extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: props.username,
      loadingUsername: false,
    };
  }

  render() {
    return (
      <div className="dropdown">
        <button
          className="d-flex align-items-center btn btn-lg btn-light dropdown-toggle"
          type="button"
          id="dropdownMenuButton1"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          style={{ height: "45px" }}
        >
          <div className="user-avatar p-1 bg-secondary">
            <span className="fs-5 text-center text-light fw-bold text-uppercase">
              {this.state.username ? this.state.username.slice(0, 1) : "U"}
            </span>
          </div>
          <span className="text-wrap mx-2">
            {this.state.loadingUsername ? <Spinner /> : this.state.username}
          </span>
        </button>
        <ul className="dropdown-menu " aria-labelledby="dropdownMenuButton1">
          <li>
            <button
              className="dropdown-item"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              onClick={this.props.showCurrentUserPage}
            >
              Mon Compte
            </button>
          </li>
          <li>
            <button
              className="dropdown-item"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              onClick={this.props.unlogUser}
            >
              Se déconnecter
            </button>
          </li>
        </ul>
      </div>
    );
  }
}
