import { LayersControl, useMap } from "react-leaflet";
import L from "leaflet";
import React, { useEffect } from "react";

const Legend = ({ getColor, maxValue }) => {
  const map = useMap();

  useEffect(() => {
    // get color depending on population density value

    const legend = L.control({ position: "topleft" });

    legend.onAdd = () => {
      const div = L.DomUtil.create("div", "info legend");
      const grades = Array(10)
        .fill()
        .map((value, index) => Math.round((maxValue * index) / 10));
      let labels = [];
      let from;
      let to;

      for (let i = 0; i < grades.length; i++) {
        from = grades[i];
        to = grades[i + 1];

        labels.push(
          '<i style="background:' +
            getColor(i / 10) +
            '"></i> ' +
            from +
            (to ? "&ndash;" + to : "+"),
        );
      }

      div.innerHTML = labels.join("<br>");
      return div;
    };

    legend.addTo(map);
  }, []);
};

export default Legend;
