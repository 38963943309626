import Role from "./roleModel";

class User {
  constructor({ id, name, email, roles, phones }) {
    this.id = id;
    this.name = name;
    this.email = email;
    this.roles = roles;
    this.phones = phones;
  }

  static fromJson(jsonValue) {
    var roles = [];
    var phones = [];
    var name = jsonValue["username"];
    var email = jsonValue["email"];
    var id = jsonValue["pk"];
    if (jsonValue["groups"].length !== "" && jsonValue["groups"].length !== 0) {
      jsonValue["groups"].forEach((element) => {
        roles.push(Role.fromJson(element));
      });
    }
    if (
      jsonValue["telephones"] &&
      jsonValue["telephones"].length !== "" &&
      jsonValue["telephones"].length !== 0
    ) {
      jsonValue["telephones"].forEach((element) => {
        phones.push(element);
      });
    }
    return new User({ id: id, email: email, name: name, roles: roles, phones: phones });
  }
}

export default User;
