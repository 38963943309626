import Dashboard from "./components/dashboard/Dashboard";
import LoginPage from "components/login/LoginPage";
import UserList from "./components/dashboard/UserList";
import NothingToShow from "./components/dashboard/Nothingtoshow";
import CourbeByComposante from "./components/dashboard/CourbeByComposante";
import TabsDash from "./components/dashboard/TableauDeBord";
import DataTable from "./components/dashboard/DataTable";
import NumericLibrary from "./components/dashboard/NumericLibrary";
import TaskPage from "./components/dashboard/TaskPage";
import Mapping from "./components/dashboard/Mapping.js";
import ProfilePage from "./components/dashboard/ProfilePage";
import useToken from "./useToken";
import {
  unstable_HistoryRouter as HistoryRouter,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import AuthService from "services/AuthServices";
import { useEffect, useState } from "react";
import { history } from "../src/constants";

export default function Main() {
  const { token, setToken } = useToken(null);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [navigateTo, setNavigateTo] = useState(null);
  const [signedIn, setSignedIn] = useState(true);

  window.addEventListener("logout", () => {
    document.location.reload();
  });

  useEffect(() => {
    setToken(localStorage.getItem("access"));
  }, []);

  document.title = "LIRE - Niger";

  return (
    <div className="wrapper">
      <HistoryRouter history={history}>
        <Routes>
          {token ? (
            <Route
              path="/dashboard"
              element={
                <Dashboard
                  setToken={setToken}
                  setSignedUser={setUser}
                  setLoading={setLoading}
                />
              }
            >
              <Route path="" element={<TabsDash setToken={setToken} />} />
              <>
                <Route
                  path="courbe"
                  element={<CourbeByComposante setToken={setToken} />}
                />
                <Route
                  path="gantt"
                  element={<TaskPage setToken={setToken} projectId={1} />}
                />
              </>
              <Route path="userlist" element={<UserList setToken={setToken} />} />
              <Route
                path="numeric-library"
                element={<NumericLibrary setToken={setToken} />}
              />
              <Route path="mapping" element={<Mapping setToken={setToken} />} />
              <Route path="datatable" element={<DataTable setToken={setToken} />} />
              <Route
                path="profile-page"
                element={<ProfilePage setToken={setToken} />}
              />
            </Route>
          ) : (
            <Route path="/" element={<LoginPage setToken={setToken} />} />
          )}
          <Route
            path="*"
            element={
              token ? <Navigate to="/dashboard" /> : <LoginPage setToken={setToken} />
            }
          />
          {/* <Route path="*" element={<NothingToShow setToken={setToken} />} /> */}
        </Routes>
      </HistoryRouter>
    </div>
  );
}
