import "react-tabs/style/react-tabs.css";
import BarChart from "./BarChart";
import { INDICATOR_TYPES } from "../../constants.js";

export default function GraphSide(props) {
  const { currentComponent, indicatorsList, selectedRegion, indicator_type } = props;
  const width =
    "relative w-[250px] sm:w-[450px] mb-8 m-auto 2xl:mx-[10px] shadow-2xl drop-shadow-2xl rounded-2xl p-4";
  const userRegionId = localStorage.getItem("region-id");
  const BAR_CHART_CLASS = `sm:grid xl:grid-cols-${
    Boolean(userRegionId) ? 1 : 1
  } gap-4 w-fit m-auto rounded-md`;
  console.log(userRegionId, Boolean(userRegionId));

  return (
    <div>
      <div className="mb-8" key={indicator_type.code}>
        <div className="chart-group-title w-full">
          <h4 className="text-center text-base sm:text-xl margin-0">
            {indicator_type.label}
          </h4>
        </div>
        <div className={BAR_CHART_CLASS}>
          {indicatorsList &&
            indicatorsList.map((indicator, index) => {
              if (indicator.indicator_type === indicator_type.code) {
                return (
                  <div className={width} key={indicator.code}>
                    <div
                      className="px-2 py-1 text-base text-center"
                      style={{ background: "#E8F4E8" }}
                    >
                      {indicator.name}
                    </div>
                    <BarChart
                      graphName={indicator.name}
                      indicator={indicator}
                      color={"#ebab34"}
                      displayTarget={!(userRegionId || selectedRegion?.pk)}
                      selectedRegion={userRegionId || selectedRegion?.pk}
                    />
                  </div>
                );
              }
              return null;
            })}
        </div>
      </div>
    </div>
  );
}
