import Modal from "react-bootstrap/Modal";
import React, { useState } from "react";
import { Link } from "react-router-dom";

function DescriptionModal({ text }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Link to="#" className="more" onClick={handleShow}>
        LIRE
      </Link>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Description</Modal.Title>
        </Modal.Header>
        <Modal.Body>{text}</Modal.Body>
      </Modal>
    </>
  );
}

export default DescriptionModal;
