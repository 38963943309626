import axios from "axios";
import { Spinner } from "components/spinner";
import HelperFunction from "helperfunctions";
import React, { Component } from "react";
import { createTaskWithFile } from "services/TaskServices";
import Swal from "sweetalert2";
import { Modal } from "react-bootstrap";
import autoBind from "react-autobind";
import { EXCEL_EXTENSIONS } from "../../constants";

class AddTaskWithFile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFile: null,
      errorMessage: "",
      operationInProgress: false,
    };

    autoBind(this);
  }

  onFileChange = (event) => {
    this.setState({ selectedFile: event.target.files[0] });
  };

  onFileUpload = () => {
    if (this.state.selectedFile) {
      const formData = new FormData();
      this.setState({
        operationInProgress: true,
        errorMessage: "",
      });

      formData.append(
        "formatted_data",
        this.state.selectedFile,
        this.state.selectedFile.name,
      );

      setTimeout(() => {}, 1000);

      Swal.fire({
        title: "Etes-vous sûr(e)?",
        text: "Cette action supprimera toute information relative aux anciennes tâches. Elle est irréversible",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oui, charger!",
        cancelButtonText: "Annuler",
      }).then((result) => {
        if (result.isConfirmed) {
          this.setState({
            operationInProgress: true,
            errorMessage: "",
          });

          createTaskWithFile({ projectId: this.props.projectId, payload: formData })
            .then((response) => {
              this.setState({
                operationInProgress: false,
                errorMessage: "",
              });
              this.props.callBackSuccess();
            })
            .catch((error) => {
              // let errorData =  HelperFunction.handleErrors(error);
              this.setState({
                operationInProgress: false,
                errorMessage: "Une erreur s'est produite",
              });
            });
        }
      });
    }
  };

  fileData = () => {
    if (this.state.operationInProgress) {
      return (
        <div class="spinner-border text-light" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      );
    } else {
      return <span>Soumettre</span>;
    }
  };

  onHide() {
    this.setState({
      selectedFile: null,
      errorMessage: "",
      operationInProgress: false,
    });
    this.props.hide();
  }

  render() {
    return (
      <>
        <Modal show={this.props.show} onHide={this.onHide} onShow={this.onShow}>
          <Modal.Header closeButton>
            <Modal.Title>Charger des tâches</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row g-2 m-2">
              <div className="col-auto">
                <input
                  className="form-control"
                  type="file"
                  onChange={this.onFileChange}
                  accept={EXCEL_EXTENSIONS}
                />
              </div>
              <div className="col-auto">
                <button className="btn btn-primary mb-3" onClick={this.onFileUpload}>
                  {this.fileData()}
                </button>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div>
              <span className="text-danger"> {this.state.errorMessage}</span>
            </div>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default AddTaskWithFile;
