import TaskCardComponent from "./TaskCardComponent";

export default function TaskChildrenToDisplay(props) {
  // return <h5>jbdjhb</h5>
  if (props.processOnGoing) {
    return <Spinner />;
  } else {
    if (props.error !== "") {
      return <ErrorToShow />;
    } else {
      if (props.childrenTasks.length > 0) {
        return (
          <TaskChildrenList
            childrenTasks={props.childrenTasks}
            updateFunction={props.updateFunction}
            deleteFunction={props.deleteFunction}
          />
        );
      } else {
        return <NothingToShow />;
      }
    }
  }
}

function Spinner() {
  return (
    <div className="d-flex justify-content-center p-4">
      <div className="spinner-border text-info" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
}

function ErrorToShow(props) {
  return <div className="d-flex justify-content-center">{props.error}</div>;
}

function NothingToShow() {
  return <div className="text-align center">Aucune tâche enfant trouvée</div>;
}

function TaskChildrenList(props) {
  // return <h5>TASK LIST CHOLDREN</h5>;
  return props.childrenTasks.map((childTask, index) => {
    return (
      <TaskCardComponent
        key={index + childTask.id}
        task={childTask}
        updateFunction={props.updateFunction}
        deleteFunction={props.deleteFunction}
      />
    );
  });
}
