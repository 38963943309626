import React, { Component } from "react";
import "./DashboardStyle.css";
import AddUserForm from "../forms/formAddUser.js";
import { Modal } from "bootstrap/dist/js/bootstrap.esm.min.js";
import User from "../../models/userModel";
import Role from "../../models/roleModel";
import {
  getAllUsers,
  createUser,
  deleteUserApi,
  updateUser,
  getRoles,
} from "../../services/UserServices";
import { ContentToDisplay } from "./ContentToDisplay";
import { TextToDisplay } from "./TextToDisplay";

import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import HelperFunction from "../../helperfunctions";
import { apiCall } from "../../services/PrimaryRequest.js";
import { APIERRORCODELIST } from "../../constants";
import DownloadUpload from "./DownloadUpload";

class UserList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      messageToShow: "",
      messagetype: "",
      apiCallHasError: false,
      apiCallProcessing: true,
      taskList: [
        {
          start: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
          end: new Date(new Date().getFullYear(), new Date().getMonth(), 15),
          name: "Some Project",
          id: "ProjectSample",
          progress: 25,
          type: "project",
          hideChildren: false,
          displayOrder: 1,
        },
      ],
      allUsers: [],
      allRoles: [],
      userToUpdate: null,
      isModalShown: false,
      users: [],
      userGroup: localStorage.getItem("group"),
    };
    this.toastSuccess = null; //Variable contenant le modal du formulaire.
  }

  successfullFormCallback() {
    this.fetchUserList();
    this.hideModal();
    toast.success("Utilisateur enregistré ", {
      position: "bottom-left",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  componentDidMount() {
    this.fetchUserList();
    this.getUserRoles();
  }

  async getUserRoles() {
    getRoles().then((response) => {
      this.setState({
        allRoles: response.data.results.map((obj) => new Role(obj)),
      });
    });
  }

  async fetchUserList() {
    // var apiCallReponse = await apiCall(function() {
    //     return getAllUsers();
    // });

    getAllUsers()
      .then((response) => {
        var tempList = [];
        response.data.results.forEach((element) => {
          tempList.push(User.fromJson(element));
        });
        this.setState({
          allUsers: tempList,
          apiCallHasError: false,
          apiCallProcessing: false,
        });
      })
      .catch((error) => {
        this.setState({
          apiCallHasError: true,
          apiCallProcessing: false,
        });

        let errorMessage = HelperFunction.handleErrors(error).get("errormessage");

        toast.error(errorMessage, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
    // Todo: Handle error in getAllUsers
  }

  showModal() {
    // Ouvre le modal du formulaire
    this.setState({
      isModalShown: true,
    });
  }

  hideModal() {
    // Ferme le modal du formulaire
    this.setState({
      isModalShown: false,
      focusedTask: null,
    });
  }

  // showToast(){
  //     // Ouvre le toast du formulaire
  //     this.toastSuccess.show();
  // }

  hideToast() {
    // Ferme le toast du formulaire
    this.toastSuccess.hide();
  }

  showSideBar() {
    // Hide the sidebar
    var sidebar = document.getElementById("sidebar");
    var sidebarShower = document.getElementById("sidebarShower");

    if (sidebar.classList.contains("d-flex")) {
      sidebar.classList.remove("d-flex");
      sidebar.style.display = "none";
      sidebarShower.classList.remove("hidden-content");
    } else {
      sidebar.classList.add("d-flex");
      sidebar.style.display = "block";
      sidebarShower.classList.add("hidden-content");
    }
  }

  async deleteUser(userToDelete) {
    if (
      !HelperFunction.isDataNull(userToDelete) &&
      !HelperFunction.isDataNull(userToDelete.id)
    ) {
      var delUserResponse = await apiCall(function () {
        return deleteUserApi({ id: userToDelete.id });
      });

      return this.manageDeleteUserResponse(delUserResponse);
    } else {
      toast.error("Veuillez sélectionner un utilisateur à supprimer", {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  manageDeleteUserResponse(response) {
    if (response.get("responseType") === "success") {
      toast.success("Utilisateur supprimé", {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      this.fetchUserList();
      this.setState({
        userToDelete: "",
      });
    } else {
      toast.error(response.get("message"), {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  selectUser(userToUpdate = null) {
    this.setState({
      userToUpdate: userToUpdate,
    });
    setTimeout(this.showModal(), 500);
  }

  render() {
    return (
      <div className="content-displayer p-3 h-screen overflow-auto">
        <ToastContainer />

        <div>
          <p className="text-uppercase fs-3 fw-bold">
            <span className="mx-1">Liste des utilisateurs</span>
            <TextToDisplay
              apiCallHasError={this.state.apiCallHasError}
              userList={this.state.allUsers}
              apiCallProcessing={this.state.apiCallProcessing}
            />
          </p>
          <hr className="page-header-divider"></hr>
          <div className="absolute right-0 top-4">
            <DownloadUpload
              downloadUrl={"users/template/"}
              downloadFileName={"Parties prenantes.xlsx"}
              uploadUrl={"users/template/"}
              modalName={"Charger un fichier"}
              onSuccessfullUpload={this.onSuccessfullUpload}
            />
          </div>
        </div>

        <ContentToDisplay
          apiCallHasError={this.state.apiCallHasError}
          userList={this.state.allUsers}
          apiCallProcessing={this.state.apiCallProcessing}
          updateFunction={(user) => this.selectUser(user)}
          deleteFunction={(user) => this.deleteUser(user)}
        />

        <button
          className="btn btn-danger mx-1 container-floatting-down-left hidden-content"
          onClick={() => this.showSideBar()}
          id="sidebarShower"
        >
          <i className="bi bi-list container-icon-customization-1"></i>
        </button>

        {this.state.userGroup === "RSE" && (
          <button
            type="button"
            className="btn btn-secondary container-floatting-down-right"
            onClick={() => this.selectUser()}
          >
            Nouvel utilisateur
          </button>
        )}

        <div>
          {this.state.userGroup === "RSE" && (
            <AddUserForm
              show={this.state.isModalShown}
              hide={() => this.hideModal()}
              roles={this.state.allRoles}
              user={this.state.userToUpdate}
              callBackSuccess={() => this.successfullFormCallback()}
            />
          )}
        </div>
      </div>
    );
  }
}

export default UserList;
