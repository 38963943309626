import React, { useState, useEffect } from "react";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { MapContainer, GeoJSON } from "react-leaflet";
import country from "../shapes/nigerAdm01.json";
import autoBind from "react-autobind";
import repNiger_logo from "../../../assets/img/repNiger_logo.svg";
import lireProject_logo from "../../../assets/img/lireProject_logo.svg";
import worldBank_logo from "../../../assets/img/worlBank_logo.svg";
import Legend from "./Legend";
import { getDataByRegion } from "services/DashboardServices";

// To be imported from backend

const commonStyle = {
  color: "black",
  weight: 1,
};
const interventionStyle = {
  ...commonStyle,
  fillColor: "green",
  fillOpacity: 0.5,
};
const neutralStyle = {
  ...commonStyle,
  fillColor: "grey",
  fillOpacity: 0.2,
};

const getColor = (d) => {
  return d > 0.9
    ? "#00a650"
    : d >= 0.8
    ? "#3ab54b"
    : d >= 0.7
    ? "#8ec63f"
    : d >= 0.6
    ? "#cadb2a"
    : d >= 0.5
    ? "#fef200"
    : d >= 0.4
    ? "#ffc20d"
    : d >= 0.3
    ? "#f8931d"
    : d >= 0.2
    ? "#f36523"
    : d >= 0.1
    ? "#ed1b24"
    : "#ffffff";
};

const RegionSelector = (props) => {
  const [total, setTotal] = useState(0);
  const [layers, setLayers] = useState([]);
  const [mapData, setMapData] = useState();

  useEffect(() => {
    if (!mapData) return;
    setTotal(
      props.isPercentage ? 100 : Object.values(mapData).reduce((a, b) => a + b, 0),
    );
    autoBind(this);
  }, [mapData, props.isPercentage]);

  const setStyle = (layer) => {
    const regionValue = mapData[layer.feature.properties.NOMREG] || 0;
    layer.setStyle({
      ...interventionStyle,
      fillColor: getColor(total ? Math.floor((regionValue * 10) / total) / 10 : 0),
      fillOpacity: 1,
    });
  };

  const onEachFeature = (feature, layer) => {
    setStyle(layer);
    setLayers((prevLayers) => [...prevLayers, layer]);
    if (feature.properties.adm_01 !== "Niamey") {
      layer
        .bindTooltip(feature.properties.adm_01, {
          permanent: true,
          direction: "center",
          className: "map-labels",
        })
        .openTooltip();
    }
  };

  useEffect(() => {
    if (!mapData) return;
    layers.forEach((layer) => {
      setStyle(layer);
    });
  }, [mapData, total]);

  useEffect(() => {
    if (!props.indicator) {
      return;
    }
    getDataByRegion(props.indicator.pk).then((response) => {
      // setDataFromRegion(response.data);
      const dataRegionObject = {};
      response.data.forEach((value) => {
        dataRegionObject[value.region_name] = value.total_real;
      });
      setMapData(dataRegionObject);
    });
  }, [props.indicator]);

  const bounds = L.geoJSON(country).getBounds();

  return (
    <div>
      {/* <div
        className="partners-icon"
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(3, 1fr)",
          alignItems: "center",
          width: "100%",
          gap: "10px",
        }}
      >
        <img src={repNiger_logo} alt="Rep. Niger" style={{ width: "100%", height: "50px" }} />
        <img src={lireProject_logo} alt="LIRE" style={{ width: "100%", height: "40px" }} />
        <img src={worldBank_logo} alt="BM" style={{ width: "100%", height: "75px" }} />
      </div> */}
      {props.indicatorName ? (
        <div
          className="map-title text-center p-2 text-base max-w-[700px]"
          style={{ background: "#E8F4E8" }}
        >
          {props.indicatorName}
        </div>
      ) : null}
      <MapContainer
        bounds={bounds}
        zoomControl={false}
        dragging={false}
        zoom={20}
        doubleClickZoom={false}
        scrollWheelZoom={false}
        className="region-selector-leaflet"
      >
        {mapData && <GeoJSON data={country} onEachFeature={onEachFeature} />}
        {total != 0 && <Legend getColor={getColor} maxValue={total} />}
      </MapContainer>
    </div>
  );
};

export default RegionSelector;
