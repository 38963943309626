import React, { useEffect, useState } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import Modal from "react-bootstrap/Modal";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ChooseFileIcon from "components/card/ChooseFileIcon";
import {
  addDocument,
  downloadDocument,
  updateDocument,
} from "../../services/LibraryServices";
import { ThemeProvider } from "@mui/material/";
import { theme, CustomizedSelect } from "../dashboard/Customization";

const FormAddDocument = ({ documentToUpdate, reloadCardsList }) => {
  const [show, setShow] = useState(false);
  const [name, setName] = useState();
  const [author, setAuthor] = useState();
  const [description, setDescription] = useState();
  const [category, setCategory] = useState();
  const [docFile, setDocFile] = useState();
  const [processing, setProcessing] = useState(false);

  const handleShow = () => {
    setShow(true);
    if (documentToUpdate) {
      downloadDocument(documentToUpdate.pk)
        .then((response) => {
          let fileFromBlob = new File([response.data], documentToUpdate.filename, {
            type: response.data.type,
          });
          showDocumentIcon([fileFromBlob]);
        })
        .catch((error) => console.log(error));
    }
  };
  const handleClose = () => setShow(false);
  const handleChangeAuthor = (e) => setAuthor(e.target.value);
  const handleChangeDescription = (e) => setDescription(e.target.value);
  const handleChangeCategory = (e) => setCategory(e.target.value);
  const handleChangeName = (e) => setName(e.target.value);

  const handleChangeDocFile = (e) => {
    showDocumentIcon(e.target.files);
  };

  const clearInputFields = () => {
    setAuthor("");
    setName("");
    setDescription("");
    setCategory("");
  };

  const showDocumentIcon = (documentFile) => {
    document.querySelector(".chosenFileBox").innerHTML = "";
    let img = document.createElement("img");
    img.src = ChooseFileIcon(documentFile[0].name);
    let title = document.createElement("p");
    title.textContent = documentFile[0].name;
    document.querySelector(".chosenFileBox").appendChild(img);
    document.querySelector(".chosenFileBox").appendChild(title);
    setDocFile(documentFile[0]);
  };

  const handleSaveDocument = () => {
    let currentDate = new Date();
    let docData = new FormData();
    docData.append("name", name);
    docData.append("author", author);
    docData.append("description", description);
    docData.append("category", category);
    docData.append("book", docFile);
    docData.append(
      "publication_date",
      `${currentDate.getFullYear()}-${
        currentDate.getMonth() + 1
      }-${currentDate.getDate()}`,
    );
    if (documentToUpdate) {
      setProcessing(true);
      updateDocument(documentToUpdate.pk, docData)
        .then((response) => {
          clearInputFields();
          handleClose();
          setProcessing(false);
          reloadCardsList();
        })
        .catch((error) => {
          clearInputFields();
          handleClose();
          setProcessing(false);
        });
    } else {
      setProcessing(true);
      addDocument(docData)
        .then((response) => {
          clearInputFields();
          handleClose();
          setProcessing(false);
          reloadCardsList();
        })
        .catch((error) => {
          clearInputFields();
          handleClose();
          setProcessing(false);
        });
    }
  };

  const Spinner = () => {
    return (
      <div className="d-flex justify-content-center">
        <div
          className="spinner-border text-light"
          role="status"
          style={{
            padding: "0 !important",
            height: "24px",
            width: "24px",
          }}
        >
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  };

  const showButtonLabel = () => {
    if (documentToUpdate) {
      return processing ? <Spinner /> : "Mettre à jour le document";
    } else {
      return processing ? <Spinner /> : "Enrégistrer le document";
    }
  };

  useEffect(() => {
    if (documentToUpdate) {
      setAuthor(documentToUpdate.author);
      setName(documentToUpdate.name);
      setDescription(documentToUpdate.description);
      setCategory(documentToUpdate.category);
    } else {
      clearInputFields();
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      {!documentToUpdate ? (
        <Button
          variant="contained"
          className="fw-bold bg-secondary"
          onClick={handleShow}
          style={{
            position: "absolute",
            bottom: "50px",
            right: "50px",
            zIndex: 100,
          }}
        >
          Ajouter un document
        </Button>
      ) : (
        <Button className="edit-button fw-bold uppercase" onClick={handleShow}>
          Editer le document
        </Button>
      )}

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Ajout de document</Modal.Title>
        </Modal.Header>
        <Modal.Body className="row gap-3" style={{ padding: "25px" }}>
          <Box className="shadow col uploadedFileBox" style={{}}>
            <input
              accept=".pdf,.doc,.docx,.xml,application/msword,
                application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              type="file"
              id="chosenFile"
              hidden
              onChange={(e) => handleChangeDocFile(e)}
            />
            <label
              htmlFor="chosenFile"
              className="text-sm chosenFileBox"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                width: "100%",
                cursor: "pointer",
              }}
            >
              <CloudUploadIcon color="primary" sx={{ fontSize: "50px" }} />
              <Typography variant="body-2" color="primary">
                Sélectionner un document
              </Typography>
            </label>
          </Box>
          <Box className="col d-grid gap-3">
            <TextField
              value={author}
              label="Auteur"
              color="primary"
              focused
              onChange={(e) => handleChangeAuthor(e)}
            />
            <FormControl>
              <InputLabel id="description-select-label">Catégorie</InputLabel>
              <CustomizedSelect
                focused
                value={category}
                labelId="description-select-label"
                label="Catégorie"
                onChange={(e) => handleChangeCategory(e)}
              >
                <MenuItem value="strategie">Stratégie</MenuItem>
                <MenuItem value="rapport">Rapport</MenuItem>
              </CustomizedSelect>
            </FormControl>
            <TextField
              maxLength="5"
              value={name}
              label="Nom du document"
              color="primary"
              focused
              onChange={(e) => handleChangeName(e)}
            />

            <TextField
              value={description}
              multiline
              rows={5}
              label="Description"
              color="primary"
              focused
              onChange={(e) => handleChangeDescription(e)}
            />
          </Box>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSaveDocument}
            className="fw-bold"
          >
            {documentToUpdate ? showButtonLabel() : showButtonLabel()}
          </Button>
        </Modal.Body>
      </Modal>
    </ThemeProvider>
  );
};

export default FormAddDocument;
