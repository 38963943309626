import L from "leaflet";

// const iconSchool = new L.Icon({
//     iconUrl: require('../../../assets/icons/ecole.png'),
//     iconRetinaUrl: require('../../../assets/icons/ecole.png'),
//     iconAnchor: null,
//     shadowUrl: null,
//     shadowSize: null,
//     shadowAnchor: null,
//     iconSize: new L.Point(30, 30),
//     className: 'leaflet-div-icon'
// });

const iconSchool = new L.divIcon({
  className: "custom-div-icon",
  html: "<div style='background-color:#c30b82;' class='marker-pin'></div><i class='material-icons'><i class='fa-solid fa-school'></i></i>",
  iconSize: [30, 42],
  iconAnchor: [15, 42],
});

const iconFirm = new L.divIcon({
  className: "custom-div-icon",
  html: "<div style='background-color:blue;' class='marker-pin'></div><i class='material-icons'><i class='fa-solid fa-building'></i></i></i>",
  iconSize: [30, 42],
  iconAnchor: [15, 42],
});

export { iconSchool, iconFirm };
