import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Skeleton from "@mui/material/Skeleton";
import { Box } from "@mui/material";

const ProfilePageSkeleton = () => {
  return (
    <Card sx={{ width: "47%" }}>
      <Skeleton sx={{ height: "20%" }} animation="wave" variant="rectangular" />

      <CardContent>
        <React.Fragment>
          <Box className="picture-fullname d-grid justify-content-center gap-2">
            <Skeleton variant="circular" animation="wave" height={100} width={100} />
            <Skeleton animation="wave" height={15} width="100%" />
          </Box>
          <Box className="infos px-3 mt-4 d-grid gap-3">
            {[0, 1, 2].map((item) => {
              return (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box px={1}>
                    <Skeleton
                      variant="circular"
                      animation="wave"
                      height={50}
                      width={50}
                    />
                  </Box>
                  <Box sx={{ width: "100%", display: "grid", gap: "5px" }}>
                    <Skeleton animation="wave" height={20} width="40%" />
                    <Skeleton animation="wave" height={15} width="30%" />
                  </Box>
                </Box>
              );
            })}
          </Box>
        </React.Fragment>
        <Box p={2} sx={{ display: "flex", justifyContent: "center" }}>
          {" "}
          <Skeleton animation="wave" height={40} width="70%" />{" "}
        </Box>
      </CardContent>
    </Card>
  );
};

export default ProfilePageSkeleton;
