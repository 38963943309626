import { requestHeader } from "./helperServices";
import axios from "./axios.js";

export async function getAllUsers() {
  // Récupère toutes les utilisateurs
  return axios.get("/users/", { headers: requestHeader() });
}

export async function createUser(payload) {
  // Crée un utilisateur
  return axios.post("/users/", payload, { headers: requestHeader() });
}

export async function updateUser({ id, payload }) {
  // Crée un utilisateur
  return axios.put("/users/" + id + "/", payload, { headers: requestHeader() });
}

export async function deleteUserApi({ id }) {
  // Supprime un utilisateur
  return axios.delete("/users/" + id, { headers: requestHeader() });
}

export async function getRoles() {
  const response = await axios.get("/roles/", { headers: requestHeader() });
  var dataToReturn = { data: response.data };

  return dataToReturn;
}
