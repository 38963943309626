export function TextToDisplay(props) {
  if (props.apiCallProcessing) {
    return <NothingToShowText />;
  } else {
    if (props.apiCallHasError) {
      return <NothingToShowText />;
    } else {
      return <TextToShow text={props.userList.length} />;
    }
  }
}

export function NothingToShowText() {
  return <span className="d-flex justify-content-center"></span>;
}

export function TextToShow(props) {
  return (
    <span className="text-uppercase fs-3 fw-bold badge bg-info">{props.text}</span>
  );
}
