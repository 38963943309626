class Role {
  constructor({ pk, name }) {
    this.id = pk;
    this.name = name;
  }

  static nulleRole() {
    return new Role({ id: "", name: "" });
  }

  static fromJson(jsonValue) {
    var id = "";
    var name = jsonValue;

    return new Role({ id: id, name: name });
  }
}

export default Role;
