class Indicator {
  constructor({ id, name, description, measureUnit, code }) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.measureUnit = measureUnit;
    this.code = code;
  }

  static fromJson(jsonValue) {
    var name = jsonValue["name"];
    var description = jsonValue["description"];
    var id = jsonValue["pk"];
    var measureUnit = jsonValue["measure_unit"];

    return new Indicator({
      id: id,
      name: name,
      description: description,
      measureUnit: measureUnit,
      code: jsonValue["code"],
    });
  }
}

export default Indicator;
