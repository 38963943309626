export default class Region {
  constructor({ pk, nom }) {
    this.id = pk;
    this.name = nom;
  }

  static fromJsonList(list) {
    console.log(list);
    return list.map((element) => new Region(element));
  }
}
