import React from "react";
import "gantt-task-react/dist/index.css";
import { ColumnVisibility, ViewMode } from "gantt-task-react";
import Dropdown from "react-bootstrap/Dropdown";
import { Select as MultiSelect } from "antd";

const { Option } = MultiSelect;

type ViewSwitcherProps = {
  isChecked: boolean;
  onViewListChange: (isChecked: boolean) => void;
  onViewModeChange: (viewMode: ViewMode) => void;
  changeColumnVisibility: (columnName: string) => void;
  columnList: ColumnVisibility[];
  selectedViewMode: ViewMode;
};

type viewModeListType = {
  label: string;
  viewMode: ViewMode;
};
const viewModeList: viewModeListType[] = [
  { label: "Mois", viewMode: ViewMode.Month },
  { label: "Trimestre", viewMode: ViewMode.Trimester },
  { label: "Semestre", viewMode: ViewMode.Semester },
  { label: "Année", viewMode: ViewMode.Year },
];
const selectedViewModeClassName: string = "flex-1 text-capitalize btn btn-primary m-1";
const unSelectedViewModeClassName: string =
  "flex-1 text-capitalize btn btn-secondary m-1";

export const ViewSwitcher: React.FC<ViewSwitcherProps> = ({
  onViewModeChange,
  onViewListChange,
  isChecked,
  changeColumnVisibility,
  columnList,
  selectedViewMode,
}) => {
  let [currentColumnList, setColumnList] = React.useState(
    columnList.filter((col) => col.isVisible).map((col) => col.columnName),
  );

  const columnsMap = Object();
  columnList.forEach((column, index) => {
    columnsMap[column.columnName] = index;
  });

  const handleColListChange = (colList: any) => {
    changeColumnVisibility(colList);
    const list = colList.sort((a: string, b: string) => columnsMap[a] - columnsMap[b]);
    setColumnList(list);
  };

  return (
    <div className="ViewContainer space-y-3">
      <div className="flex items-center flex-wrap">
        {viewModeList.map((value, index) => {
          return (
            <button
              key={index}
              className={
                value.viewMode === selectedViewMode
                  ? selectedViewModeClassName
                  : unSelectedViewModeClassName
              }
              onClick={() => onViewModeChange(value.viewMode)}
            >
              {value.label}
            </button>
          );
        })}
      </div>

      <MultiSelect
        allowClear
        mode={"multiple"}
        placeholder="Sélectionez colonnes"
        onChange={handleColListChange}
        className="!w-full select-input-2 mb-2 !ml-0"
        value={currentColumnList}
      >
        {columnList.map((column, index) => (
          <Option key={index} value={column.columnName} name={column.columnName}>
            {column.columnName}
          </Option>
        ))}
      </MultiSelect>
    </div>
  );
};
