import React, { useState, useEffect, useRef } from "react";
import { Task, ViewMode, Gantt, ColumnVisibility } from "gantt-task-react";
import GanttPageHeader from "./GanttPageHeader";
import { getStartEndDateForProject, initTasks } from "./helper";
import "gantt-task-react/dist/index.css";
import HelperFunction from "../../../helperfunctions";
import { getTasks } from "services/TaskServices";
import { Spinner } from "../ContentToDisplay";
import { useParams } from "react-router-dom";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

type DataType = {
  kids: Array<Task>;
  expanded: boolean;
};

export default function GantTable(props: any) {
  let [tasks, setTasks] = React.useState<Task[]>(props.tasks);
  let [dateLimits, setDateLimits] = React.useState<Date[] | undefined>(undefined);
  const [view, setView] = React.useState<ViewMode>(ViewMode.Year);
  const defaultColWidth = 55;
  const [columnWidth, setColumnWidth] = React.useState(defaultColWidth);
  const prevView = useRef<ViewMode>();
  const [isChecked, setIsChecked] = React.useState(true);
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const [yearsParams, setYearsParams] = useState({ start: null, end: null });
  const [children, setChildren] = useState<Map<any, DataType>>(new Map());

  useEffect(() => {
    if (props.forceUpdate) {
      setTasks(props.tasks);
      setChildren(new Map());
    }

    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [props]);

  useEffect(() => {
    if (view === ViewMode.Year) {
      let minDate: Date = new Date(2020, 0, 1);
      let maxDate: Date = new Date(2030, 11, 30);
      setDateLimits([minDate, maxDate]);
    } else {
      let currentYear = new Date().getFullYear();

      let minDate: Date = new Date(currentYear - 1, 11, 1);
      let maxDate: Date = new Date(currentYear, 11, 30);
      setDateLimits([minDate, maxDate]);
    }
  }, [tasks, view]);

  useEffect(() => {
    if ([ViewMode.Semester, ViewMode.Trimester].includes(view)) {
      setColumnWidth(150);
    } else {
      setColumnWidth(defaultColWidth);
    }
  }, [view]);

  const handleTaskChange = (task: Task) => {
    let newTasks = tasks.map((t) => (t.id === task.id ? task : t));
    if (task.project) {
      const [start, end] = getStartEndDateForProject(newTasks, task.project);
      const project = newTasks[newTasks.findIndex((t) => t.id === task.project)];
      if (
        project.start.getTime() !== start.getTime() ||
        project.end.getTime() !== end.getTime()
      ) {
        const changedProject = { ...project, start, end };
        newTasks = newTasks.map((t) => (t.id === task.project ? changedProject : t));
      }
    }
    setTasks(newTasks);
  };

  const handleTaskDelete = (task: Task) => {
    const conf = window.confirm("Are you sure about " + task.name + " ?");
    if (conf) {
      // setTasks(tasks.filter(t => t.id !== task.id));
      props.deleteFunction(task.id);
    }
    return conf;
  };

  const handleProgressChange = async (task: Task) => {
    setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
  };

  const handleDblClick = (task: Task) => {
    props.updateFunction(task);
  };

  const handleSelect = (task: Task, isSelected: boolean) => {
    // props.updateFunction(task);
  };

  const removeTaskChildren = (task: Task) => {
    let kidsTasks = children.get(task.id);
    if (!kidsTasks) {
      return;
    }

    let kidsIds = kidsTasks.kids.map((t: Task) => {
      removeTaskChildren(t);
      return t.id;
    });

    let newList: Task[] = [];

    tasks.forEach((t: Task) => {
      if (!kidsIds.includes(t.id)) {
        if (task.id === t.id) {
          newList.push(task);
        } else {
          newList.push(t);
        }
      }
    });
    tasks = newList;
    setTasks(newList);
    let taskData = children.get(task.id);
    if (taskData && taskData.expanded) {
      taskData.expanded = false;
    }
  };

  const insertTasks = (
    parentTask: Task,
    taskList: Task[],
    index: number,
    startsAfter = null,
    endsBefore = null,
  ) => {
    let newTaskList = tasks.map((t) => (t.id === parentTask.id ? parentTask : t));
    let kidsTasks: Task[] = [];
    taskList.forEach((element: any) => {
      let newTask = element;
      if (element.code) {
        // element is still the bare data sent from API
        // We must create a Task object from these data
        if (startsAfter && endsBefore) {
          newTask = HelperFunction.taskfromJson(element, startsAfter, endsBefore);
        } else {
          newTask = HelperFunction.taskfromJson(element);
        }
      }
      kidsTasks.push(newTask);
    });
    newTaskList.splice(index, 0, ...kidsTasks);
    // setTasks(props.tasks);
    setTasks(newTaskList);
    return kidsTasks;
  };

  const getChildTasks = (task: Task, index: number) => {
    let startsFilter = yearsParams.start;
    let endsFilter = yearsParams.end;
    let kidsTasks = children.get(task.id);
    if (kidsTasks) {
      insertTasks(task, kidsTasks.kids, index, startsFilter, endsFilter);
      kidsTasks.expanded = true;
      return true;
    }

    let params = new URLSearchParams();
    if (startsFilter && endsFilter) {
      params.append("starts_after", startsFilter);
      params.append("ends_before", endsFilter);
    }
    if (view === ViewMode.Month) {
      const currentYear = new Date().getFullYear();
      params.append("year", String(currentYear));
    }
    params.append("parent", task.id);
    const year =
      localStorage.getItem("viewMode") !== ViewMode.Year
        ? new Date().getFullYear()
        : null;
    if (year) {
      params.append("year", year.toString());
    }
    getTasks({ projectId: 1, params: params }).then((response) => {
      let taskChildren = response.data.results;
      const newTasks = insertTasks(task, taskChildren, index, startsFilter, endsFilter);
      children.set(task.id, { expanded: true, kids: newTasks });
      setChildren(children);
    });
  };

  const handleExpanderClick = (task: Task) => {
    let index = tasks.findIndex((elmt: any) => {
      return elmt.id === task.id;
    });
    let taskData = children.get(task.id);

    if (taskData && taskData.expanded) {
      // setTasks(tasks.map(t => (t.id === task.id ? task : t)));
      removeTaskChildren(task);
      return true;
    }

    index += 1;
    getChildTasks(task, index);
  };

  // const shouldComponentUpdate = () => {
  // }

  const defaultColumnList = [
    {
      columnName: "Code",
      isVisible: true,
      columnWithArrow: true,
      toShow: (task: Task) => {
        return task.id;
      },
    },
    {
      columnName: "Nom",
      isVisible: true,
      toShow: (task: Task) => {
        return task.name;
      },
    },
    {
      columnName: "Début",
      isVisible: false,
      isDate: true,
      toShow: (task: Task) => {
        return task.startToDisplay;
      },
    },
    {
      columnName: "Fin",
      isVisible: false,
      isDate: true,
      toShow: (task: Task) => {
        return task.endToDisplay;
      },
    },
    {
      columnName: "Assigné à",
      isVisible: true,
      toShow: (task: Task) => {
        return task.assignedUser;
      },
    },
    {
      columnName: "Région bénéficiaire",
      isVisible: true,
      toShow: (task: Task) => {
        return task.beneficiaire;
      },
    },
    {
      columnName: "Budget",
      isVisible: true,
      toShow: (task: Task) => {
        if (task.estimatedAmount) return `${task.estimatedAmount / 1000} M`;
        return "";
      },
    },
  ];

  const [columnList, setColumnList] = useState(defaultColumnList);

  const updateColumnVisibility = (newColumnsList: any) => {
    let columnListClone = columnList.slice();

    for (let columnIndex = 0; columnIndex < columnListClone.length; columnIndex++) {
      let col = columnListClone[columnIndex];
      columnListClone[columnIndex].isVisible = Boolean(
        newColumnsList.indexOf(col.columnName) + 1,
      );
    }
    setColumnList(columnListClone);
  };

  const handleFilterSubmit = (params: any) => {
    setYearsParams({
      start: params.get("starts_after"),
      end: params.get("ends_before"),
    });
    props.submitSearch(params);
  };

  const onViewModeChange = (viewMode: ViewMode) => {
    let current_year = new Date().getFullYear();
    let newView = viewMode;
    let currentView = view;
    if (newView === ViewMode.Year && currentView !== ViewMode.Year) {
      handleFilterSubmit(new URLSearchParams());
    } else if (newView !== ViewMode.Year && currentView === ViewMode.Year) {
      let params = new URLSearchParams();
      params.append("year", current_year.toString());
      handleFilterSubmit(params);
    }
    setView(viewMode);
  };

  return (
    <div className="h-screen overflow-auto mb-40 pb-40">
      <GanttPageHeader
        onViewModeChange={onViewModeChange}
        onViewListChange={setIsChecked}
        isChecked={isChecked}
        changeColumnVisibility={updateColumnVisibility}
        columnList={columnList}
        selectedViewMode={view}
        submitSearch={handleFilterSubmit}
        projectId={props.projectId}
        filterWithCurrentYear={view !== ViewMode.Year}
      />
      {props.apiCallProcessing && <Spinner />}

      {!props.apiCallProcessing && tasks.length > 0 && (
        <Gantt
          locale="fr"
          tasks={tasks}
          viewMode={view}
          onDateChange={handleTaskChange}
          onDelete={handleTaskDelete}
          onProgressChange={handleProgressChange}
          // onDoubleClick={handleDblClick}
          onSelect={handleSelect}
          onExpanderClick={handleExpanderClick}
          listCellWidth={isChecked ? "155px" : ""}
          ganttHeight={windowDimensions.height - 300}
          columnWidth={columnWidth}
          columnList={columnList}
          showTaskName={false}
          preStepsCount={1}
          showOnlyFirstLetters={true}
          fontSize={"11"}
          dateInterval={dateLimits}
        />
      )}
      {!props.apiCallProcessing && !props.tasks.length && <NoTask />}
    </div>
  );
}

export function NoTask() {
  return <div className="d-flex justify-content-center">Aucune tâche trouvée</div>;
}
