import { useState, useEffect } from "react";
import "./DashboardStyle.css";
import Courbe from "./Courbe.js";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { getTasks } from "services/TaskServices";
import { Spinner } from "components/spinner";
import HelperFunction from "helperfunctions";
import DownloadUpload from "./DownloadUpload";

const CourbeByComposante = function (props) {
  const [composantes, setComposantes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isUploadModalShown, setIsUploadModalShown] = useState(false);

  useEffect(() => {
    let params = new URLSearchParams("category=Composante");
    setLoading(true);
    getTasks({ params: params }).then((response) => {
      setComposantes(response.data.results);
      setLoading(false);
    });
  }, []);

  const showUploadModal = function () {
    setIsUploadModalShown(true);
  };

  const hideUploadModal = function () {
    setIsUploadModalShown(false);
  };

  const successfullUploadCallback = function () {
    let params = new URLSearchParams("category=Composante");
    setLoading(true);
    getTasks({ params: params }).then((response) => {
      setComposantes(response.data.results);
      setLoading(false);
    });
    hideUploadModal();
  };

  const download = function () {
    HelperFunction.downloadFile("/finances/template/", "Dépenses.xlsx");
  };

  const onSuccessfullUpload = function () {
    // forceUpdate();
  };

  return (
    <>
      {!loading && (
        <Tabs className="h-screen overflow-auto p-3">
          <TabList>
            <div className="d-flex flex-row">
              <div className="flex-grow-1">
                {composantes.map((component) => {
                  return <Tab key={component.code}>Composante {component.code}</Tab>;
                })}
              </div>
              <DownloadUpload
                downloadUrl={"finances/template/"}
                downloadFileName={"Dépenses.xlsx"}
                uploadUrl={`finances/template/`}
                modalName={"Charger un fichier"}
                onSuccessfullUpload={onSuccessfullUpload}
              />
            </div>
          </TabList>
          {composantes.map((component) => {
            return (
              <TabPanel key={component.code}>
                {/* <h2 style={{margin: "auto", width: "50%", textAlign: "center"}}>Indicateurs de réalisation</h2> */}
                <Courbe composante={component} />
              </TabPanel>
            );
          })}
        </Tabs>
      )}
      {loading && <Spinner />}

      {/* <div className="btn-group container-floatting-down-right"> */}

      {/* </div> */}
    </>
  );
};

export default CourbeByComposante;
