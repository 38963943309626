import { useState } from "react";
import "react-tabs/style/react-tabs.css";
import { INDICATOR_TYPES } from "constants";
import RegionSelector from "./map/RegionSelector";

export default function MapSide(props) {
  const { currentComponent, indicatorsList, selectedRegion } = props;
  const width =
    "relative w-full mb-8 m-auto 2xl:mx-[10px] shadow-2xl drop-shadow-2xl rounded-2xl p-4";
  const BAR_CHART_CLASS = "sm:grid xl:grid-cols-1 gap-4 w-full m-auto rounded-md";
  const [mapData, setMapData] = useState();

  return (
    <div className="">
      {INDICATOR_TYPES.map((indicator_type) => {
        return (
          <div key={indicator_type.code} className="mb-8">
            <div className="chart-group-title w-full">
              <h4 className="text-center text-base sm:text-xl margin-0">
                {indicator_type.label}
              </h4>
            </div>
            <div className={BAR_CHART_CLASS}>
              {indicatorsList &&
                indicatorsList.map((indicator, index) => {
                  if (indicator.is_national) {
                    return;
                  }
                  if (indicator.indicator_type === indicator_type.code) {
                    return (
                      <div className={width} key={indicator.code}>
                        <RegionSelector
                          data={mapData}
                          indicatorName={indicator ? indicator.name : ""}
                          isPercentage={indicator ? indicator.is_percentage : false}
                          indicator={indicator}
                        />
                      </div>
                    );
                  }
                })}
            </div>
          </div>
        );
      })}
    </div>
  );
}
