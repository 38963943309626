import { requestHeader } from "./helperServices";
import axios from "./axios.js";

export async function getDocuments(params) {
  return axios.get("/library/", {
    headers: requestHeader(),
    params,
  });
}

export async function downloadDocument(docId) {
  return axios.get(`/library/${docId}/download/`, {
    headers: requestHeader(),
    responseType: "blob",
  });
}

export async function addDocument(docData) {
  return axios.post("/library/", docData, {
    headers: requestHeader(),
  });
}

export async function updateDocument(docId, docData) {
  return axios.put(`/library/${docId}/`, docData, {
    headers: requestHeader(),
  });
}
