import HelperFunction from "../helperfunctions";
import { requestHeader } from "./helperServices";
import axios from "./axios.js";

export default class AuthService {
  static async login(username, password) {
    // var dataToReturn = new Map();
    var payload = {
      username: username,
      password: password,
    };
    return axios.post("/token/", payload);
  }

  static logout() {
    //Délogue l'utilisateur connecté
    // localStorage.removeItem("access");
    // localStorage.removeItem("refresh");
    // localStorage.removeItem("me-username");
    localStorage.clear();
  }

  static getCurrentUser() {
    //Récupère l'utilisateur connecté
    if (!HelperFunction.isDataNull(localStorage.getItem("rse-access"))) {
      try {
        return JSON.parse(localStorage.getItem("rse-access"));
      } catch (error) {
        return "";
      }
    } else {
      return "";
    }
  }

  static getCurrentUsername() {
    var dataToReturn;
    if (localStorage.getItem("me-username") !== null) {
      try {
        dataToReturn = localStorage.getItem("me-username");
      } catch (error) {
        dataToReturn = null;
      }
    }
    return dataToReturn;
  }

  static async getMe() {
    return axios.get("/users/me", { headers: requestHeader() });
  }

  static saveToken(data) {
    localStorage.setItem("access", data.get("access"));
    localStorage.setItem("refresh", data.get("refresh"));
  }

  static saveGroup(group) {
    localStorage.setItem("group", group);
  }

  static saveUserName(username) {
    localStorage.setItem("me-username", username);
  }

  static saveRegionId(userData) {
    if (userData.regions_ids?.length) {
      localStorage.setItem("region-id", userData.regions_ids[0]);
    }
  }
}
