import React, { Component, useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
} from "chart.js";
import { Line, Bar } from "react-chartjs-2";
import Select from "react-select";
import { Select as MultiSelect } from "antd";
import "./DashboardStyle.css";
import {
  getFinancesByYear,
  getFinancesByTrimester,
} from "../../services/FinanceServices";
import { getTasks } from "services/TaskServices";
import { Spinner } from "../spinner";
import { useLocalStorageState } from "components/utils";

const { Option } = MultiSelect;

const YEARS = [2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029];
const ABSISCES_TYPES = {
  MULTI_ANNUEL: {
    value: "MULTI_ANNUEL",
    labels: YEARS,
  },
  ANNUEL: {
    value: "ANNUEL",
    labels: ["T1", "T2", "T3", "T4"],
  },
};

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

const Courbe = (props) => {
  const [mainTask, setMainTask] = useState(props.composante.code);
  const [data, setData] = useState({
    labels: [],
    datasets: [],
    abscisseType: ABSISCES_TYPES.MULTI_ANNUEL.value,
  });
  const [intervals, setIntervals] = useState([]);
  const [subComponents, setSubComponents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [region, setRegion] = useLocalStorageState("courbes-region");
  const [selectedYear, setSelectedYear] = useLocalStorageState("selectedYear");

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Dépenses",
      },
    },
  };
  const abscissesTypesRef = React.createRef();
  const yearSelectRef = React.createRef();

  useEffect(() => {
    setLoading(true);
    getTasks({ projectId: 1, params: { parent: props.composante.code } }).then(
      (response) => {
        if (response.data) {
          setSubComponents(response.data.results);
          setLoading(false);
        }
      },
    );
  }, []);

  const showSideBar = () => {
    // Hide the sidebar
    var sidebar = document.getElementById("sidebar");
    var sidebarShower = document.getElementById("sidebarShower");

    if (sidebar.classList.contains("d-flex")) {
      sidebar.classList.remove("d-flex");
      sidebar.style.display = "none";
      sidebarShower.classList.remove("hidden-content");
    } else {
      sidebar.classList.add("d-flex");
      sidebar.style.display = "block";
      sidebarShower.classList.add("hidden-content");
    }
  };

  const computeIntervals = (datasets) => {
    let realized = datasets[0]["data"];
    let planned = datasets[1]["data"];
    let atTop = realized[0] > planned[0] ? "realised" : "planned";
    atTop = realized[0] === planned[0] ? "none" : atTop;
    let total = Math.abs(realized[0] - planned[0]);
    let current_interval = { atTop, duration: 1, from: 0, to: 0, total };
    let intervals = [];
    for (let index = 1; index < realized.length; index++) {
      const realValue = realized[index];
      const planValue = planned[index];
      let diff = Math.abs(realValue - planValue);
      if (realValue > planValue) {
        if (atTop === "realised") {
          current_interval.to = index;
          current_interval.duration += 1;
          current_interval.total += diff;
        } else {
          intervals.push(current_interval);
          atTop = "realised";
          current_interval = { atTop, duration: 1, from: index, total: diff };
        }
      } else if (realValue < planValue) {
        if (atTop === "planned") {
          current_interval.to = index;
          current_interval.duration += 1;
          current_interval.total += diff;
        } else {
          intervals.push(current_interval);
          atTop = "planned";
          current_interval = { atTop, duration: 1, from: index, total: diff };
        }
      } else {
        if (atTop === "none") {
          current_interval.to = index;
          current_interval.duration += 1;
          current_interval.total += diff;
        } else {
          intervals.push(current_interval);
          atTop = "none";
          current_interval = { atTop, duration: 1, from: index, total: diff };
        }
      }
    }
    intervals.push(current_interval);
    return intervals;
  };

  const initDataset = (labels) => {
    return [
      {
        label: "Dépenses réelles",
        borderColor: "rgb(255, 99, 132)",
        data: Array(labels.length).fill(0),
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "Prévisions",
        borderColor: "rgb(53, 162, 235)",
        data: Array(labels.length).fill(0),
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ];
  };

  const renderMultiAnnuelChart = () => {
    if (region !== null && selectedYear.length > 0) {
      setLoading(true);
      getFinancesByYear(mainTask, region).then((response) => {
        const labels = selectedYear;
        let datasets = initDataset(labels);
        let dataMap = new Map();
        response.data.forEach((dataValue) => {
          dataMap.set(dataValue.year, dataValue);
        });

        labels.forEach((year, index) => {
          let yearData = dataMap.get(year);
          if (yearData) {
            datasets[0].data[index] = yearData.total_real;
            datasets[1].data[index] = yearData.total_planned;
          }
        });
        setData({
          labels,
          datasets,
          abscisseType: ABSISCES_TYPES.MULTI_ANNUEL.value,
        });
        setIntervals(computeIntervals(datasets));
        setLoading(false);
        let barDatasets = datasets.map((set) => {
          let newSet = { ...set };
          newSet.data = [0, ...set.data, 0];
          return newSet;
        });
        // barData = {
        //   labels: ["", ...labels, ""],
        //   datasets: barDatasets,
        // };
      });
    }
  };

  const renderAnnuelChart = (year) => {
    if (selectedYear === null || region === null) {
      return;
    }

    setLoading(true);
    const r = region != "TOUT" ? region : null;
    getFinancesByTrimester(year, mainTask, r).then((response) => {
      const labels = ABSISCES_TYPES.ANNUEL.labels;
      let datasets = initDataset(labels);
      response.data.forEach((dataValue, index) => {
        datasets[0].data[index] = dataValue.total_real;
        datasets[1].data[index] = dataValue.total_planned;
      });
      setData({
        labels,
        datasets,
      });
      setIntervals(computeIntervals(datasets));
      setLoading(false);

      let barDatasets = datasets.map((set) => {
        let newSet = { ...set };
        newSet.data = [0, ...set.data, 0];
        return newSet;
      });
      // barData = {
      //   labels: ["", ...labels, ""],
      //   datasets: barDatasets,
      // };
    });
  };

  const filterBySubComponent = (code) => {
    setMainTask(code);
  };

  useEffect(() => {
    const node = abscissesTypesRef.current;
    if (!node.state.selectValue[0]) return;

    if (node.state.selectValue[0].value === ABSISCES_TYPES.MULTI_ANNUEL.value) {
      renderAnnuelChart(selectedYear);
    }
  }, [mainTask, region]);

  const handleYearSelection = (event) => {
    const selectedYear = event && event.value;
    setSelectedYear(selectedYear);
  };

  useEffect(() => {
    renderAnnuelChart(selectedYear);
  }, [selectedYear, region]);

  const handleRegionSelection = (event) => {
    const selectedRegion = event && event.value;
    setRegion(selectedRegion);
  };

  var abscisseTypes = Object.keys(ABSISCES_TYPES).map((absType) => ({
    label: ABSISCES_TYPES[absType].value.replace(/_/g, " "),
    value: ABSISCES_TYPES[absType].value,
  }));
  const updateAbscisseType = (absType) => {
    // setabscisseType(absType);
    if (absType === ABSISCES_TYPES.MULTI_ANNUEL.value) {
      renderMultiAnnuelChart();
    }
  };
  const regions = [
    "AGADAZ",
    "DOSSO",
    "MARADI",
    "TAHOUA",
    "ZINDER",
    "DIFFA",
    "TILLABERY",
  ];
  const REGION_SELECT = [
    { label: "TOUT", value: "TOUT" },
    ...regions.map((name) => ({ label: name, value: name })),
  ];
  return (
    <div>
      <button
        className="btn btn-danger mx-1 container-floatting-down-left hidden-content"
        onClick={() => showSideBar()}
        id="sidebarShower"
      >
        <i className="bi bi-list container-icon-customization-1"></i>
      </button>

      <div className="mb-4 space-y-4">
        <div className="scomp-container">
          <fieldset>
            <div className="scomp-radio">
              <input
                type="radio"
                id={mainTask}
                name="scomp"
                value={mainTask}
                onClick={(event) => filterBySubComponent(mainTask)}
                defaultChecked
              />
              <label htmlFor={mainTask} className="scomp-label">
                Tout
              </label>
            </div>
            {subComponents &&
              subComponents.map((scomp) => {
                return (
                  <div key={scomp.code} className="scomp-radio">
                    <input
                      type="radio"
                      id={scomp.code}
                      name="scomp"
                      value={scomp.code}
                      onClick={(event) => filterBySubComponent(scomp.code, event)}
                    />
                    <label htmlFor={scomp.code} className="scomp-label">
                      Sous composante {scomp.code}
                    </label>
                  </div>
                );
              })}
          </fieldset>
        </div>

        <div className="flex flex-wrap gap-4 items-center justify-center sm:max-w-2xl mx-auto px-4">
          <div className="flex-[1_1_200px]">
            <Select
              options={REGION_SELECT}
              placeholder="Zone d'intervention"
              onChange={handleRegionSelection}
              ref={abscissesTypesRef}
              className="select-input-2 !w-full"
              value={region && { label: region, value: region }}
            />
          </div>

          <div className="flex-[1_1_200px]">
            <Select
              options={YEARS.map((value) => ({ label: value, value }))}
              placeholder="Année d'intervention"
              onChange={handleYearSelection}
              className="select-input-2 !w-full"
              isClearable
              value={selectedYear && { label: selectedYear, value: selectedYear }}
            />
          </div>
        </div>
      </div>

      {loading && <Spinner />}
      {!loading && data.datasets.length > 0 && (
        <>
          <div className="full-width flex justify-center w-[90%] mx-auto">
            <Line
              data={data}
              options={options}
              ref={(reference) => (reference = reference)}
            />
          </div>

          <div className="full-width flex justify-center w-[90%] mx-auto">
            <Bar
              data={data}
              options={options}
              ref={(reference) => {
                const referenceBar = reference;
              }}
            />
          </div>

          <div className="full-width w-4/5 mx-auto my-8">
            <ul className="intervals py-3 !pl-4 sm:!pl-8 space-y-2">
              {intervals &&
                intervals.map((element, i) => {
                  let labels = data.labels;
                  let from = labels[element.from];
                  let to = labels[element.to];
                  let textAtTop = "";
                  let textAtBottom = "";
                  let comparison = "égalent les";
                  if (element.atTop === "realised") {
                    comparison = "sont au dessus des";
                  } else if (element.atTop === "planned") {
                    comparison = "sont en dessous des";
                  }

                  let moneyName = "";
                  let divider = 1;
                  if (element.total > 10 ** 9) {
                    moneyName = "milliards(s) de ";
                    divider = 10 ** 9;
                  } else if (element.total > 10 ** 6) {
                    moneyName = "million(s) de ";
                    divider = 10 ** 6;
                  } else if (element.total > 1000) {
                    moneyName = "mille ";
                    divider = 10 ** 3;
                  }
                  let diff = element.total / divider;
                  return (
                    <li key={i}>
                      {element.from !== element.to && (
                        <span>
                          {from} à {to}:{" "}
                        </span>
                      )}
                      {element.from === element.to && <span>En {from}: </span>}
                      Les dépenses réalisées {comparison} dépenses prévues
                      {element.total > 0 && (
                        <span>
                          {" "}
                          avec une différence de {diff.toFixed(2)} {moneyName}fcfa
                          environ
                        </span>
                      )}
                    </li>
                  );
                })}
            </ul>
          </div>
        </>
      )}
    </div>
  );
};

export default Courbe;
