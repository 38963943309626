import React, { Component } from "react";
import "./DashboardStyle.css";
import "react-toastify/dist/ReactToastify.css";
import HelperFunctions from "helperfunctions";
import { apiCall } from "services/PrimaryRequest.js";
import { getTasks } from "services/TaskServices";
import { APIERRORCODELIST } from "../../constants";
import TaskChildrenToDisplay from "./TaskChildrenToDisplay";

class TaskCardComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      childrenTasks: [],
      processOnGoing: false,
      error: "",
    };
  }

  updateFunction(task) {
    this.props.updateFunction(task);
  }

  deleteFunction(task) {
    this.props.deleteFunction(task);
  }

  async getChildrenTasks(projectId, params = new URLSearchParams()) {
    var apiCallReponse = await apiCall(function () {
      return getTasks({ projectId: projectId, params: params });
    });

    this.setState({
      processOnGoing: true,
    });

    if (APIERRORCODELIST().includes(apiCallReponse.get("responseType"))) {
      this.setState({
        processOnGoing: false,
        error: apiCallReponse.get("message"),
      });
    } else {
      //S'il n'y a pas d'erreur retournée par la requete
      var tempList = [];
      var response = apiCallReponse.get("data");
      var data = response["results"];

      if (data.length > 0) {
        data.forEach((element) => {
          tempList.push(HelperFunctions.taskfromJson(element));
        });
      } // Convertire les données reçues depuis le serveur en Task suivant le model présent

      this.setState({
        childrenTasks: tempList,
        processOnGoing: false,
      });
    }
  }

  async showTaskChildren(task) {
    let parentId = "parent-id-" + task.id;
    var col = document.getElementById(parentId);

    if (col !== null) {
      col.classList.toggle("active");
      let childId = "children-id-" + task.id;
      let arrowId = "arrow-id-" + task.id;
      var content = document.getElementById(childId);
      var arrow = document.getElementById(arrowId);
      let contentClassList = content.classList;
      let arrowClassList = arrow.classList;
      if (contentClassList.contains("hidden-content")) {
        contentClassList.remove("hidden-content");
        arrowClassList.add("active-arrow-show-children");
        contentClassList.add(
          "row",
          "ms-2",
          "d-flex",
          "flex-row",
          "justify-content-start",
        );
      } else {
        contentClassList.add("hidden-content");
        contentClassList.remove(
          "row",
          "ms-2",
          "d-flex",
          "flex-row",
          "justify-content-start",
        );
        arrowClassList.remove("active-arrow-show-children");
      }
    }

    var params = new URLSearchParams();
    params.append("parent", task.id);

    if (this.state.childrenTasks.length < 1) {
      await this.getChildrenTasks(1, params).then((response) => {});
    }
  }

  childrenContainerId(taskId) {
    let idString = "children-id-" + taskId;
    return idString;
  }

  parentCollapsibleId(taskId) {
    let idString = "parent-id-" + taskId;
    return idString;
  }

  arrowCollapsibleId(taskId) {
    let idString = "arrow-id-" + taskId;
    return idString;
  }

  componentDidMount() {}

  render() {
    const visibleCollapsibleIcon = "collapsible";
    const hiddenCollapsibleIcon = "hidden-content";

    return (
      <>
        <div className="card mb-2 border border-primary">
          <div className="card-body flex">
            <div className="border-end flex">
              <i
                className={
                  this.props.task.childrenCount === 0
                    ? hiddenCollapsibleIcon
                    : visibleCollapsibleIcon
                }
                onClick={() => this.showTaskChildren(this.props.task)}
                id={this.parentCollapsibleId(this.props.task.id)}
              >
                <i
                  className="bi bi-caret-right-fill arrow-show-children"
                  id={this.arrowCollapsibleId(this.props.task.id)}
                ></i>
              </i>
              <span className="mx-1">{this.props.task.id}</span>
            </div>
            <div className="flex flex-col sm:flex-row sm:items-center w-full space-y-2 sm:space-y-0">
              <div className="flex-1 mx-2">
                <h5 className="card-title text-sm md:text-base">
                  {this.props.task.name}
                </h5>
                <div className="row justify-content-start row-cols-auto text-secondary">
                  <span className="col mx-1 align-self-center">
                    <i className="bi bi-calendar2"></i>{" "}
                    {HelperFunctions.formatDate(this.props.task.start)}
                  </span>
                  <span className="col mx-1 align-self-center">
                    <i className="bi bi-calendar2-check"></i>{" "}
                    {HelperFunctions.formatDate(this.props.task.end)}
                  </span>
                </div>
              </div>
              <div className="">
                <div className="">
                  <button
                    className="col btn btn-primary m-1"
                    onClick={() => this.updateFunction(this.props.task)}
                  >
                    <i className="bi bi-pencil-square"></i>
                  </button>
                  <button
                    className="col btn btn-danger m-1"
                    onClick={() => this.deleteFunction(this.props.task)}
                  >
                    <i className="bi bi-trash"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="hidden-content"
          id={this.childrenContainerId(this.props.task.id)}
        >
          <div className="child-task-line"></div>
          <div className="flex-grow-1">
            <TaskChildrenToDisplay
              processOnGoing={this.state.processOnGoing}
              error={this.state.error}
              updateFunction={this.props.updateFunction}
              deleteFunction={this.props.deleteFunction}
              childrenTasks={this.state.childrenTasks}
            />
          </div>
        </div>
      </>
    );
  }
}

export default TaskCardComponent;
