import { requestHeader } from "./helperServices";
import axios from "./axios.js";
import { API_URL } from "../constants";

export async function getFinancesByYear(task, region = null) {
  let params = { composante: task };
  if (region) {
    params.region = region;
  }
  const response = await axios.get("/finances/by_year/", {
    headers: requestHeader(),
    params,
  });
  var dataToReturn = { data: response.data };

  return dataToReturn;
}

export async function getFinancesByTrimester(year, task, region = null) {
  let params = { year, composante: task };
  if (region) {
    params.region = region;
  }

  const response = await axios.get(`finances/by_trimester/`, {
    headers: requestHeader(),
    params,
  });
  var dataToReturn = { data: response.data };

  return dataToReturn;
}

export async function createFinanceWithFile({ payload }) {
  return await axios.post("/finances/template/", payload, { headers: requestHeader() });
}

export async function getFinanceFile() {
  const headers = requestHeader();
  headers.Accept = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
  return await fetch(`${API_URL}/finances/template/`, { headers });
}
