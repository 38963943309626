import React, { Component } from "react";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { getTasks, createTask, updateTask } from "services/TaskServices";

import "react-datepicker/dist/react-datepicker.css";
import HelperFunction from "../../helperfunctions";
import dateFormat, { masks } from "dateformat";
import { Modal, Button, Form } from "react-bootstrap";
import { Wallet2, Wallet } from "react-bootstrap-icons";
import { getAllUsers } from "services/UserServices";
import { Spinner } from "components/spinner";
import { TASKTYPES } from "../../constants";
import autoBind from "react-autobind";

class AddTaskForm extends Component {
  constructor(props) {
    super(props);

    this.initialState = {
      operationInProgress: false,
      taskName: "",
      taskDescription: "",
      userAssigned: 0,
      predecessors: [],
      beginingDate: "",
      endDate: "",
      minDate: null,
      progress: "",
      isCompleted: false,

      projectError: [],
      nameError: [],
      progressError: [],
      descriptionError: [],
      deadlineError: [],
      assigneeError: [],
      startDateError: [],
      isCompletedError: [],

      formData: new FormData(),
      userOptions: [],
      taskOptions: [],
      parentTaskOptions: [],

      parent: 0,
      approver: 0,
      informed: 0,
      estimatedAmount: "",
      spentAmount: "",
      code: "",

      parentError: [],
      predecessorsError: [],
      approverError: [],
      informedError: [],
      estimatedAmountError: [],
      spentAmountError: [],
      codeError: [],
      categoryError: [],
      otherError: [],
      parentNoOptionsMessage: this.returnNoOption(),
      userList: [],
      selectedTaskType: { value: 0, label: "Sélectionner le type" },
      taskTypeOptions: this.generateTaskTypeOption(),
      allFieldMayNotBeEdited: true,
      isFormValid: false,
    };

    this.state = this.initialState;
    autoBind(this);
  }

  generateTaskTypeOption() {
    let taskTypeOptions = TASKTYPES.map((taskType) => ({
      value: taskType.id,
      label: taskType.name,
    }));
    return taskTypeOptions;
  }

  generateUserOptions(sourceList) {
    var userOptions = sourceList.map((user) => ({ value: user.id, label: user.name }));
    return userOptions;
  }

  getDefaultParentOption(taskList = this.props.taskList) {
    var tempParentOption = null;

    if (this.props.task) {
      let tempTask = taskList.find((element) => element.id === this.props.task.parent);
      if (tempTask) {
        tempParentOption = {
          value: this.props.task.parent,
          label: tempTask.name,
        };
      }
    }
    return tempParentOption;
  }

  getUserByName(name) {
    return this.props.userList.find((element) => element.name === name);
  }

  getUserOptionByName(name) {
    if (!this.props.task) {
      return;
    }
    const user = this.getUserByName(this.props.task[name]);
    return user && { value: user.id, label: user.name };
  }

  getDefaultAssigneeOption() {
    return this.getUserOptionByName("assignedUser");
  }

  getDefaultApproverOption() {
    return this.getUserOptionByName("approver");
  }

  getDefaultInformedOption() {
    return this.getUserOptionByName("informed");
  }

  getDefaultPredecessorsOption() {
    var tempOptions = [];

    if (this.props.task) {
      if (
        this.props.task.dependencies !== null ||
        this.props.task.dependencies !== undefined
      ) {
        let tempTasks = this.props.taskList.filter((element) => {
          return this.props.task.dependencies.includes(element.id);
        });
        if (tempTasks.length > 0) {
          tempTasks.forEach((element) => {
            tempOptions.push({ value: element.id, label: element.name });
          });
        }
      }
    }

    return tempOptions;
  }

  componentDidUpdate(prevprops, prevstate) {
    if (this.props.taskList && prevprops.taskList !== this.props.taskList) {
      this.setState({
        taskOptions: this.generateTaskOptions(),
        parentTaskOptions: this.generateTaskOptions(),
      });
    }

    if (prevprops.task !== this.props.task && this.props.task) {
      let task = this.props.task;
      if (task.type === "Activité") {
        this.setState({
          allFieldMayNotBeEdited: false,
        });
      } else {
        this.setState({
          allFieldMayNotBeEdited: true,
        });
      }
      this.setState({
        id: this.props.task.id,
        taskName: task.name || "",
        taskDescription: task.description || "",
        userAssigned: task.assignedUser || 0,
        beginingDate: task.start || "",
        endDate: task.end || "",
        progress: task.progress || 0,
        isCompleted: Boolean(task.isCompleted),
        parent: task.parent || 0,
        approver: task.approver || 0,
        informed: task.informed || 0,
        estimatedAmount: task.estimatedAmount || "",
        spentAmount: task.spentAmount || "",
        code: task.id || "",
        selectedTaskType: { value: task.type, label: task.type },
        isFormValid: true,
      });
    }

    if (
      this.state.selectedTaskType &&
      prevstate.selectedTaskType !== this.state.selectedTaskType
    ) {
      if (this.state.selectedTaskType.value === "Activité") {
        this.setState({
          allFieldMayNotBeEdited: false,
        });
      } else {
        this.setState({
          allFieldMayNotBeEdited: true,
        });
      }
    }
  }

  async handleFormSubmit({ isUpdate, payload }) {
    if (isUpdate) {
      return this.updateTaskInForm(payload);
    }
    return this.createTaskInForm(payload);
  }

  manageFormSubmitError(error) {
    this.setState({
      operationInProgress: false,
    });
    if (error.get("code") === "ERR_BAD_REQUEST") {
      var errorData = error.get("data");
      this.setState({
        projectError: errorData.project ? errorData.project : [],
        nameError: errorData.name ? errorData.name : [],
        codeError: errorData.code ? errorData.code : [],
        categoryError: errorData.category ? errorData.category : [],
        progressError: errorData.percentage ? errorData.percentage : [],
        descriptionError: errorData.description ? errorData.description : [],
        deadlineError: errorData.deadline ? errorData.deadline : [],
        assigneeError: errorData.assignee ? errorData.assignee : [],
        startDateError: errorData.start_date ? errorData.start_date : [],
        isCompletedError: errorData.is_completed ? errorData.is_completed : [],
        parentError: errorData.is_completed ? errorData.is_completed : [],
        predecessorsError: errorData.predecessors ? errorData.predecessors : [],
        approverError: errorData.approver ? errorData.approver : [],
        informedError: errorData.informed ? errorData.informed : [],
        estimatedAmountError: errorData.estimated_amount
          ? errorData.estimated_amount
          : [],
        spentAmountError: errorData.spent_amount ? errorData.spent_amount : [],
        otherError: errorData.detail ? errorData.detail : "",
      });
    } else {
      let errorData = error.get("data");
      this.setState({
        otherError: errorData.detail ? errorData.detail : [],
      });
    }
  }

  manageFormSubmitSuccess() {
    this.setState(this.initialState);
  }

  checkFormValidity() {
    let formHasError = false;
    this.setState({
      projectError: [],
      nameError: [],
      codeError: [],
      categoryError: [],
      progressError: [],
      descriptionError: [],
      deadlineError: [],
      assigneeError: [],
      startDateError: [],
      isCompletedError: [],
      parentError: [],
      predecessorsError: [],
      approverError: [],
      informedError: [],
      estimatedAmountError: [],
      spentAmountError: [],
      otherError: "",
    });
    if (!this.state.code) {
      let temp = this.state.codeError.slice();
      temp.push("Ce champ ne peut être vide");
      this.setState({
        codeError: temp,
      });
      formHasError = true;
    }
    if (!this.state.selectedTaskType) {
      let temp = this.state.categoryError.slice();
      temp.push("Ce champ ne peut être vide");
      this.setState({
        categoryError: temp,
      });
      formHasError = true;
    } else if (this.state.selectedTaskType.value === 0) {
      let temp = this.state.categoryError.slice();
      temp.push("Ce champ est obligatoire");
      this.setState({
        categoryError: temp,
      });
      formHasError = true;
    }
    if (isNaN(this.state.code)) {
      let temp = this.state.codeError.slice();
      temp.push("Ce champ ne peut qu'être numérique");
      this.setState({
        codeError: temp,
      });
      formHasError = true;
    }
    if (!this.state.taskName) {
      let temp = this.state.nameError.slice();
      temp.push("Ce champ ne peut être vide");
      this.setState({
        nameError: temp,
      });
      formHasError = true;
    }
    if (!this.state.userAssigned) {
      let temp = this.state.assigneeError.slice();
      temp.push("Ce champ est requis");
      this.setState({
        assigneeError: temp,
      });
      formHasError = true;
    }
    if (!this.state.approver) {
      let temp = this.state.approverError.slice();
      temp.push("Ce champ est requis");
      this.setState({
        approverError: temp,
      });
      formHasError = true;
    }
    if (!this.state.informed) {
      let temp = this.state.informedError.slice();
      temp.push("Ce champ est requis");
      this.setState({
        informedError: temp,
      });
      formHasError = true;
    }

    return formHasError;
  }

  returnNoOption(errorMessage = "") {
    // Todo: Does this need to return a function ?
    if (!errorMessage) {
      const override = {
        display: "block",
        margin: "auto",
        textAlign: "center",
      };
      return () => <Spinner override={override} />;
    } else {
      return () => <h6>Une erreur s'est produite</h6>;
    }
  }

  handleSuccess() {
    this.manageFormSubmitSuccess();
    this.props.callBackSuccess();
  }

  handleFailure(error) {
    let errorHandled = HelperFunction.handleErrors(error);
    this.manageFormSubmitError(errorHandled);
  }

  async createTaskInForm(payload) {
    let projectId = this.props.projectId;
    createTask(payload, projectId)
      .then(() => {
        this.handleSuccess();
      })
      .catch((error) => {
        this.handleFailure(error);
      });
  }

  async updateTaskInForm(payload) {
    let projectId = this.props.projectId;
    updateTask({
      payload: payload,
      // Todo: Do we need this ? If payload is finally sent, its code is probably the props code
      code: this.props.task ? this.props.task.id : payload.code,
      project: projectId,
    })
      .then(() => {
        this.handleSuccess();
      })
      .catch((error) => {
        this.handleFailure(error);
      });
  }

  handleSubmit(event) {
    event.preventDefault();

    if (!this.checkFormValidity()) {
      var parentTasks = [];
      if (this.state.predecessors) {
        parentTasks = this.state.predecessors.map((selection) => selection.value);
      }

      var payload = {
        name: this.state.taskName,
        description: this.state.taskDescription ? this.state.taskDescription : null,
        start_date:
          this.state.beginingDate !== "" ? this.state.beginingDate.toISOString() : null,
        deadline: this.state.endDate !== "" ? this.state.endDate.toISOString() : null,
        assignee: this.state.userAssigned ? this.state.userAssigned.label : null,
        category: this.state.selectedTaskType
          ? this.state.selectedTaskType.label
          : null,
        predecessors: parentTasks,
        parent: this.state.parent ? this.state.parent.value : null,
        approver: this.state.approver ? this.state.approver.value : null,
        informed: this.state.informed ? this.state.informed.value : null,
        estimated_amount:
          this.state.estimatedAmount === "" ? 0 : this.state.estimatedAmount,
        spent_amount: this.state.spentAmount === "" ? 0 : this.state.spentAmount,
        code: this.state.code,
        project: this.props.projectId,
        percentage: this.state.progress === "" ? 0 : this.state.progress,
      };

      var isUpdate = Boolean(this.props.task);

      this.setState({
        operationInProgress: true,
      });

      this.handleFormSubmit({ isUpdate: isUpdate, payload: payload });
    }
  }

  addCommas(num) {
    return this.removeNonNumeric(num).replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  }

  removeNonNumeric(num) {
    return num.toString().replace(/[^0-9]/g, "");
  }

  handleInputChange(valueToUpdate, event) {
    // Todo: Change this, it is called too many times at creation time

    let newState = {};
    let classicInputs = [
      "taskName",
      "taskDescription",
      "progress",
      "estimatedAmount",
      "spentAmount",
      "code",
    ];
    let selectInputs = [
      "userAssigned",
      "predecessors",
      "isCompleted",
      "parent",
      "approver",
      "informed",
      "selectedTaskType",
    ];
    if (classicInputs.includes(valueToUpdate)) {
      if (valueToUpdate === "estimatedAmount" || valueToUpdate === "spentAmount") {
        newState[valueToUpdate] = this.addCommas(event.target.value);
      } else {
        newState[valueToUpdate] = event.target.value;
      }
      // let value = valueToUpdate.includes("Amount") ? this.addCommas(event.target.value) : event.target.value
    } else if (selectInputs.includes(valueToUpdate)) {
      newState[valueToUpdate] = event;
      if (valueToUpdate === "selectedTaskType") {
        this.getPossibleParentTask(event.value);
      }
    } else {
      // Date fields
      newState[valueToUpdate] = new Date(event);
      if (valueToUpdate === "beginingDate") {
        newState["minDate"] = event;
      }
    }
    this.setState(newState);
  }

  generateTaskOptions(taskList = this.props.taskList) {
    var taskOptions = [{ value: 0, label: "Choisir une tâche" }];

    taskList.forEach((task) => {
      // Todo: After deciding suggested parents will be tasks of different type, this might not be necessary anymore
      if (this.props.task === null || this.props.task.id !== task.id) {
        //Empecher que la tache actuelle se trouve parmi les tache parent
        taskOptions.push({ value: task.id, label: task.name });
      }
    });
    return taskOptions;
  }

  getPossibleParentTask(currentTaskCategory = null) {
    if (!currentTaskCategory && this.props.task) {
      currentTaskCategory = this.props.task.type;
    }
    this.setState({
      parentNoOptionsMessage: this.returnNoOption(),
    });
    let parentCategory = "";
    if (currentTaskCategory === "Sous composante") {
      parentCategory = "Composante";
    } else if (currentTaskCategory === "Volet") {
      parentCategory = "Sous composante";
    } else if (currentTaskCategory === "Activité") {
      parentCategory = "Volet";
    } else {
      parentCategory = "Composante";
    }
    let params = new URLSearchParams();
    params.append("category", parentCategory);

    getTasks({
      // link: "/propro",
      projectId: this.props.projectId,
      params: params,
    })
      .then((response) => {
        var optionsList = response.data.results.map((element) => ({
          value: element.code,
          label: element.name,
        }));
        this.setState({
          parentTaskOptions: optionsList,
        });
      })
      .catch((error) => {
        // let errorMessage = HelperFunction.handleErrors(error).get("errormessage");
        this.setState({
          parentNoOptionsMessage: this.returnNoOption("Une erreur est survenue"),
          parentTaskOptions: [],
        });
      });
  }

  onShow() {
    this.getPossibleParentTask();
    this.getDefaultParentOption();
    this.setState({
      userAssigned: this.getDefaultAssigneeOption(),
      parent: this.getDefaultParentOption(),
      approver: this.getDefaultApproverOption(),
      informed: this.getDefaultInformedOption(),
      predecessors: this.getDefaultPredecessorsOption(),
      userOptions: this.generateUserOptions(this.props.userList),
    });
  }

  onHide() {
    this.props.hide();
    this.setState(this.initialState);
  }

  render() {
    return (
      <div>
        <Modal show={this.props.show} onHide={this.onHide} onShow={this.onShow}>
          <Modal.Header closeButton>
            <Modal.Title>
              {this.state.code && "Modifier"}
              {!this.state.code && "Créer"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={this.handleSubmit}>
              <div className="form-outline mb-4">
                <label className="form-label mb-1">Code</label>
                <input
                  required
                  type="number"
                  placeholder="Code"
                  className="form-control"
                  value={this.state.code}
                  onChange={(event) => this.handleInputChange("code", event)}
                />
                <ul className="mt-1">
                  {this.state.codeError.map((error, index) => (
                    <li key={index}>
                      {" "}
                      <span className="text-danger"> {error}</span>
                    </li>
                  ))}
                </ul>
              </div>

              <div className="form-outline mb-4">
                <label className="form-label mb-1">Nom</label>
                <input
                  required
                  type="text"
                  placeholder="Nom"
                  className="form-control"
                  value={this.state.taskName}
                  onChange={(event) => this.handleInputChange("taskName", event)}
                />
                <ul className="mt-1">
                  {this.state.nameError.map((error, index) => (
                    <li key={index}>
                      {" "}
                      <span className="text-danger"> {error}</span>
                    </li>
                  ))}
                </ul>
              </div>

              <div className="row justify-content-between mb-4">
                <div className="col">
                  <label className="form-label mb-1">Coût estimé</label>
                  <div className="input-group">
                    <input
                      required
                      type="text"
                      className="form-control"
                      min={0}
                      step={1}
                      value={this.state.estimatedAmount}
                      onChange={(event) =>
                        this.handleInputChange("estimatedAmount", event)
                      }
                      disabled={this.state.allFieldMayNotBeEdited}
                      style={{ textAlign: "right" }}
                    />
                    <div className="input-group-text fw-bold">
                      <Wallet2 />
                    </div>
                  </div>
                  <ul className="mt-1">
                    {this.state.estimatedAmountError.map((error, index) => (
                      <li key={index}>
                        {" "}
                        <span className="text-danger"> {error}</span>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="col">
                  <label className="form-label mb-1">Coût dépensé</label>
                  <div className="input-group">
                    <input
                      required
                      type="text"
                      className="form-control"
                      value={this.state.spentAmount}
                      disabled={this.state.allFieldMayNotBeEdited}
                      onChange={(event) => this.handleInputChange("spentAmount", event)}
                      style={{ textAlign: "right" }}
                    />
                    <div className="input-group-text fw-bold">
                      <Wallet />
                    </div>
                  </div>
                  <ul className="mt-1">
                    {this.state.spentAmountError.map((error, index) => (
                      <li key={index}>
                        {" "}
                        <span className="text-danger"> {error}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              <div className="row justify-content-between mb-4">
                <div className="col">
                  <div className="form-outline">
                    <label className="form-label mb-1">Date de début</label>
                    <DatePicker
                      className="form-control"
                      dateFormat="dd/MM/yyyy"
                      selected={this.state.beginingDate}
                      onChange={(date) => this.handleInputChange("beginingDate", date)}
                      // showTimeSelect
                      // timeFormat="HH:mm"
                      // timeIntervals={15}
                      // timeCaption="time"
                      required
                      disabled={this.state.allFieldMayNotBeEdited}
                    />
                    <ul className="mt-1">
                      {this.state.startDateError.map((error, index) => (
                        <li key={index}>
                          {" "}
                          <span className="text-danger"> {error}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>

                <div className="col">
                  <div className="form-outline">
                    <label className="form-label mb-1">Date de fin</label>
                    <DatePicker
                      className="form-control"
                      minDate={this.state.minDate}
                      dateFormat="dd/MM/yyyy"
                      selected={this.state.endDate}
                      onChange={(date) => this.handleInputChange("endDate", date)}
                      // showTimeSelect
                      // timeFormat="HH:mm"
                      // timeIntervals={15}
                      // timeCaption="time"
                      required
                      disabled={this.state.allFieldMayNotBeEdited}
                    />
                    <ul className="mt-1">
                      {this.state.deadlineError.map((error, index) => (
                        <li key={index}>
                          {" "}
                          <span className="text-danger"> {error}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>

              <div className="form-outline mb-4">
                <label className="form-label mb-1">Type</label>

                <Select
                  value={this.state.selectedTaskType}
                  defaultValue={this.state.selectedTaskType}
                  onChange={(value) =>
                    this.handleInputChange("selectedTaskType", value)
                  }
                  options={this.state.taskTypeOptions}
                />
                <ul className="mt-1">
                  {this.state.categoryError.map((error, index) => (
                    <li key={index}>
                      {" "}
                      <span className="text-danger"> {error}</span>
                    </li>
                  ))}
                </ul>
              </div>

              <div className="form-outline mb-4">
                <label className="form-label mb-1">Parent </label>

                <Select
                  value={this.state.parent}
                  defaultValue={this.state.parent}
                  noOptionsMessage={this.state.parentNoOptionsMessage}
                  onChange={(value) => this.handleInputChange("parent", value)}
                  options={this.state.parentTaskOptions}
                  isDisabled={this.state.allFieldMayNotBeEdited}
                />
                <ul className="mt-1">
                  {this.state.parentError.map((error, index) => (
                    <li key={index}>
                      {" "}
                      <span className="text-danger"> {error}</span>
                    </li>
                  ))}
                </ul>
              </div>

              <div className="form-outline mb-4">
                <label className="form-label mb-1">Prédecesseurs </label>

                <Select
                  value={this.state.predecessors}
                  isMulti
                  defaultValue={[{ value: 0, label: "Prédecesseurs" }]}
                  onChange={(value) => this.handleInputChange("predecessors", value)}
                  isDisabled={this.state.allFieldMayNotBeEdited}
                  options={this.state.taskOptions}
                />
                <ul className="mt-1">
                  {this.state.predecessorsError.map((error, index) => (
                    <li key={index}>
                      {" "}
                      <span className="text-danger"> {error}</span>
                    </li>
                  ))}
                </ul>
              </div>

              <div className="form-outline mb-4">
                <label className="form-label mb-1">Assignée à </label>

                <Select
                  value={this.state.userAssigned}
                  defaultValue={this.state.userAssigned}
                  onChange={(value) => this.handleInputChange("userAssigned", value)}
                  options={this.state.userOptions}
                  placeholder="Choisir un utilisateur"
                />
                <ul className="mt-1">
                  {this.state.assigneeError.map((error, index) => (
                    <li key={index}>
                      {" "}
                      <span className="text-danger"> {error}</span>
                    </li>
                  ))}
                </ul>
              </div>

              <div className="row justify-content-between mb-4">
                <div className="col">
                  <label className="form-label mb-1">Approbateur </label>

                  <Select
                    value={this.state.approver}
                    defaultValue={this.state.approver}
                    onChange={(value) => this.handleInputChange("approver", value)}
                    options={this.state.userOptions}
                  />
                  <ul className="mt-1">
                    {this.state.approverError.map((error, index) => (
                      <li key={index}>
                        {" "}
                        <span className="text-danger"> {error}</span>
                      </li>
                    ))}
                  </ul>
                </div>

                <div className="col">
                  <label className="form-label mb-1">Utilisateur à informer </label>

                  <Select
                    value={this.state.informed}
                    defaultValue={this.state.informed}
                    onChange={(value) => this.handleInputChange("informed", value)}
                    options={this.state.userOptions}
                  />
                  <ul className="mt-1">
                    {this.state.informedError.map((error, index) => (
                      <li key={index}>
                        {" "}
                        <span className="text-danger"> {error}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              <div className="form-outline mb-4">
                <label className="form-label mb-1">Description</label>
                <textarea
                  required
                  className="form-control"
                  rows="4"
                  placeholder="Decription de la tâche"
                  value={this.state.taskDescription}
                  onChange={(event) => this.handleInputChange("taskDescription", event)}
                ></textarea>
                <ul className="mt-1">
                  {this.state.descriptionError.map((error, index) => (
                    <li key={index}>
                      {" "}
                      <span className="text-danger"> {error}</span>
                    </li>
                  ))}
                </ul>
              </div>

              <div className="form-outline mb-4">
                <label className="form-label mb-1">Pourcentage d'évolution</label>
                <div className="input-group">
                  <input
                    required
                    type="number"
                    max={100}
                    min={0}
                    placeholder="Pourcentage d'évolution"
                    className="form-control"
                    value={this.state.progress}
                    disabled={this.state.allFieldMayNotBeEdited}
                    onChange={(event) => this.handleInputChange("progress", event)}
                  />
                  <div className="input-group-text fw-bold">%</div>
                </div>
                <ul className="mt-1">
                  {this.state.progressError.map((error, index) => (
                    <li key={index}>
                      {" "}
                      <span className="text-danger"> {error}</span>
                    </li>
                  ))}
                </ul>
              </div>

              <ul className="mt-2">
                {this.state.projectError.map((error, index) => (
                  <li key={index}>
                    {" "}
                    <span className="text-danger"> {error}</span>
                  </li>
                ))}
                {this.state.isCompletedError.map((error, index) => (
                  <li key={index}>
                    {" "}
                    <span className="text-danger"> {error}</span>
                  </li>
                ))}
                <span className="text-danger"> {this.state.otherError} </span>
              </ul>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.props.hide}>
              Annuler
            </Button>
            <Button variant="primary" onClick={this.handleSubmit}>
              Enregistrer
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default AddTaskForm;
