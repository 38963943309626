import React, { Component } from "react";
import { Accordion, Button } from "react-bootstrap";
import { Cash, Filter, Wallet2 } from "react-bootstrap-icons";
import DatePicker, { setDefaultLocale } from "react-datepicker";
import { addMonth, addDay } from "helper";
import { getTasks } from "services/TaskServices";
import HelperFunctions from "helperfunctions";
import { ToastContainer, toast } from "react-toastify";
import autoBind from "react-autobind";
import Select from "react-select";
import { Checkbox, FormControlLabel } from "@mui/material";

setDefaultLocale("fr");

const SELECT_GROUP_CLASSNAME = "col";

export default class TaskFilter extends Component {
  constructor(props) {
    super(props);
    this.initialState = {
      taskName: "",
      taskDescription: "",
      userAssigned: 0,
      predecessors: [],
      beginingDate: null,
      endDate: null,
      minDate: null,
      progress: "",
      isCompleted: false,

      formData: new FormData(),
      userOptions: [],
      taskOptions: [],

      parent: 0,
      approver: 0,
      informed: 0,
      estimatedAmount: "",
      spentAmount: "",
      code: "",

      dateNullChecked: false,

      composantes: [],
      selectedComposante: null,

      sousComposantes: [],
      allSousComposantes: [],
      selectedSousComposante: null,
      sousComposanteRef: React.createRef(),

      volets: [],
      allVolet: [],
      selectedVolet: null,
      voletRef: React.createRef(),

      activities: [],
      allActivities: [],
      selectedActivity: null,
      activityRef: React.createRef(),

      showComposanteFilters: false,
      subActivitiesOnly: false,
    };

    this.state = this.initialState;

    this.handleSubmit = this.handleSubmit.bind(this);
    this.cancelFilter = this.cancelFilter.bind(this);
    autoBind(this);
  }

  componentDidMount() {
    var params = new URLSearchParams([["category", "Composante"]]);
    this.getSomeTask(params, (data) => {
      this.setState({
        composantes: data,
      });

      this.initialState.composantes = data;
    });

    params = new URLSearchParams([["category", "Sous composante"]]);

    this.getSomeTask(params, (data) => {
      this.setState({
        allSousComposantes: data,
      });

      this.initialState.allSousComposantes = data;
    });

    params = new URLSearchParams([["category", "Volet"]]);

    this.getSomeTask(params, (data) => {
      this.setState({
        allVolet: data,
      });

      this.initialState.allVolet = data;
    });

    params = new URLSearchParams([["category", "Activité"]]);

    this.getSomeTask(params, (data) => {
      this.setState({
        allActivities: data,
      });

      this.initialState.allActivities = data;
    });
  }

  getSomeTask(params, successfullCallback) {
    getTasks({
      projectId: 1,
      params: params,
    })
      .then((response) => {
        var data = response.data.results;
        successfullCallback(data);
      })
      .catch((error) => {
        this.setState({
          apiCallHasError2: true,
          apiCallProcessing2: false,
        });

        let errorMessage = HelperFunctions.handleErrors(error).get("errormessage");

        toast.error(errorMessage, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  }

  onDateChange(dates) {
    // const [start, end] = dates;
    this.setState(
      {
        beginingDate: dates[0],
        endDate: dates[1],
      },
      () => {
        if (!dates.includes(null)) {
          this.handleSubmit();
        }
      },
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.detailView != prevProps.detailView) {
      this.setState({ showComposanteFilters: this.props.detailView });
      if (!this.props.detailView) {
        this.cancelFilter();
      }
    }
  }

  handleInputChange(valueToUpdate, event) {
    switch (valueToUpdate) {
      case "taskname":
        this.setState({
          taskName: event.target.value,
        });

        break;
      case "description":
        this.setState({
          taskDescription: event.target.value,
        });
        break;
      case "userassigned":
        this.setState({
          userAssigned: event,
        });
        break;
      case "predecessors":
        this.setState({
          predecessors: event,
        });
        break;

      case "progress":
        this.setState({
          progress: event.target.value,
        });
        break;
      case "iscompleted":
        this.setState({
          isCompleted: event,
        });
        break;

      case "parent":
        this.setState({
          parent: event,
        });
        break;
      case "approver":
        this.setState({
          approver: event,
        });
        break;
      case "informed":
        this.setState({
          informed: event,
        });
        break;
      case "estimatedamount":
        this.setState({
          estimatedAmount: event.target.value,
        });
        break;
      case "spentamount":
        this.setState({
          spentAmount: event.target.value,
        });
        break;
      case "code":
        this.setState({
          code: event.target.value,
        });
        break;
      case "datenull":
        this.setState({
          dateNullChecked: !this.state.dateNullChecked,
        });
        break;

      default:
    }
  }

  handleSubmit() {
    var params = new URLSearchParams();
    if (this.state.beginingDate) {
      let date = this.state.beginingDate;
      // Due to GMT date, we get the date before the selected date
      // when converting into iso.
      // Here we move back to the selected date
      if (date) {
        date = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1);
      }
      params.append("starts_after", date.toISOString().split("T")[0]);
    }
    if (this.state.endDate) {
      let endDate = this.state.endDate;
      // Before getting date string, we must include the current selected
      // ending month. So we move the selected date one month forward
      // and the iso issue move it one day backward and we get the last
      // day of the selected month. Awesome right ? :-)
      if (endDate) {
        endDate = new Date(
          endDate.getFullYear(),
          endDate.getMonth() + 1,
          endDate.getDate(),
        );
      }
      params.append("ends_before", endDate.toISOString().split("T")[0]);
    }

    if (this.state.parent !== 0) {
      params.append("parent", this.state.parent);
    }

    if (this.props.filterWithCurrentYear) {
      let current_year = new Date().getFullYear();
      params.append("year", current_year.toString());
    }

    if (this.state.subActivitiesOnly) {
      params.append("sub_activities_only", true);
    }

    if (params) {
      this.props.submitSearch(params);
    }
  }

  cancelFilter() {
    let params = new URLSearchParams();
    this.setState(this.initialState);
    this.props.submitSearch(params);
  }

  onSelectClear(taskType) {
    switch (taskType) {
      case "composante":
        this.state.sousComposanteRef.current.clearValue();
        this.state.voletRef.current.clearValue();
        this.state.activityRef.current.clearValue();
        this.setState({
          selectedSousComposante: null,
          selectedVolet: null,
          selectedActivity: null,
        });
        break;
      case "sous composante":
        this.state.voletRef.current.clearValue();
        this.state.activityRef.current.clearValue();
        this.setState({
          selectedVolet: null,
          selectedActivity: null,
        });
        break;

      case "volet":
        this.state.activityRef.current.clearValue();
        this.setState({
          selectedActivity: null,
        });
        break;
      default:
    }
  }

  handleTaskParentChange(taskType, event, action = null) {
    if (action && action.action === "clear") {
      this.onSelectClear(taskType);
      return;
    } else if (!event) {
      return;
    }
    this.setState({ parent: event.value }, () => {
      this.handleSubmit();
    });
    this.onSelectClear(taskType);

    switch (taskType) {
      case "composante":
        this.setState({
          selectedComposante: event.value,
          sousComposantes: this.state.allSousComposantes.filter(
            (element) => element.parent === event.value,
          ),
        });
        break;
      case "sous composante":
        this.setState({
          selectedSousComposante: event.value,
          volets: this.state.allVolet.filter(
            (element) => element.parent === event.value,
          ),
        });
        break;

      case "volet":
        this.setState({
          selectedVolet: event.value,
          activities: this.state.allActivities.filter(
            (element) => element.parent === event.value,
          ),
        });
        break;
      default:
    }
  }

  switchSubActivities(event) {
    this.setState({ subActivitiesOnly: event.target.checked }, () => {
      this.handleSubmit();
    });
  }

  render() {
    return (
      <Accordion defaultActiveKey="1" className="mb-4">
        <Accordion.Item eventKey="1">
          <Accordion.Header>
            <Filter className="mr-4" /> Filtres
          </Accordion.Header>
          <Accordion.Body>
            {!this.props.calendarOnly && (
              <div className="form-outline mb-4">
                <label className="form-label mb-1">Nom de la tâche</label>
                <input
                  required
                  type="text"
                  placeholder="Nom de la tâche"
                  className="form-control"
                  value={this.state.taskName}
                  onChange={(event) => this.handleInputChange("taskname", event)}
                />
              </div>
            )}
            {!this.props.calendarOnly && (
              <div className="row justify-content-between mb-4">
                <div className="col">
                  <label className="form-label mb-1">Coût estimé</label>
                  <div className="input-group">
                    <input
                      required
                      type="number"
                      placeholder="Coût estimé"
                      className="form-control"
                      value={this.state.estimatedAmount}
                      onChange={(event) =>
                        this.handleInputChange("estimatedamount", event)
                      }
                    />
                    <div className="input-group-text fw-bold">
                      <Cash />
                    </div>
                  </div>
                </div>
                <div className="col">
                  <label className="form-label mb-1">Coût dépensé</label>
                  <div className="input-group">
                    <input
                      required
                      type="number"
                      placeholder="Coût dépensé"
                      className="form-control"
                      value={this.state.spentAmount}
                      onChange={(event) => this.handleInputChange("spentamount", event)}
                    />
                    <div className="input-group-text fw-bold">
                      <Wallet2 />
                    </div>
                  </div>
                </div>
              </div>
            )}

            {!this.state.showComposanteFilters && (
              <div className="row justify-content-center mb-2">
                <div className="col text-center">
                  <DatePicker
                    dateFormat="MMM yyyy"
                    className="date-picker"
                    onChange={this.onDateChange}
                    // showTimeSelect
                    // timeFormat="HH:mm"
                    // timeIntervals={15}
                    // timeCaption="time"
                    required
                    startDate={this.state.beginingDate}
                    endDate={this.state.endDate}
                    showMonthYearPicker
                    selectsRange
                    placeholderText="Période"
                    // Todo: Set locales
                  />
                </div>
              </div>
            )}

            {this.state.showComposanteFilters && (
              <div className="mt-2 mb-2 space-y-2">
                <div className="flex flex-wrap gap-4 items-center">
                  <div className="flex-[1_1_200px]">
                    <Select
                      options={this.state.composantes.map((comp) => ({
                        label: "Composante " + comp.code,
                        value: comp.code,
                      }))}
                      isClearable={true}
                      placeholder="Composante"
                      onChange={(event, action) =>
                        this.handleTaskParentChange("composante", event, action)
                      }
                      className={SELECT_GROUP_CLASSNAME}
                    />
                  </div>

                  <div className="flex-[1_1_200px]">
                    <Select
                      options={this.state.sousComposantes.map((region) => ({
                        label: "Sous Composante " + region.code,
                        value: region.code,
                      }))}
                      isDisabled={!this.state.selectedComposante}
                      isClearable={true}
                      placeholder="Sous composante"
                      className={SELECT_GROUP_CLASSNAME}
                      onChange={(event) =>
                        this.handleTaskParentChange("sous composante", event)
                      }
                      // value={this.state.selectedSousComposante}
                      ref={this.state.sousComposanteRef}
                      noOptionsMessage={() => "Sélectionnez une composante"}
                    />
                  </div>
                  <div className="flex-[1_1_200px]">
                    <Select
                      options={this.state.volets.map((volet) => ({
                        label: "Volet " + volet.code,
                        value: volet.code,
                      }))}
                      isDisabled={!this.state.selectedSousComposante}
                      isClearable={true}
                      placeholder="Volet"
                      className={SELECT_GROUP_CLASSNAME}
                      onChange={(event) => this.handleTaskParentChange("volet", event)}
                      ref={this.state.voletRef}
                      noOptionsMessage={() => "Sélectionnez une sous composante"}
                    />
                  </div>
                  <div className="flex-[1_1_200px]">
                    <Select
                      options={this.state.activities.map((activity) => ({
                        label: "Activité " + activity.code,
                        value: activity.code,
                      }))}
                      isDisabled={!this.state.selectedVolet}
                      isClearable={true}
                      placeholder="Activité"
                      className={SELECT_GROUP_CLASSNAME}
                      onChange={(event) =>
                        this.handleTaskParentChange("activity", event)
                      }
                      ref={this.state.activityRef}
                      noOptionsMessage={() => "Sélectionnez un volet"}
                    />
                  </div>
                </div>
                <div className="">
                  <FormControlLabel
                    control={<Checkbox onChange={this.switchSubActivities} />}
                    label="Sous Activités"
                  />
                </div>
              </div>
            )}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    );
  }
}
