import { ToastContainer, toast } from "react-toastify";
import axios from "./services/axios";
import { API_URL } from "constants";
import { requestHeader } from "services/helperServices";
import { YEARS } from "constants";

const excel_accept =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

export default class HelperFunction {
  static showSideBar() {
    // Hide the sidebar
    var sidebar = document.getElementById("sidebar");
    var sidebarShower = document.getElementById("sidebarShower");

    if (sidebar.classList.contains("d-flex")) {
      sidebar.classList.remove("d-flex");
      sidebar.style.display = "none";
      sidebarShower.classList.remove("hidden-content");
    } else {
      sidebar.classList.add("d-flex");
      sidebar.style.display = "block";
      sidebarShower.classList.add("hidden-content");
    }
  }

  static isDataNull(dataToCheck) {
    var isNull = false;
    if (dataToCheck === "") {
      isNull = true;
    } else if (dataToCheck == null) {
      isNull = true;
    } else if (!dataToCheck) {
      isNull = true;
    } else if (dataToCheck === undefined) {
      isNull = true;
    } else if (dataToCheck === "undefined") {
      isNull = true;
    } else if (dataToCheck === null) {
      isNull = true;
    }
    return isNull;
  }

  static padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  static formatDate(date) {
    if (date == null) {
      return "";
    } else {
      date = new Date(date);
      date = new Date(date.getFullYear(), date.getMonth());
      try {
        return [
          this.padTo2Digits(date.getDate()),
          this.padTo2Digits(date.getMonth() + 1),
          date.getFullYear(),
        ].join("/");
      } catch (e) {
        return "";
      }
    }
  }

  static taskfromJson(element, startingDate = "", endingDate = "") {
    const currentDate = new Date();
    let withinATime = true;
    if (startingDate && endingDate) {
      let taskStartDate =
        element.computed_start_date && new Date(element.computed_start_date);
      let taskEndDate =
        element.computed_end_date && new Date(element.computed_end_date);

      startingDate = new Date(startingDate);
      endingDate = new Date(endingDate);

      if (!taskStartDate || taskStartDate < startingDate) {
        withinATime = false;
      } else if (!taskEndDate || taskEndDate > endingDate) {
        withinATime = false;
      }
    }

    let start = element.computed_start_date && new Date(element.computed_start_date);
    start = start && startingDate ? new Date(Math.max(start, startingDate)) : start;
    let end = element.computed_end_date && new Date(element.computed_end_date);
    end = end && endingDate ? new Date(Math.min(end, endingDate)) : end;

    let outsideTimeOption = {
      progressColor: "#7393B3",
      progressSelectedColor: "#6082B6",
      backgroundColor: "#D3D3D3",
      backgroundSelectedColor: "#808080",
    };
    let taskObject = {
      // Dates to be displayed on gantt project.
      // In case we enforce the task start and end dates because of filtering,
      // we want the real dates to be display anyway.
      startToDisplay: this.isDataNull(element.computed_start_date)
        ? currentDate
        : new Date(element.computed_start_date),
      endToDisplay: this.isDataNull(element.computed_end_date)
        ? currentDate
        : new Date(element.computed_end_date),
      // Dates to be considered by gantt module to display the task on he diagram
      start: start || currentDate,
      end: end || currentDate,
      name: element.name,
      id: element.code,
      progress: element.percentage,
      type: element.category ? element.category : "task",
      project: "Niger LIRE",
      parent: element.parent,
      estimatedAmount: element.estimated_amount,
      spentAmount: element.spent_amount,
      approver: element.approver,
      informed: element.informed,
      dependencies: element.predecessors,
      description: element.description,
      assignedUser: element.assignee,
      isCompleted: this.isDataNull(element.is_completed) ? false : element.is_completed,
      duration: element.duration,
      createdBy: element.created_by,
      childrenCount: element.children_count,
      hideChildren: true,
      expanded: false,
      styles: !withinATime ? outsideTimeOption : {},
      estimatedAmount: element.estimated_amount,
      beneficiaire: element.region_beneficiaire,
    };
    return taskObject;
  }

  static taskOptionsfromJson({ dataSource, defaultValueLabel = "Choisir une tâche" }) {
    var taskOptions = [{ value: 0, label: defaultValueLabel }];

    dataSource.forEach((task) => {
      taskOptions.push({ value: task.code, label: task.name });
    });

    return taskOptions;
  }

  static handleErrors(error) {
    let returnMe = new Map();
    let errorMessage;
    let code = "";
    let data = {};
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      errorMessage = error.message;
      code = error.code;
      data = error.response.data;
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      errorMessage = "Server Error";
      code = "503";
      data = error.request;
    } else {
      // Something happened in setting up the request that triggered an Error
      errorMessage = "Something went wrong, please contact us";
      code = "503";
      data = error.request;
    }
    returnMe.set("code", code);
    returnMe.set("errormessage", errorMessage);
    returnMe.set("data", data);
    return returnMe;
  }

  static handleBlobDownloadFile(blob, fileName) {
    // const url = window.URL.createObjectURL(blob);
    // const link = document.createElement("a");
    // link.href = url;
    // link.setAttribute("download", fileName);
    // document.body.appendChild(link);
    // link.click();
    // link.remove();
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "file-name.ext";
    link.click();
  }

  static handleDownloadFileError(errorMessage) {
    toast.error(errorMessage, {
      position: "bottom-left",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  static downloadFile(endPoint, fileName, accept = excel_accept) {
    const headers = requestHeader();
    headers.Accept = accept;
    console.log(headers);
    fetch(`${API_URL}/${endPoint}`, { headers })
      .then((result) => {
        if (!result.ok) {
          throw Error(result.statusText);
        }
        return result.blob();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
      });
  }

  static async creationWithFile({ endPoint, payload }) {
    return await axios.post(endPoint, payload);
  }

  static minMaxDates(datesArray) {
    let min = new Date(datesArray[0]);
    let max = new Date(datesArray[0]);

    for (let i = 1; i < datesArray.length; i++) {
      let currentDate = new Date(datesArray[i]);

      if (currentDate < min) {
        min = currentDate;
      }

      if (currentDate > max) {
        max = currentDate;
      }
    }

    return [min, max];
  }

  static transformToDataSet(indicator, data, colors, displayTarget) {
    let labels = YEARS.map((element) => {
      return element;
    });
    let firstValues = data.map((element) => element.total_real);

    let datasets = [
      {
        label: "Total réalisé",
        data: firstValues,
        backgroundColor: colors[0] ?? "rgba(255, 99, 132, 0.5)",
        order: 2,
      },
    ];

    if (indicator.measure_unit === "gender_related") {
      const secondValues = data.map((element) => element.total_female_real);
      datasets.push({
        label: "Nombre de femmes",
        data: secondValues,
        backgroundColor: "rgba(255, 99, 132, 0.5)",
        order: 1,
      });
    }

    if (displayTarget && indicator.target_value) {
      datasets.push({
        label: "Cible",
        data: Array(firstValues.length).fill(indicator.target_value),
        backgroundColor: colors[1] ?? "rgb(75, 192, 192)",
        type: "line",
      });
      if (
        indicator.female_target_value &&
        indicator.measure_unit === "gender_related"
      ) {
        datasets.push({
          label: "Nombre de femmes ciblés",
          data: Array(firstValues.length).fill(indicator.female_target_value),
          backgroundColor: "rgb(75, 192, 192)",
          type: "line",
        });
      }
    }
    return {
      labels,
      datasets,
    };
  }

  static barChartOption(graphTitle, onBarClick) {
    return {
      responsive: true,
      plugins: {
        legend: {
          position: "bottom",
          labels: {
            usePointStyle: true,
          },
        },
        title: {
          display: false,
          text: graphTitle,
          fullSize: false,
        },
      },
      onClick: onBarClick,
      scale: { ticks: { precision: 0 } },
    };
  }
}
