import React, { Component } from "react";
import Select from "react-select";

import "react-datepicker/dist/react-datepicker.css";

import HelperFunction from "../../helperfunctions";
import { Modal } from "react-bootstrap";
import {
  getAllUsers,
  createUser,
  deleteUserApi,
  updateUser,
  getRoles,
} from "../../services/UserServices";

export default class AddUserForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      operationInProgress: false,
      id: "",
      userName: "",
      userEmail: "",
      password1: "",
      password2: "",

      userNameError: [],
      userEmailError: [],
      password1Error: [],
      password2Error: [],
      groupsError: [],

      userRoleOption: [],
      formData: new FormData(),
      options: [{ value: "", label: "Sélectionnez un rôle" }],
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.onShow = this.onShow.bind(this);
    this.onHide = this.onHide.bind(this);
  }

  componentDidUpdate(prevprops) {
    if (prevprops.roles !== this.props.roles && this.props.roles !== "") {
      var roleOptions = [{ value: 0, label: "Choisir un rôle" }];

      this.props.roles.forEach((role) => {
        roleOptions.push({ value: role.id, label: role.name });
      });

      this.setState({
        options: roleOptions,
      });
    }

    if (prevprops.user !== this.props.user) {
      if (this.props.user) {
        this.setState({
          id: this.props.user.id,
          userName: this.props.user.name,
          userEmail: this.props.user.email,
          // options: roleOptions,
        });
      } else {
        this.setState({
          id: "",
          userName: "",
          userEmail: "",
          // options: roleOptions,
          userRoleOption: { value: "", label: "Sélectionner role" },
        });
      }
    }
  }

  getDefaultRolesOption() {
    var tempOptions = [];

    if (this.props.user !== null && this.props.user !== undefined) {
      if (this.props.user.roles !== null && this.props.user.roles !== undefined) {
        var n = 1;
        let tempRoles = [];
        this.props.roles.forEach((element) => {
          this.props.user.roles.forEach((elmt) => {
            if (elmt.name === element.name) {
              tempRoles.push(element);
            }
          });
          n++;
        });

        if (tempRoles.length > 0) {
          tempOptions = [];
          tempRoles.forEach((element) => {
            tempOptions.push({ value: element.id, label: element.name });
          });
        }
      }
    }

    return tempOptions;
  }

  generateUserRoleOptions(user) {
    var rolesOptions = [];

    if (user !== "") {
      if (user.roles) {
        user.roles.forEach((element) => {
          rolesOptions.push({ value: element.id, label: element.name });
        });
      }
    }
    return rolesOptions;
  }

  async handleFormSubmit({ isUpdate, payload }) {
    if (isUpdate === true) {
      return this.updateUserInForm(payload);
    } else {
      return this.createUserInForm(payload);
    }
  }

  async handleSubmit(event) {
    event.preventDefault();

    var userRoles = [];

    this.state.userRoleOption.forEach((keyValue) => {
      userRoles.push(keyValue.label);
    });

    var username = this.state.userName;
    var email = this.state.userEmail;
    var password1 = this.state.password1;
    var password2 = this.state.password2;
    var roles = userRoles;

    var payload = {
      username: username,
      email: email,
      password1: password1,
      password2: password2,
      groups: roles,
    };

    var isUpdate = false;

    if (!HelperFunction.isDataNull(this.props.user)) {
      isUpdate = true;
    }

    this.setState({
      operationInProgress: true,
    });
    this.handleFormSubmit({ isUpdate: isUpdate, payload: payload });
  }

  manageFormSubmitError(error) {
    this.setState({
      operationInProgress: false,
    });
    if (error.get("code") === "ERR_BAD_REQUEST") {
      var errorData = error.get("data");

      this.setState({
        userNameError: errorData.username ? errorData.username : [],
        userEmailError: errorData.email ? errorData.email : [],
        password1Error: errorData.password ? errorData.password : [],
        password2Error: errorData.password ? errorData.password : [],
        groupsError: errorData.groups ? errorData.groups : [],
      });
    }
  }

  manageFormSubmitSuccess() {
    this.setState({
      operationInProgress: false,
    });
    this.setState({
      id: "",
      userName: "",
      userEmail: "",
      userRoleOption: "",
      password1: "",
      password2: "",
      userNameError: [],
      userEmailError: [],
      password1Error: [],
      password2Error: [],
    });
  }

  handleSuccess() {
    this.manageFormSubmitSuccess();
    this.props.callBackSuccess();
  }

  handleFailure(error) {
    let errorHandled = HelperFunction.handleErrors(error);
    this.manageFormSubmitError(errorHandled);
  }

  createUserInForm(payload) {
    createUser(payload)
      .then((response) => {
        this.handleSuccess();
      })
      .catch((error) => {
        this.handleFailure(error);
      });
  }

  updateUserInForm(payload) {
    if (HelperFunction.isDataNull(payload.password1)) {
      delete payload.password1;
      delete payload.password2;
    }
    updateUser({ payload: payload, id: this.state.id })
      .then((response) => {
        this.handleSuccess();
      })
      .catch((error) => {
        this.handleFailure(error);
      });
  }

  handleInputChange(valueToUpdate, event) {
    switch (valueToUpdate.toLowerCase()) {
      case "username":
        this.setState({
          userName: event.target.value,
        });
        break;
      case "useremail":
        this.setState({
          userEmail: event.target.value,
        });
        break;
      case "password1":
        this.setState({
          password1: event.target.value,
        });
        break;
      case "password2":
        this.setState({
          password2: event.target.value,
        });
        break;
      case "userrole":
        this.setState({
          userRoleOption: event,
        });
        break;

      default:
      // code block
    }
  }

  renderButtonContent() {
    var toDisplay = "Enregistrer";
    if (this.state.operationInProgress === true) {
      toDisplay = <Spinner />;
    } else {
      toDisplay = "Enregistrer";
    }
    return toDisplay;
  }

  onShow() {
    this.getDefaultRolesOption();
    this.setState({
      userRoleOption: this.getDefaultRolesOption(),
    });
  }

  onHide() {
    this.setState({
      id: "",
      userName: "",
      userEmail: "",
      userRoleOption: "",
      password1: "",
      password2: "",
      userNameError: [],
      userEmailError: [],
      password1Error: [],
      password2Error: [],
    });
    this.props.hide();
  }

  render() {
    return (
      <div>
        <Modal show={this.props.show} onHide={this.onHide} onShow={this.onShow}>
          <Modal.Header closeButton>
            <Modal.Title>Utilisateur</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={this.handleSubmit}>
              <div className="form-outline mb-4">
                <label className="form-label mb-1">Nom de l'utilisateur *</label>
                <input
                  type="text"
                  placeholder="Nom de l'utilisateur"
                  className="form-control"
                  value={this.state.userName}
                  onChange={(event) => this.handleInputChange("username", event)}
                />
                <ul className="mt-1">
                  {this.state.userNameError.map((error, index) => (
                    <li key={index}>
                      {" "}
                      <span className="text-danger"> {error}</span>
                    </li>
                  ))}
                </ul>
              </div>

              <div className="form-outline mb-4">
                <label className="form-label mb-1">Email de l'utilisateur *</label>
                <input
                  type="text"
                  placeholder="Email de l'utilisateur"
                  className="form-control"
                  value={this.state.userEmail}
                  onChange={(event) => this.handleInputChange("userEmail", event)}
                />
                <ul className="mt-1">
                  {this.state.userEmailError.map((error, index) => (
                    <li key={index}>
                      {" "}
                      <span className="text-danger"> {error}</span>
                    </li>
                  ))}
                </ul>
              </div>

              {/* <div className="form-outline mb-4">
                                <label className="form-label mb-1" >Mot de passe</label>
                                <input
                                    type="password"
                                    placeholder="Mot de passe"
                                    className="form-control"
                                    value={this.state.password1}
                                    onChange={(event) => this.handleInputChange("password1", event)}
                                />
                                <ul className="mt-1">
                                    {this.state.password1Error.map((error, index) => (
                                        <li key={index}> <span className="text-danger"> {error}</span></li>
                                    ))}
                                </ul>

                            </div>

                            <div className="form-outline mb-4">
                                <label className="form-label mb-1"> Confirmer le Mot de passe</label>
                                <input
                                    type="password"
                                    placeholder="Confirmer le Mot de passe"
                                    className="form-control"
                                    value={this.state.password2}
                                    onChange={(event) => this.handleInputChange("password2", event)}
                                />
                                <ul className="mt-1">
                                    {this.state.password2Error.map((error, index) => (
                                        <li key={index}> <span className="text-danger"> {error}</span></li>
                                    ))}
                                </ul>

                            </div> */}

              <div className="form-outline mb-4">
                <label className="form-label mb-1">Rôle/Niveau de permission </label>

                <Select
                  value={this.state.userRoleOption}
                  isMulti={true}
                  defaultValue={[{ value: 0, label: "Choisir un rôle" }]}
                  // defaultInputValue = {this.state.userRoleOption === "" ? this.state.options[0].label : this.state.userRoleOption.label}
                  onChange={(value) => this.handleInputChange("userrole", value)}
                  options={this.state.options}
                />
                <ul className="mt-1">
                  {this.state.groupsError.map((error, index) => (
                    <li key={index}>
                      {" "}
                      <span className="text-danger"> {error}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <div className="d-grip gap-2">
              <button className="btn btn-primary mb-4" onClick={this.handleSubmit}>
                {this.renderButtonContent()}
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export function Spinner() {
  return (
    <div className="d-flex justify-content-center">
      <div className="spinner-border text-light" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
}
