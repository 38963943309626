import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import "../../assets/scss/editProfileModal.scss";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import AccountCircle from "@mui/icons-material/AccountCircle";
import EmailIcon from "@mui/icons-material/Email";
import KeyIcon from "@mui/icons-material/Key";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const FormEditProfile = ({ userData, currentprofilePicUrl, changeProfilePicture }) => {
  const [show, setShow] = useState(false);
  const [surname, setSurname] = useState("");
  const [foreNames, setForeNames] = useState("");
  const [email, setEmail] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword1, setNewPassword1] = useState("");
  const [newPassword2, setNewPassword2] = useState("");
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword1, setShowNewPassword1] = useState(false);
  const [showNewPassword2, setShowNewPassword2] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleChangeSurName = (e) => {
    setSurname(e.target.value);
  };

  const handleChangeForeNames = (e) => {
    setForeNames(e.target.value);
  };

  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleChangeCurrentPassword = (e) => {
    setCurrentPassword(e.target.value);
  };

  const handleChangeNewPassword1 = (e) => {
    setNewPassword1(e.target.value);
  };

  const handleChangeNewPassword2 = (e) => {
    setNewPassword2(e.target.value);
  };

  const handleUpdateProfile = () => {
    //
  };

  const handleClickShowCurrentPassword = () => {
    setShowCurrentPassword(!showCurrentPassword);
  };

  const handleClickShowNewPassword1 = () => {
    setShowNewPassword1(!showNewPassword1);
  };

  const handleClickShowNewPassword2 = () => {
    setShowNewPassword2(!showNewPassword2);
  };

  useEffect(() => {
    setSurname(userData.surname);
    setForeNames(userData.forname);
    setEmail(userData.email);
  }, []);

  return (
    <div className="mt-4 !px-6 sm:px-5">
      <button className="edit-profile-button w-100" onClick={handleShow}>
        {" "}
        Editer le profil{" "}
      </button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Body
          style={{
            display: "grid",
            gap: "20px",
          }}
        >
          <div className="cover-picture"></div>

          <div className="picture-fullname d-grid justify-content-center">
            <label htmlFor="pictureInput" style={{ cursor: "pointer" }}>
              <img
                src={currentprofilePicUrl}
                alt="USER PIC"
                width={130}
                height={130}
                className="mb-2 rounded-circle shadow-sm"
              />
            </label>
            <p className="fw-bold text-center">{`${userData.surname} ${userData.forename}`}</p>
          </div>

          <input
            id="pictureInput"
            className="d-none"
            type="file"
            accept="image/*"
            onChange={(e) => changeProfilePicture(e)}
          />

          <TextField
            label="Nom"
            value={surname}
            fullWidth
            color="primary"
            focused
            onChange={handleChangeSurName}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircle color="primary" />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            label="Prénoms"
            value={foreNames}
            color="primary"
            focused
            onChange={handleChangeForeNames}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircle color="primary" />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            label="E-mail"
            value={email}
            color="primary"
            focused
            onChange={handleChangeEmail}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailIcon color="primary" />
                </InputAdornment>
              ),
            }}
          />

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className="fw-bold"> Modifier le mot de passe </Typography>
            </AccordionSummary>
            <AccordionDetails className="d-grid gap-4">
              <TextField
                label="Mot De Passe Actuel"
                type={showCurrentPassword ? "text" : "password"}
                value={currentPassword}
                color="primary"
                focused
                onChange={handleChangeCurrentPassword}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleClickShowCurrentPassword} edge="end">
                        {showCurrentPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <TextField
                label="Nouveau Mot De Passe"
                type={showNewPassword1 ? "text" : "password"}
                value={newPassword1}
                color="primary"
                focused
                onChange={handleChangeNewPassword1}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleClickShowNewPassword1} edge="end">
                        {showNewPassword1 ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <TextField
                label="Confirmer Nouveau Mot De Passe"
                type={showNewPassword2 ? "text" : "password"}
                value={newPassword2}
                color="primary"
                focused
                onChange={handleChangeNewPassword2}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleClickShowNewPassword2} edge="end">
                        {showNewPassword2 ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </AccordionDetails>
          </Accordion>

          <button className="save-data-button" onClick={handleUpdateProfile}>
            {" "}
            Enrégistrer{" "}
          </button>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default FormEditProfile;
