import { requestHeader } from "./helperServices";
import axios from "./axios.js";

export async function getSchoolsGeoInfos(params = new URLSearchParams()) {
  return axios.get("/ecoles/", {
    headers: requestHeader(),
    params,
  });
}

export async function getFirmsGeoInfos(params = new URLSearchParams()) {
  return axios.get("/structures/", {
    headers: requestHeader(),
    params,
  });
}

export async function getYears(url) {
  return axios.get(`/${url}/years/`, {
    headers: requestHeader(),
  });
}
