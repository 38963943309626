import { TextField, Button } from "@mui/material";
import LibraryCard from "components/card/card";
import React, { useState, useEffect, Component } from "react";
import { ListGroup } from "react-bootstrap";
import { getDocuments } from "../../services/LibraryServices";
import FormAddDocument from "../forms/formAddDocument";
import { Spinner } from "../spinner";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { ThemeProvider } from "@mui/material/";
import { theme, CustomizedSelect } from "../dashboard/Customization";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
import autoBind from "react-autobind";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

let documentTypes = [
  ["Docs Projet", "base_document"],
  ["Rapport d'exécution", "rapport"],
  ["PTBA", "ptba"],
  ["Autre", "autres"],
  ["Strategie", "strategie"],
];

class NumericLibrary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      documents: [],
      docName: "",
      docAuthor: "",
      docDescription: "",
      loading: true,
      userGroup: localStorage.getItem("group"),
    };
    autoBind(this);
  }

  componentDidMount() {
    this.loadDocumentCards();
  }

  loadDocumentCards(params = {}) {
    params["category"] = this.props.category;
    getDocuments(params).then((response) => {
      this.setState({ documents: response.data.results, loading: false });
    });
  }

  filter() {
    let filters = {
      name: this.state.docName,
      description: this.state.docDescription,
      author: this.state.docAuthor,
    };
    let params = {};
    for (const [key, attr] of Object.entries(filters)) {
      if (attr) {
        params[key] = attr;
      }
    }
    this.loadDocumentCards(params);
  }

  removeFilterAndUpdate(field) {
    let newState = {};
    newState[field] = "";
    this.setState(newState, this.filter);
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <div>
          <div
            className="filter-bar !mx-1 sm:!mx-5 mt-4 p-3 grid md:grid-cols-2 lg:grid-cols-4 gap-6"
            style={{
              backgroundColor: "rgba(51, 153, 255, 0.04)",
              borderRadius: "15px",
            }}
          >
            <TextField
              value={this.state.docAuthor}
              size="small"
              label="Auteur"
              color="primary"
              onChange={(e) => this.setState({ docAuthor: e.target.value })}
              InputProps={{
                name: "InputProps",
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => this.removeFilterAndUpdate("docAuthor")}
                      edge="end"
                    >
                      <CancelIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              value={this.state.docName}
              size="small"
              label="Nom du document"
              color="primary"
              onChange={(e) => this.setState({ docName: e.target.value })}
              InputProps={{
                name: "InputProps",
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => this.removeFilterAndUpdate("docName")}
                      edge="end"
                    >
                      <CancelIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              value={this.state.docDescription}
              size="small"
              label="Description"
              color="primary"
              onChange={(e) => this.setState({ docDescription: e.target.value })}
              InputProps={{
                name: "InputProps",
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => this.removeFilterAndUpdate("docDescription")}
                      edge="end"
                    >
                      <CancelIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {/* <FormControl sx={{ width: "auto" }}>
                <InputLabel id="description-select-label-fixed">Catégorie</InputLabel>
                <CustomizedSelect
                  value={this.state.docCategory}
                  size="small"
                  labelId="description-select-label-fixed"
                  label="Catégorie"
                  onChange={(e) => this.state.setState({ docCategory: e.target.value })}
                >
                  <MenuItem value="strategie">Stratégie</MenuItem>
                  <MenuItem value="rapport">Rapport</MenuItem>
                </CustomizedSelect>
              </FormControl> */}
            <Button
              variant="contained"
              className="fw-bold bg-secondary"
              onClick={this.filter}
            >
              Rechercher
            </Button>
            {this.state.userGroup === "RSE" && (
              <FormAddDocument reloadCardsList={this.loadDocumentCards} />
            )}
          </div>
          {this.state.loading ? (
            <Spinner />
          ) : (
            <ListGroup className="m-5 mt-1 pt-5 d-flex flex-row flex-wrap gap-4">
              {this.state.documents.map((doc) => (
                <LibraryCard
                  key={doc.pk}
                  cardData={doc}
                  reloadCardsList={this.loadDocumentCards}
                />
              ))}
            </ListGroup>
          )}
        </div>
      </ThemeProvider>
    );
  }
}

function NumericLibraryTabs() {
  return (
    <Tabs className="mt-2 h-screen overflow-auto p-3">
      <TabList>
        <div>
          {documentTypes.map(([display, type]) => (
            <Tab key={type}>{display}</Tab>
          ))}
        </div>
      </TabList>
      {documentTypes.map(([display, type]) => (
        <TabPanel key={type}>
          <NumericLibrary category={type} />
        </TabPanel>
      ))}
    </Tabs>
  );
}

export default NumericLibraryTabs;
