/* eslint-disable jsx-a11y/anchor-is-valid */
import Select from "react-select";
import { Select as MultiSelect } from "antd";
import { COMPONENTS_LIST } from "constants";

export default function ComponentParameterModal(props) {
  const { handleChangeComponent, selectedComponent } = props;

  const { Option } = MultiSelect;
  return (
    <div className="p-8">
      <h3 className="mt-6 text-base font-medium text-black">Composantes</h3>
      <div className="overflow-auto analyse-listing">
        {COMPONENTS_LIST.map((component, index) => {
          return (
            <span key={`${component} `} className="cursor-pointer">
              <a
                onClick={() => handleChangeComponent(component)}
                className={`${
                  selectedComponent === component ? "!bg-blue" : "bg-blue-50"
                } flex items-center justify-between px-2 py-2.5 border-t-[1px] border-b-[1px] hover:bg-transparent transition-pacofide no-underline`}
              >
                <span className="text-xs text-blue-500 transition-pacofide">
                  {component}
                </span>
                <span className="text-slate-500">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    style={{ fill: "currentColor" }}
                  >
                    <path d="M10.707 17.707 16.414 12l-5.707-5.707-1.414 1.414L13.586 12l-4.293 4.293z"></path>
                  </svg>
                </span>
              </a>
            </span>
          );
        })}
      </div>
    </div>
  );
}
