export async function apiCall(funct) {
  var responseToReturn = await mainFunction(function () {
    return funct();
  });
  return responseToReturn;
}

async function mainFunction(funct) {
  var dataToReturn = new Map();

  try {
    var requestResponse = await funct();
    dataToReturn.set("responseType", "success");
    dataToReturn.set("message", "");
    dataToReturn.set("data", requestResponse.data);
  } catch (error) {
    var partialResponse = new Map();

    if (error.response) {
      partialResponse = await manageErrorBasedOnServerResponse(error.response);
    } else if (error.request) {
      partialResponse = await manageNoResponseError(error.request);
    } else {
      partialResponse = await manageOtherError(error.message);
    }

    var responseType = partialResponse.get("code");
    var message = partialResponse.get("message");
    var data = partialResponse.get("data");

    dataToReturn.set("responseType", responseType);
    dataToReturn.set("message", message);
    dataToReturn.set("data", data);
  }

  return dataToReturn;
}

async function manageErrorBasedOnServerResponse(response) {
  var returnMe = new Map();
  var code = "";
  var message = "";
  var data = "";

  switch (response.data.code) {
    case "token_not_valid":
      code = response.data.code;
      message = response.data.detail;

      returnMe.set("code", code);
      returnMe.set("message", message);
      returnMe.set("data", message);
      return returnMe;

    case "user_not_found":
      code = response.data.code;
      message = response.data.detail;

      returnMe.set("code", code);
      returnMe.set("message", message);
      returnMe.set("data", message);

      return returnMe;

    default:
      code = response.statusText;
      message = response.statusText;
      data = response.data;

      returnMe.set("code", code);
      returnMe.set("message", message);
      returnMe.set("data", data);

      return returnMe;
  }
}

async function manageNoResponseError(response) {
  var returnMe = new Map();
  var code = response.data.code;
  var message = response.data.detail;
  var data = response.data;

  returnMe.set("code", code);
  returnMe.set("message", message);
  returnMe.set("data", data);

  return returnMe;
}

async function manageOtherError(response) {
  var returnMe = new Map();
  var code = response;
  var message = response;
  var data = response;

  returnMe.set("code", code);
  returnMe.set("message", message);
  returnMe.set("data", data);

  return returnMe;
}
