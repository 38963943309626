import { requestHeader } from "./helperServices";
import axios from "./axios.js";
import { API_URL } from "../constants";

export async function getTasks({
  link = "/projects/",
  projectId = 1,
  params = new URLSearchParams(),
}) {
  var dataToReturn = await axios.get(link + projectId + "/tasks/", {
    headers: requestHeader(),
    params: params,
  });
  return dataToReturn;
}

export async function createTask(payload, projectId) {
  var dataToReturn = await axios.post(
    "/projects/" + projectId + "/tasks/",
    payload,
    // { "headers": requestHeader() }
  );

  return dataToReturn;
}

export async function updateTask({ code, project, payload }) {
  var dataToReturn = await axios.put(
    "/projects/" + project + "/tasks/" + code + "/",
    payload,
    { headers: requestHeader() },
  );
  return dataToReturn;
}

export async function createTaskWithFile({ projectId, payload }) {
  console.log();
  return await axios.post("/projects/" + projectId + "/tasks/template/", payload);
}

export async function getTaskFile() {
  const headers = requestHeader();
  headers.Accept = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
  return await fetch(`${API_URL}/projects/1/tasks/template/`, { headers });
}

export async function getInterventionsItems({ endpoint, filters }) {
  const headers = requestHeader();
  filters = filters || new URLSearchParams();
  return await axios.get(`/interventions/${endpoint}`, { headers, params: filters });
}

export async function getInterventions(filters) {
  return await getInterventionsItems({ endpoint: "", filters });
}
